import { useState, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ShowCurrencies from "../../utils/showCurrencies";
import Skeleton from "react-loading-skeleton";
import "react-lazy-load-image-component/src/effects/blur.css";
import CustomButton from "./button";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../store/user";
import ConvertAUD from "../../utils/convertAUD";
import useCart from "../../hooks/cart/useCart";
import { openSheet } from "../../store/sheetToggler";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { HiShoppingCart } from "react-icons/hi";
import { selectWidgetProducts } from "../../store/widgetCart";

const SelectOneProductsBox = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const ref = useRef(null);
  const selectedCurrency = useSelector(selectSelectedCurrency);
  const [activeProductIdx, setActiveProductIdx] = useState(0);
  const {
    title,
    description,
    image,
    products,
    hasCustomPrice,
    arabic,
    buttonLable,
    alt,
    linkBtnClasses,
  } = props;
  const [showMore, setShowMore] = useState(false);
  const [visibleDescriptions, setVisibleDescriptions] = useState({}); // State to track visibility of descriptions
  const widgetProducts = useSelector(selectWidgetProducts);

  const toggleDescription = (index) => {
    setVisibleDescriptions((prevState) => {
      const newState = {}; // Reset all descriptions to be hidden
      newState[index] = !prevState[index]; // Toggle the selected description
      return newState;
    });
  };
  /* -------------------------------------------------------------------------- */
  const dispatch = useDispatch();
  const { handleAddToGiving, handleCheckProduct, handleRemoveProduct } =
    useCart();

  /* ------------------------------ Start methods ----------------------------- */
  const handleConvert = (currency, value) => {
    return Math.round(ConvertAUD(currency, value, false));
  };
  const handleCurrency = (product) => {
    if (product.quantity) {
      return ShowCurrencies(
        selectedCurrency,
        product[selectedCurrency.toLowerCase()] * product.quantity,
        false,
      );
    } else {
      return ShowCurrencies(
        selectedCurrency,
        product[selectedCurrency.toLowerCase()],
        false,
      );
    }
  };
  const handleHeight = () => {
    setShowMore(!showMore);
  };

  const handleAddToCart = (product) => {
    const { quantity } = product;
    handleAddToGiving(product, quantity || 1);
    dispatch(openSheet());
  };

  const handleProductCounter = (creator) => {
    const product = widgetProducts?.cartCounter?.find(
      (item) => item.creator === creator,
    );

    if (product) {
      if (product.counter >= 99) {
        return 99;
      } else {
        return product.counter || 0;
      }
    } else {
      return 0;
    }
  };
  /* -------------------------------------------------------------------------- */
  const handleAddProduct = (product) => {
    handleAddToGiving(product);
    dispatch(openSheet());
  };

  /* ------------------------------- Start hooks ------------------------------ */

  /* -------------------------------------------------------------------------- */

  return (
    <>
      <div className="hidden sm:block">
        <div className="w-full shadow-lg bg-white pb-3 md:pb-5">
          <div className="overflow-hidden img-hover-zoom w-full h-[260px] tooltip [&>span]:!block">
            {image ? (
              image ? (
                <LazyLoadImage
                  alt={alt ? alt : title}
                  effect="blur"
                  width={`100%`}
                  height={`100%`}
                  delayTime={500}
                  className="cursor-pointer block h-full object-cover object-top"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/general/logo-replace.png";
                  }}
                  src={image} // use normal <img> attributes as props
                />
              ) : (
                <img
                  className="w-full"
                  src="/images/general/logo-replace.png"
                  alt=""
                />
              )
            ) : (
              <Skeleton height={260} />
            )}
          </div>
          <p className="text-[#00a3da] cursor-pointer flex justify-center items-center text-sm sm:text-md md:text-md lg:text-lg xl:text-xl text-center px-1 mt-3 min-h-[56px] uppercase">
            {title}
          </p>
          <div className="relative text-start">
            <div
              className={`transition-all duration-300  my-2 relative overflow-hidden max-h-[65px] md:max-h-[78px] ${description.split(" ").length > 28 ? "mb-0" : "mb-0"}`}
            >
              <p
                ref={ref}
                className={`text-stone-500 text-sm md:text-base lg:!leading-6 overflow-hidden overflow-y-scroll- transition-all duration-200 min-h-[65px] md:min-h-[79px] px-2 md:px-3 text-center`}
              >
                {description}
              </p>
            </div>
          </div>
          <div className="px-2 md:px-3">
            <div className="flex flex-col gap-y-1.5 my-6">
              {products?.map((product, index) =>
                product ? (
                  <div
                    key={`product_${index}`}
                    onClick={() => setActiveProductIdx(index)}
                    className={`w-full flex justify-between p-2 transition cursor-pointer ${activeProductIdx === index ? "text-white bg-[#F60362] " : "text-[#00a3da] bg-white"}`}
                    style={{
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <p>{product.alternativeName || product.name}</p>
                    <p className="font-brandingBold">
                      {hasCustomPrice
                        ? handleConvert(selectedCurrency, product.quantity)
                        : handleCurrency(product)}{" "}
                      <span>{selectedCurrency}</span>
                    </p>
                  </div>
                ) : (
                  <Skeleton key={`product_skeleton_${index}`} height={40} />
                ),
              )}
            </div>

            <div className="flex justify-center col-span-6">
              <CustomButton
                // onClick={() => {
                //     const product = products[activeProductIdx];
                //     if(hasCustomPrice) {
                //         product.quantity = handleConvert(selectedCurrency, product.quantity);
                //     }
                //     addProduct({product: product, currency: selectedCurrency})
                // }}
                onClick={() => {
                  const product = products[activeProductIdx];
                  handleAddProduct(product);
                }}
                title={
                  buttonLable
                    ? buttonLable
                    : arabic
                      ? "تبرع الان"
                      : "Donate Now"
                }
                className={`!rounded-none ${linkBtnClasses ? linkBtnClasses : ""} ${arabic ? "font-[AlmariMedium]" : ""}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="sm:hidden">
        <div className="w-full gap-2 md:gap-4 grid grid-cols-[auto,1fr] shadow-[0_7px_29px_0px_rgba(100,100,111,0.2)] bg-white">
          <div className="overflow-hidden tooltip img-hover-zoom w-[72px] md:w-[108px] aspect-square">
            {image ? (
              image ? (
                <LazyLoadImage
                  alt={alt ? alt : title}
                  effect="blur"
                  width={`100%`}
                  height={`100%`}
                  delayTime={500}
                  className="cursor-pointer w-full h-full"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/general/logo-replace.png";
                  }}
                  src={image} // use normal <img> attributes as props
                />
              ) : (
                <img
                  className="w-full h-full object-cover"
                  src="/images/general/logo-replace.png"
                  alt=""
                />
              )
            ) : (
              <Skeleton height={100} width={100} />
            )}
          </div>
          {/* Right Container */}
          <div
            className={`flex flex-col justify-between md:items-baseline gap-1 sm:gap-3 p-2 md:p-3 ${arabic ? "pl-2" : "pl-0"}`}
          >
            <div
              className={`flex gap-1 flex-col items-center overflow-hidden transition-all duration-300 ${showMore ? "h-auto" : "max-h-[24px]"}`}
            >
              <div className="w-full flex gap-2 items-center">
                <p
                  className={`text-[#00a3da] cursor-pointer md:text-lg font-semibold md:font-bold uppercase ${!showMore ? "line-clamp-1" : ""}`}
                >
                  {title}
                </p>
                <span className="block basis-[18px]">
                  <ChevronDownIcon
                    onClick={handleHeight}
                    className={`md:block w-[18px] h-[18px] text-[#00a3da] transition-all duration-300 cursor-pointer ${
                      showMore ? "transform rotate-180" : ""
                    }`}
                  />
                </span>
              </div>
              <div className="relative text-start">
                <div
                  className={`transition-all duration-300  my-2 relative overflow-hidden max-h-[65px] md:max-h-[78px] ${description.split(" ").length > 28 ? "mb-0" : "mb-0"}`}
                >
                  <p
                    ref={ref}
                    className={`text-stone-500 text-sm md:text-base lg:!leading-6 overflow-hidden overflow-y-scroll- transition-all duration-200 min-h-[65px] md:min-h-[79px] px-2 md:px-3 text-center`}
                  >
                    {description}
                  </p>
                </div>
              </div>
            </div>
            {/* Button Container */}
            <div className="flex gap-3 items-center w-full justify-end">
              <div className="flex gap-2 items-center">
                <button
                  className={`relative w-full max-w-max cursor-pointer transition-all duration-300 border border-LightBlue flex justify-center items-center gap-2 text-xs sm:text-base h-7 sm:h-10 px-2 sm:px-3 ${
                    products[activeProductIdx] &&
                    handleCheckProduct(products[activeProductIdx])
                      ? "bg-LightBlue text-white"
                      : "bg-white text-LightBlue"
                  }`}
                  onClick={() => {
                    handleAddToCart(products[activeProductIdx]);
                  }}
                >
                  Add
                  {handleProductCounter(products[activeProductIdx]?.creator) >=
                  1 ? (
                    <span
                      className={`text-xs absolute top-[-8px] right-[-8px] bg-[#f60362] text-white ${handleProductCounter(products[activeProductIdx]?.creator) >= 99 ? "w-5 h-5 !text-[10px]" : "w-4 h-4"} leading-[0px]  rounded-full flex justify-center items-center`}
                    >
                      {" "}
                      {handleProductCounter(
                        products[activeProductIdx]?.creator,
                      ) >= 99 ? (
                        <span>
                          99<sup>+</sup>
                        </span>
                      ) : (
                        handleProductCounter(
                          products[activeProductIdx]?.creator,
                        )
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                  <HiShoppingCart />
                </button>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <div className="grid grid-cols-3 gap-2 p-2">
              {products?.map((product, index) =>
                product ? (
                  <div
                    key={`product_${index}`}
                    onClick={(e) => {
                      setActiveProductIdx(index);
                      e.stopPropagation();
                      toggleDescription(index);
                    }}
                    className={`w-full flex justify-between p-2 transition cursor-pointer ${activeProductIdx === index ? "text-white bg-[#F60362] " : "text-[#F60362] bg-white"}`}
                    style={{
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    {/* <p>{product.alternativeName || product.name}</p> */}
                    <div className="w-full font-brandingBold text-xs text-center uppercase flex gap-1 items-center justify-center">
                      <p className="">
                        {hasCustomPrice
                          ? handleConvert(selectedCurrency, product.quantity)
                          : handleCurrency(product)}
                      </p>
                      <span>{selectedCurrency}</span>
                      <span
                        className={`block basis-[18px] text-[#00a3da] ${activeProductIdx === index ? "text-[#fff]" : ""}`}
                      >
                        <ChevronDownIcon
                          className={`w-[18px] h-[18px] transition-all duration-300 cursor-pointer  ${
                            visibleDescriptions[index]
                              ? `transform rotate-180`
                              : ""
                          }`}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  <Skeleton key={`product_skeleton_${index}`} height={40} />
                ),
              )}
            </div>
            {products?.map(
              (item, index) =>
                visibleDescriptions[index] && (
                  <div className="text-stone-500 text-sm overflow-hidden transition-all duration-200 px-2 pb-2">
                    <p>{item.alternativeName || item.name}</p>
                  </div>
                ),
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectOneProductsBox;
