import { Builder } from '@builder.io/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from "../../../../../../store/user";
import TabContent from "../../../../../general/tabs/tab-content";
import { selectProducts } from '../../../../../../store/products';
const TabsWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    tabs = []
  } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  /* -------------------------------------------------------------------------- */
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const [tabProducts, setTabProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const tabsArray = Array.isArray(tabs) ? tabs : [];
  const _allProducts = useSelector(state => selectProducts(state));
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  const getSelectedProducts = _allProducts => {
    setIsLoaded(true);
    let tempSelectedProducts = [];
    // Initialize tempSelectedProducts with an empty products array for each tab
    tabsArray.forEach(() => {
      tempSelectedProducts.push({
        products: []
      });
    });
    _allProducts.forEach(item => {
      item.sub_categories.forEach(innerItem => {
        innerItem.products.forEach(innerProduct => {
          tabsArray.map((tabItem, tabIndex) => {
            if (tabItem.products && tabItem.products.includes(innerProduct.creator)) {
              tempSelectedProducts[tabIndex].products.push(innerProduct);
            }
          });
        });
      });
    });
    setTabProducts(tempSelectedProducts);
  };
  useEffect(() => {
    if (tabs.length > 0) {
      _allProducts && Object.keys(_allProducts).length && getSelectedProducts(_allProducts);
    }
  }, [tabs, _allProducts]);
  /* -------------------------------------------------------------------------- */
  return <section className="bg-[#253B7E]">
    <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
      <div className=" flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
        {tabsArray.map(({
          title
        }, index) => <button key={`btn_${index}`} onClick={() => {
          // window.scrollTo({
          //   behavior: 'smooth',
          //   top: contentRef.current.offsetTop - 200
          // });
          setActiveTabProducts(index);
        }} className={`${index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'} text-[14px] md:text-base- rounded-full max-[500px]:col-span-2- p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase font-['Montserrat'] font-bold`}>
            {title}
          </button>)}
      </div>
      <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
        <div className="flex flex-col w-full justify-center">
          {tabsArray.map((item, index) => index === activeTabProducts && <React.Fragment key={`description_${index}`}>
            <div className="flex w-full justify-center mb-6">
              <p className="text-center text-white text-[18px] md:text-3xl md:w-2/3">{item.description}</p>
            </div>
            <div ref={contentRef}>
              <TabContent showArrows={true} products={tabProducts[index] ? tabProducts[index].products : []} currency={selectedCurrencyStore} isLoaded={isLoaded} />
            </div>
          </React.Fragment>)}
        </div>
      </div>
    </div>
  </section>;
};
Builder.registerComponent(TabsWidget, {
  name: 'TabsWidget',
  inputs: [
    {
      name: 'tabs',
      type: 'list', // Defines the input as a list (array)
      defaultValue: [], // Initializes with an empty array
      minRows: 0,       // Optional: Minimum number of items
      maxRows: 6,     // Optional: Maximum number of items
      defaultItem: { title: '', description: '', products: [] }, // Defines the structure of each item
      subFields: [
        {
          name: 'title',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Title.', // Provides guidance to users
        },
        {
          name: 'description',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Description.', // Provides guidance to users
        },
        {
          name: 'products',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Products.', // Provides guidance to users
        },
      ],
    },
  ]
  // inputs: [{
  //   "name": "tabs",
  //   "type": "string"
  // }]
});
export default TabsWidget;