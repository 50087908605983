export const countries = [
    { countryName: "Japan", iso2: "JP" },
    { countryName: "Indonesia", iso2: "ID" },
    { countryName: "India", iso2: "IN" },
    { countryName: "China", iso2: "CN" },
    { countryName: "Philippines", iso2: "PH" },
    { countryName: "Brazil", iso2: "BR" },
    { countryName: "Korea, South", iso2: "KR" },
    { countryName: "Mexico", iso2: "MX" },
    { countryName: "Egypt", iso2: "EG" },
    { countryName: "United States", iso2: "US" },
    { countryName: "Bangladesh", iso2: "BD" },
    { countryName: "Thailand", iso2: "TH" },
    { countryName: "Russia", iso2: "RU" },
    { countryName: "Argentina", iso2: "AR" },
    { countryName: "Nigeria", iso2: "NG" },
    { countryName: "Turkey", iso2: "TR" },
    { countryName: "Pakistan", iso2: "PK" },
    { countryName: "Vietnam", iso2: "VN" },
    { countryName: "Iran", iso2: "IR" },
    { countryName: "Congo (Kinshasa)", iso2: "CD" },
    { countryName: "United Kingdom", iso2: "GB" },
    { countryName: "France", iso2: "FR" },
    { countryName: "Peru", iso2: "PE" },
    { countryName: "Angola", iso2: "AO" },
    { countryName: "Malaysia", iso2: "MY" },
    { countryName: "South Africa", iso2: "ZA" },
    { countryName: "Colombia", iso2: "CO" },
    { countryName: "Tanzania", iso2: "TZ" },
    { countryName: "Sudan", iso2: "SD" },
    { countryName: "Hong Kong", iso2: "HK" },
    { countryName: "Saudi Arabia", iso2: "SA" },
    { countryName: "Chile", iso2: "CL" },
    { countryName: "Spain", iso2: "ES" },
    { countryName: "Iraq", iso2: "IQ" },
    { countryName: "Singapore", iso2: "SG" },
    { countryName: "Cameroon", iso2: "CM" },
    { countryName: "Canada", iso2: "CA" },
    { countryName: "Kenya", iso2: "KE" },
    { countryName: "Burma", iso2: "MM" },
    { countryName: "Australia", iso2: "AU" },
    { countryName: "Côte d’Ivoire", iso2: "CI" },
    { countryName: "Germany", iso2: "DE" },
    { countryName: "Afghanistan", iso2: "AF" },
    { countryName: "Mali", iso2: "ML" },
    { countryName: "Jordan", iso2: "JO" },
    { countryName: "Morocco", iso2: "MA" },
    { countryName: "Ghana", iso2: "GH" },
    { countryName: "Algeria", iso2: "DZ" },
    { countryName: "United Arab Emirates", iso2: "AE" },
    { countryName: "Bolivia", iso2: "BO" },
    { countryName: "Greece", iso2: "GR" },
    { countryName: "Ethiopia", iso2: "ET" },
    { countryName: "Taiwan", iso2: "TW" },
    { countryName: "Guatemala", iso2: "GT" },
    { countryName: "Kuwait", iso2: "KW" },
    { countryName: "Hungary", iso2: "HU" },
    { countryName: "Yemen", iso2: "YE" },
    { countryName: "Uzbekistan", iso2: "UZ" },
    { countryName: "Ukraine", iso2: "UA" },
    { countryName: "Korea, North", iso2: "KP" },
    { countryName: "Italy", iso2: "IT" },
    { countryName: "Ecuador", iso2: "EC" },
    { countryName: "Somalia", iso2: "SO" },
    { countryName: "Syria", iso2: "SY" },
    { countryName: "Zambia", iso2: "ZM" },
    { countryName: "Burkina Faso", iso2: "BF" },
    { countryName: "Lebanon", iso2: "LB" },
    { countryName: "Romania", iso2: "RO" },
    { countryName: "Sri Lanka", iso2: "LK" },
    { countryName: "Azerbaijan", iso2: "AZ" },
    { countryName: "Madagascar", iso2: "MG" },
    { countryName: "Venezuela", iso2: "VE" },
    { countryName: "Austria", iso2: "AT" },
    { countryName: "Zimbabwe", iso2: "ZW" },
    { countryName: "Cambodia", iso2: "KH" },
    { countryName: "Sweden", iso2: "SE" },
    { countryName: "Cuba", iso2: "CU" },
    { countryName: "Belarus", iso2: "BY" },
    { countryName: "Netherlands", iso2: "NL" },
    { countryName: "Kazakhstan", iso2: "KZ" },
    { countryName: "Malawi", iso2: "MW" },
    { countryName: "Poland", iso2: "PL" },
    { countryName: "Puerto Rico", iso2: "PR" },
    { countryName: "Congo (Brazzaville)", iso2: "CG" },
    { countryName: "Oman", iso2: "OM" },
    { countryName: "Uruguay", iso2: "UY" },
    { countryName: "Honduras", iso2: "HN" },
    { countryName: "Uganda", iso2: "UG" },
    { countryName: "Guinea", iso2: "GN" },
    { countryName: "Bulgaria", iso2: "BG" },
    { countryName: "Costa Rica", iso2: "CR" },
    { countryName: "Rwanda", iso2: "RW" },
    { countryName: "Panama", iso2: "PA" },
    { countryName: "Senegal", iso2: "SN" },
    { countryName: "Mongolia", iso2: "MN" },
    { countryName: "Israel", iso2: "IL" },
    { countryName: "Denmark", iso2: "DK" },
    { countryName: "Finland", iso2: "FI" },
    { countryName: "Czechia", iso2: "CZ" },
    { countryName: "New Zealand", iso2: "NZ" },
    { countryName: "Dominican Republic", iso2: "DO" },
    { countryName: "Portugal", iso2: "PT" },
    { countryName: "Ireland", iso2: "IE" },
    { countryName: "Belgium", iso2: "BE" },
    { countryName: "Serbia", iso2: "RS" },
    { countryName: "Qatar", iso2: "QA" },
    { countryName: "Libya", iso2: "LY" },
    { countryName: "Burundi", iso2: "BI" },
    { countryName: "Mozambique", iso2: "MZ" },
    { countryName: "Kyrgyzstan", iso2: "KG" },
    { countryName: "Georgia", iso2: "GE" },
    { countryName: "Chad", iso2: "TD" },
    { countryName: "Mauritania", iso2: "MR" },
    { countryName: "Armenia", iso2: "AM" },
    { countryName: "Norway", iso2: "NO" },
    { countryName: "Nicaragua", iso2: "NI" },
    { countryName: "Turkmenistan", iso2: "TM" },
    { countryName: "Niger", iso2: "NE" },
    { countryName: "Liberia", iso2: "LR" },
    { countryName: "Haiti", iso2: "HT" },
    { countryName: "Eritrea", iso2: "ER" },
    { countryName: "Sierra Leone", iso2: "SL" },
    { countryName: "Laos", iso2: "LA" },
    { countryName: "Latvia", iso2: "LV" },
    { countryName: "Central African Republic", iso2: "CF" },
    { countryName: "Tajikistan", iso2: "TJ" },
    { countryName: "Nepal", iso2: "NP" },
    { countryName: "Gabon", iso2: "GA" },
    { countryName: "Croatia", iso2: "HR" },
    { countryName: "Lithuania", iso2: "LT" },
    { countryName: "Moldova", iso2: "MD" },
    { countryName: "Papua New Guinea", iso2: "PG" },
    { countryName: "Benin", iso2: "BJ" },
    { countryName: "Bahrain", iso2: "BH" },
    { countryName: "Estonia", iso2: "EE" },
    { countryName: "Djibouti", iso2: "DJ" },
    { countryName: "Tunisia", iso2: "TN" },
    { countryName: "Gaza Strip", iso2: "XG" },
    { countryName: "Jamaica", iso2: "JM" },
    { countryName: "Macau", iso2: "MO" },
    { countryName: "North Macedonia", iso2: "MK" },
    { countryName: "Guinea-Bissau", iso2: "GW" },
    { countryName: "Malta", iso2: "MT" },
    { countryName: "Paraguay", iso2: "PY" },
    { countryName: "Slovakia", iso2: "SK" },
    { countryName: "South Sudan", iso2: "SS" },
    { countryName: "Switzerland", iso2: "CH" },
    { countryName: "Namibia", iso2: "NA" },
    { countryName: "Bosnia and Herzegovina", iso2: "BA" },
    { countryName: "Albania", iso2: "AL" },
    { countryName: "Gambia, The", iso2: "GM" },
    { countryName: "Lesotho", iso2: "LS" },
    { countryName: "Cyprus", iso2: "CY" },
    { countryName: "El Salvador", iso2: "SV" },
    { countryName: "Reunion", iso2: "RE" },
    { countryName: "Equatorial Guinea", iso2: "GQ" },
    { countryName: "Slovenia", iso2: "SI" },
    { countryName: "Bahamas, The", iso2: "BS" },
    { countryName: "Martinique", iso2: "MQ" },
    { countryName: "Guadeloupe", iso2: "GP" },
    { countryName: "Botswana", iso2: "BW" },
    { countryName: "Suriname", iso2: "SR" },
    { countryName: "Timor-Leste", iso2: "TL" },
    { countryName: "Kosovo", iso2: "XK" },
    { countryName: "West Bank", iso2: "XW" },
    { countryName: "Guyana", iso2: "GY" },
    { countryName: "Fiji", iso2: "FJ" },
    { countryName: "New Caledonia", iso2: "NC" },
    { countryName: "Montenegro", iso2: "ME" },
    { countryName: "Curaçao", iso2: "CW" },
    { countryName: "Mauritius", iso2: "MU" },
    { countryName: "Iceland", iso2: "IS" },
    { countryName: "Maldives", iso2: "MV" },
    { countryName: "Luxembourg", iso2: "LU" },
    { countryName: "French Polynesia", iso2: "PF" },
    { countryName: "Guam", iso2: "GU" },
    { countryName: "Bhutan", iso2: "BT" },
    { countryName: "Togo", iso2: "TG" },
    { countryName: "Eswatini", iso2: "SZ" },
    { countryName: "Barbados", iso2: "BB" },
    { countryName: "Trinidad and Tobago", iso2: "TT" },
    { countryName: "Solomon Islands", iso2: "SB" },
    { countryName: "Mayotte", iso2: "YT" },
    { countryName: "Cabo Verde", iso2: "CV" },
    { countryName: "Saint Lucia", iso2: "LC" },
    { countryName: "Sao Tome and Principe", iso2: "ST" },
    { countryName: "French Guiana", iso2: "GF" },
    { countryName: "Belize", iso2: "BZ" },
    { countryName: "Brunei", iso2: "BN" },
    { countryName: "Vanuatu", iso2: "VU" },
    { countryName: "Samoa", iso2: "WS" },
    { countryName: "Monaco", iso2: "MC" },
    { countryName: "Aruba", iso2: "AW" },
    { countryName: "Gibraltar", iso2: "GI" },
    { countryName: "Jersey", iso2: "JE" },
    { countryName: "Marshall Islands", iso2: "MH" },
    { countryName: "Comoros", iso2: "KM" },
    { countryName: "Kiribati", iso2: "KI" },
    { countryName: "Isle of Man", iso2: "IM" },
    { countryName: "Cayman Islands", iso2: "KY" },
    { countryName: "Seychelles", iso2: "SC" },
    { countryName: "Saint Vincent and the Grenadines", iso2: "VC" },
    { countryName: "Tonga", iso2: "TO" },
    { countryName: "Andorra", iso2: "AD" },
    { countryName: "Antigua and Barbuda", iso2: "AG" },
    { countryName: "Guernsey", iso2: "GG" },
    { countryName: "Greenland", iso2: "GL" },
    { countryName: "Dominica", iso2: "DM" },
    { countryName: "Micronesia, Federated States of", iso2: "FM" },
    { countryName: "Faroe Islands", iso2: "FO" },
    { countryName: "Saint Kitts and Nevis", iso2: "KN" },
    { countryName: "Virgin Islands, British", iso2: "VG" },
    { countryName: "American Samoa", iso2: "AS" },
    { countryName: "Grenada", iso2: "GD" },
    { countryName: "San Marino", iso2: "SM" },
    { countryName: "Bonaire, Sint Eustatius, and Saba", iso2: "BQ" },
    { countryName: "Palau", iso2: "PW" },
    { countryName: "Tuvalu", iso2: "TV" },
    { countryName: "Liechtenstein", iso2: "LI" },
    { countryName: "Saint Martin", iso2: "MF" },
    { countryName: "Saint Pierre and Miquelon", iso2: "PM" },
    { countryName: "Cook Islands", iso2: "CK" },
    { countryName: "Turks and Caicos Islands", iso2: "TC" },
    { countryName: "Anguilla", iso2: "AI" },
    { countryName: "Northern Mariana Islands", iso2: "MP" },
    { countryName: "Saint Barthelemy", iso2: "BL" },
    { countryName: "Falkland Islands (Islas Malvinas)", iso2: "FK" },
    { countryName: "Sint Maarten", iso2: "SX" },
    { countryName: "Svalbard", iso2: "XR" },
    { countryName: "Christmas Island", iso2: "CX" },
    { countryName: "Wallis and Futuna", iso2: "WF" },
    { countryName: "Bermuda", iso2: "BM" },
    { countryName: "Vatican City", iso2: "VA" },
    { countryName: "Nauru", iso2: "NR" },
    { countryName: "Saint Helena, Ascension, and Tristan da Cunha", iso2: "SH" },
    { countryName: "Niue", iso2: "NU" },
    { countryName: "Montserrat", iso2: "MS" },
    { countryName: "Norfolk Island", iso2: "NF" },
    { countryName: "South Georgia and South Sandwich Islands", iso2: "GS" },
    { countryName: "Pitcairn Islands", iso2: "PN" },
    { countryName: "U.S. Virgin Islands", iso2: "VI" }
];

export const alternativeMappings = {
    // North America
    'usa': 'us',
    'america': 'us',
    'united-states': 'us',
    'united_states': 'us',
    'unitedstatesofamerica': 'us',
    'the-united-states': 'us',
    'can': 'ca',
    'mex': 'mx',
    'méxico': 'mx',
    'mejico': 'mx',

    // United Kingdom and variations
    'uk': 'gb',
    'eng': 'gb',
    'england': 'gb',
    'britain': 'gb',
    'greatbritain': 'gb',
    'united-kingdom': 'gb',
    'united_kingdom': 'gb',
    'scotland': 'gb',
    'wales': 'gb',
    'northern-ireland': 'gb',

    // Middle East
    'uae': 'ae',
    'emirates': 'ae',
    'united-arab-emirates': 'ae',
    'ksa': 'sa',
    'saudi': 'sa',
    'saudiarabia': 'sa',
    'saudi-arabia': 'sa',

    // Asia
    'rok': 'kr',
    'southkorea': 'kr',
    'south-korea': 'kr',
    'republicofkorea': 'kr',
    'korea': 'kr',
    'dprk': 'kp',
    'north-korea': 'kp',
    'northkorea': 'kp',
    'roc': 'tw',
    'taiwan': 'tw',
    'chinese-taipei': 'tw',
    'prc': 'cn',
    'mainland': 'cn',
    'mainlandchina': 'cn',
    'peoples-republic': 'cn',
    'hkg': 'hk',
    'hongkong': 'hk',
    'hong-kong': 'hk',
    'macau': 'mo',
    'macao': 'mo',
    'brunei': 'bn',
    'brunei-darussalam': 'bn',
    'vietnam': 'vn',
    'viet-nam': 'vn',
    'laos': 'la',
    'lao': 'la',
    'myanmar': 'mm',
    'burma': 'mm',

    // South Asia
    'pak': 'pk',
    'pakistan': 'pk',
    'ind': 'in',
    'bharat': 'in',
    'hindustan': 'in',
    'sri-lanka': 'lk',
    'srilanka': 'lk',
    'ceylon': 'lk',
    'bangladesh': 'bd',
    'bangla': 'bd',

    // Europe
    'deutschland': 'de',
    'allemagne': 'de',
    'suisse': 'ch',
    'schweiz': 'ch',
    'switzerland': 'ch',
    'españa': 'es',
    'espana': 'es',
    'sverige': 'se',
    'holland': 'nl',
    'netherlands': 'nl',
    'nederland': 'nl',
    'belgië': 'be',
    'belgique': 'be',
    'denmark': 'dk',
    'danmark': 'dk',
    'norge': 'no',
    'norway': 'no',
    'suomi': 'fi',
    'finland': 'fi',
    'czechia': 'cz',
    'czech': 'cz',
    'czech-republic': 'cz',
    'polska': 'pl',
    'poland': 'pl',
    'magyarország': 'hu',
    'hungary': 'hu',

    // Russia and former Soviet states
    'russia': 'ru',
    'russian-federation': 'ru',
    'rossiya': 'ru',
    'ukraine': 'ua',
    'ukraina': 'ua',
    'belarus': 'by',
    'byelorussia': 'by',
    'kazakhstan': 'kz',
    'uzbekistan': 'uz',

    // Oceania
    'aus': 'au',
    'australia': 'au',
    'new-zealand': 'nz',
    'aotearoa': 'nz',
    'png': 'pg',
    'papua': 'pg',
    'papua-new-guinea': 'pg',

    // Africa
    'rsa': 'za',
    'south-africa': 'za',
    'southafrica': 'za',
    'egypt': 'eg',
    'misr': 'eg',
    'masr': 'eg',
    'nigeria': 'ng',
    'naija': 'ng',
    'kenya': 'ke',
    'morocco': 'ma',
    'maroc': 'ma',
    'al-maghrib': 'ma',
    'tunisia': 'tn',
    'tunis': 'tn',

    // South America
    'brasil': 'br',
    'brazil': 'br',
    'argentine': 'ar',
    'argentina': 'ar',
    'colombia': 'co',
    'peru': 'pe',
    'chile': 'cl',
    'venezuela': 've',

    // Additional variations
    'islamic-republic': 'ir',
    'iran': 'ir',
    'persia': 'ir',
    'iraq': 'iq',
    'türkiye': 'tr',
    'turkiye': 'tr',
    'turkey': 'tr',
    'syrian': 'sy',
    'syria': 'sy',
    'lebanon': 'lb',
    'liban': 'lb',
    'jordan': 'jo',
    'al-urdun': 'jo',
    'palestine': 'ps',
    'israel': 'il',
    'afghan': 'af',
    'afghanistan': 'af',

    // Central America
    'costa-rica': 'cr',
    'panama': 'pa',
    'honduras': 'hn',
    'el-salvador': 'sv',
    'guatemala': 'gt',
    'nicaragua': 'ni',
    'belize': 'bz',

    // Caribbean
    'dominican-republic': 'do',
    'dominicana': 'do',
    'jamaica': 'jm',
    'trinidad': 'tt',
    'trinidad-and-tobago': 'tt',
    'barbados': 'bb',
    'bahamas': 'bs',
    'the-bahamas': 'bs',

    // Format variations for all
    'united.states': 'us',
    'united.kingdom': 'gb',
    'south.korea': 'kr',
    'saudi.arabia': 'sa',
    'new.zealand': 'nz',
    'united.arab.emirates': 'ae'
};