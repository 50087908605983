import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {findCountryCode, findCountryName, getCmsRoutes} from "./utils";

const NamazTimeSitemapAccordion = () => {
    const location = useLocation();
    const [routes, setRoutes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeCountry, setActiveCountry] = useState('');

    const pathType = location.pathname.includes("timetable") ? "timetable" : "times";
    const namazOrSalah = location.pathname.includes("namaz") ? "Namaz" : "Salah";

    useEffect(() => {
        const fetchRoutes = async () => {
            try {
                setIsLoading(true);
                const data = await getCmsRoutes();
                setRoutes(Array.isArray(data) ? data : []);
            } catch (err) {
                setError('Failed to load routes');
            } finally {
                setIsLoading(false);
            }
        };

        fetchRoutes();
    }, []);

    const groupAndSortRoutes = (routes) => {
        // Grouping logic as previously defined
        const grouped = routes.reduce((acc, route) => {
            if (!route?.route) return acc;

            const routeParts = route.route.split('/').filter(part => part);

            // Check if this is a direct city route (no country code)
            if (routeParts.length === 1) {
                if (!acc['Major Cities']) {
                    acc['Major Cities'] = [];
                }
                acc['Major Cities'].push({
                    ...route,
                    cityName: routeParts[0]
                });
            }
            // Handle country-specific routes
            else if (routeParts.length === 2) {
                const [countryCode, city] = routeParts;
                const countryName = findCountryName(countryCode);

                if (!acc[countryName]) {
                    acc[countryName] = [];
                }

                acc[countryName].push({
                    ...route,
                    cityName: city,
                    countryCode
                });
            }

            return acc;
        }, {});

        // Sort with countries first (alphabetically), then Major Cities last
        const sortedEntries = Object.entries(grouped).sort((a, b) => {
            const groupA = a[0];
            const groupB = b[0];

            if (groupA === 'Major Cities' && groupB === 'Major Cities') {
                return 0; // Both are Major Cities
            }
            if (groupA === 'Major Cities') {
                return 1; // groupA should come after groupB
            }
            if (groupB === 'Major Cities') {
                return -1; // groupA should come before groupB
            }
            // Normal alphabetical sort for countries
            return groupA.localeCompare(groupB);
        });

        // Convert back to an object, maintaining the sorted order
        const sortedGrouped = {};
        sortedEntries.forEach(([key, value]) => {
            sortedGrouped[key] = value;
        });

        return sortedGrouped;
    };

    const formatCountryName = (country) => {
        const countryMap = {
            'af': 'Afghanistan',
            'al': 'Albania',
            'dz': 'Algeria',
            'as': 'American Samoa',
            'ad': 'Andorra',
            'ao': 'Angola',
            'ai': 'Anguilla',
            'ag': 'Antigua and Barbuda',
            'ar': 'Argentina',
            'am': 'Armenia',
            'aw': 'Aruba',
            'au': 'Australia',
            'at': 'Austria',
            'az': 'Azerbaijan',
            'bs': 'Bahamas, The',
            'bh': 'Bahrain',
            'bd': 'Bangladesh',
            'bb': 'Barbados',
            'by': 'Belarus',
            'be': 'Belgium',
            'bz': 'Belize',
            'bj': 'Benin',
            'bm': 'Bermuda',
            'bt': 'Bhutan',
            'bo': 'Bolivia',
            'bq': 'Bonaire, Sint Eustatius, and Saba',
            'ba': 'Bosnia and Herzegovina',
            'bw': 'Botswana',
            'br': 'Brazil',
            'bn': 'Brunei',
            'bg': 'Bulgaria',
            'bf': 'Burkina Faso',
            'mm': 'Burma',
            'bi': 'Burundi',
            'cv': 'Cabo Verde',
            'kh': 'Cambodia',
            'cm': 'Cameroon',
            'ca': 'Canada',
            'ky': 'Cayman Islands',
            'cf': 'Central African Republic',
            'td': 'Chad',
            'cl': 'Chile',
            'cn': 'China',
            'cx': 'Christmas Island',
            'co': 'Colombia',
            'km': 'Comoros',
            'cg': 'Congo (Brazzaville)',
            'cd': 'Congo (Kinshasa)',
            'ck': 'Cook Islands',
            'cr': 'Costa Rica',
            'ci': 'Côte d’Ivoire',
            'hr': 'Croatia',
            'cu': 'Cuba',
            'cw': 'Curaçao',
            'cy': 'Cyprus',
            'cz': 'Czechia',
            'dk': 'Denmark',
            'dj': 'Djibouti',
            'dm': 'Dominica',
            'do': 'Dominican Republic',
            'ec': 'Ecuador',
            'eg': 'Egypt',
            'sv': 'El Salvador',
            'gq': 'Equatorial Guinea',
            'er': 'Eritrea',
            'ee': 'Estonia',
            'sz': 'Eswatini',
            'et': 'Ethiopia',
            'fk': 'Falkland Islands (Islas Malvinas)',
            'fo': 'Faroe Islands',
            'fj': 'Fiji',
            'fi': 'Finland',
            'fr': 'France',
            'gf': 'French Guiana',
            'pf': 'French Polynesia',
            'ga': 'Gabon',
            'gm': 'Gambia, The',
            'xg': 'Gaza Strip',
            'ge': 'Georgia',
            'de': 'Germany',
            'gh': 'Ghana',
            'gi': 'Gibraltar',
            'gr': 'Greece',
            'gl': 'Greenland',
            'gd': 'Grenada',
            'gp': 'Guadeloupe',
            'gu': 'Guam',
            'gt': 'Guatemala',
            'gg': 'Guernsey',
            'gn': 'Guinea',
            'gw': 'Guinea-Bissau',
            'gy': 'Guyana',
            'ht': 'Haiti',
            'hn': 'Honduras',
            'hk': 'Hong Kong',
            'hu': 'Hungary',
            'is': 'Iceland',
            'in': 'India',
            'id': 'Indonesia',
            'ir': 'Iran',
            'iq': 'Iraq',
            'ie': 'Ireland',
            'im': 'Isle of Man',
            'il': 'Israel',
            'it': 'Italy',
            'jm': 'Jamaica',
            'jp': 'Japan',
            'je': 'Jersey',
            'jo': 'Jordan',
            'kz': 'Kazakhstan',
            'ke': 'Kenya',
            'ki': 'Kiribati',
            'kp': 'Korea, North',
            'kr': 'Korea, South',
            'xk': 'Kosovo',
            'kw': 'Kuwait',
            'kg': 'Kyrgyzstan',
            'la': 'Laos',
            'lv': 'Latvia',
            'lb': 'Lebanon',
            'ls': 'Lesotho',
            'lr': 'Liberia',
            'ly': 'Libya',
            'li': 'Liechtenstein',
            'lt': 'Lithuania',
            'lu': 'Luxembourg',
            'mo': 'Macau',
            'mg': 'Madagascar',
            'mw': 'Malawi',
            'my': 'Malaysia',
            'mv': 'Maldives',
            'ml': 'Mali',
            'mt': 'Malta',
            'mh': 'Marshall Islands',
            'mq': 'Martinique',
            'mr': 'Mauritania',
            'mu': 'Mauritius',
            'yt': 'Mayotte',
            'mx': 'Mexico',
            'fm': 'Micronesia, Federated States of',
            'md': 'Moldova',
            'mc': 'Monaco',
            'mn': 'Mongolia',
            'me': 'Montenegro',
            'ms': 'Montserrat',
            'ma': 'Morocco',
            'mz': 'Mozambique',
            'na': 'Namibia',
            'nr': 'Nauru',
            'np': 'Nepal',
            'nl': 'Netherlands',
            'nc': 'New Caledonia',
            'nz': 'New Zealand',
            'ni': 'Nicaragua',
            'ne': 'Niger',
            'ng': 'Nigeria',
            'nu': 'Niue',
            'nf': 'Norfolk Island',
            'mk': 'North Macedonia',
            'mp': 'Northern Mariana Islands',
            'no': 'Norway',
            'om': 'Oman',
            'pk': 'Pakistan',
            'pw': 'Palau',
            'pa': 'Panama',
            'pg': 'Papua New Guinea',
            'py': 'Paraguay',
            'pe': 'Peru',
            'ph': 'Philippines',
            'pn': 'Pitcairn Islands',
            'pl': 'Poland',
            'pt': 'Portugal',
            'pr': 'Puerto Rico',
            'qa': 'Qatar',
            're': 'Reunion',
            'ro': 'Romania',
            'ru': 'Russia',
            'rw': 'Rwanda',
            'bl': 'Saint Barthelemy',
            'sh': 'Saint Helena, Ascension, and Tristan da Cunha',
            'kn': 'Saint Kitts and Nevis',
            'lc': 'Saint Lucia',
            'mf': 'Saint Martin',
            'pm': 'Saint Pierre and Miquelon',
            'vc': 'Saint Vincent and the Grenadines',
            'ws': 'Samoa',
            'sm': 'San Marino',
            'st': 'Sao Tome and Principe',
            'sa': 'Saudi Arabia',
            'sn': 'Senegal',
            'rs': 'Serbia',
            'sc': 'Seychelles',
            'sl': 'Sierra Leone',
            'sg': 'Singapore',
            'sx': 'Sint Maarten',
            'sk': 'Slovakia',
            'si': 'Slovenia',
            'sb': 'Solomon Islands',
            'so': 'Somalia',
            'za': 'South Africa',
            'gs': 'South Georgia and South Sandwich Islands',
            'ss': 'South Sudan',
            'es': 'Spain',
            'lk': 'Sri Lanka',
            'sd': 'Sudan',
            'sr': 'Suriname',
            'xr': 'Svalbard',
            'se': 'Sweden',
            'ch': 'Switzerland',
            'sy': 'Syria',
            'tw': 'Taiwan',
            'tj': 'Tajikistan',
            'tz': 'Tanzania',
            'th': 'Thailand',
            'tl': 'Timor-Leste',
            'tg': 'Togo',
            'to': 'Tonga',
            'tt': 'Trinidad and Tobago',
            'tn': 'Tunisia',
            'tr': 'Turkey',
            'tm': 'Turkmenistan',
            'tc': 'Turks and Caicos Islands',
            'tv': 'Tuvalu',
            'vi': 'U.S. Virgin Islands',
            'ug': 'Uganda',
            'ua': 'Ukraine',
            'ae': 'United Arab Emirates',
            'gb': 'United Kingdom',
            'uk': 'United Kingdom',
            'us': 'United States',
            'uy': 'Uruguay',
            'uz': 'Uzbekistan',
            'vu': 'Vanuatu',
            'va': 'Vatican City',
            've': 'Venezuela',
            'vn': 'Vietnam',
            'vg': 'Virgin Islands, British',
            'wf': 'Wallis and Futuna',
            'xw': 'West Bank',
            'ye': 'Yemen',
            'zm': 'Zambia',
            'zw': 'Zimbabwe'
        };
        return countryMap[country.toLowerCase()] || country.toUpperCase();
    };

    const formatCityName = (city) => {
        return city
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    if (isLoading) {
        return <div className="p-4 text-center">Loading routes...</div>;
    }

    if (error) {
        return <div className="p-4 text-center text-red-600">{error}</div>;
    }

    const groupedRoutes = groupAndSortRoutes(routes);
    const sortedGroups = Object.keys(groupedRoutes); // Use the order from groupAndSortRoutes

    return (
        <div className="bg-white">
            <div className="container mx-auto px-4 py-6">
                <h1 className="text-2xl font-bold text-gray-900 mb-6">
                    Prayer Times Sitemap
                </h1>

                <ul className="border border-gray-200 rounded-lg">
                    {sortedGroups.map((country) => {
                        const countryRoutes = groupedRoutes[country];
                        const isActive = activeCountry === country;

                        return (
                            <li key={country} className="border-b last:border-b-0">
                                <button
                                    onClick={() => setActiveCountry(isActive ? '' : country)}
                                    className="w-full px-4 py-3 text-left hover:bg-gray-50 flex justify-between items-center focus:outline-none"
                                    aria-expanded={isActive}
                                    aria-controls={`section-${country}`}
                                >
                                    <span className="font-medium">{country}</span>
                                    <span>{isActive ? '▼' : '▶'}</span>
                                </button>

                                {isActive && (
                                    <ul
                                        id={`section-${country}`}
                                        className="p-4 bg-gray-50"
                                        aria-label={`Routes for ${country}`}
                                    >
                                        <li>
                                            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
                                                {countryRoutes.map((route) => {
                                                    const displayCity = formatCityName(route.cityName);
                                                    return (
                                                        <li key={route.id} className="list-none">
                                                            <a
                                                                href={`/${namazOrSalah.toLowerCase()}-${pathType}${route.route}`}
                                                                className="text-sm text-gray-600 hover:text-blue-600 truncate"
                                                            >
                                                                {namazOrSalah} Times in {displayCity}
                                                            </a>
                                                        </li>
                                                    );
                                                })}
                                            </div>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default NamazTimeSitemapAccordion;