import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${encodeURIComponent(
        JSON.stringify(value)
    )};expires=${expires.toUTCString()};path=/`;
};

const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
        campaign_source: params.get('campaign_source') || params.get('utm_source'),
        campaign_medium: params.get('campaign_medium') || params.get('utm_medium'),
        campaign_name: params.get('campaign_name') || params.get('utm_campaign'),
    };
};

const CampaignTracker = () => {
    const location = useLocation();

    useEffect(() => {
        const { campaign_source, campaign_medium, campaign_name } = getQueryParams(location.search);

        // Only proceed if any of these parameters are present
        if (campaign_source || campaign_medium || campaign_name) {
            // Check if 'campaign_data' cookie already exists
            const existingCookie = document.cookie
                .split('; ')
                .find((row) => row.startsWith('campaign_data='));

            // If the cookie doesn't exist, set it for 7 days
            if (!existingCookie) {
                const campaignData = {
                    campaign_source,
                    campaign_medium,
                    campaign_name,
                };
                setCookie('campaign_data', campaignData, 7);
            }

            // Prepare data for POST request
            const data = [
                {
                    campaignName: campaign_name || '',
                    campaignSource: campaign_source || '',
                    campaignMedium: campaign_medium || '',
                },
            ];

            // Send POST request
            fetch('https://matw.finbix.io/254fd2a6-5977-40be-8fad-95b5edfa0c98/api/campaign/logs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.text(); // Use text() or json() based on your API response
                })
                .then((responseText) => {
                    console.log('Success:', responseText);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [location.search]);

    return null;
};

export default CampaignTracker;
