export const RamadanWidgetSlide = ({ className, children }) => {
    return (
      <div
        className={`overflow-x-hidden bg-[#d1e8f7] border border-[#00a3da] text-[#00a3da] p-2 text-center ${className ? className : ""}`}
      >
        {/* <div className="flex items-center relative max-w-full"> */}
          {/* First slide */}
          {/* <div className="ramadan-widget-slide min-w-max inline-block whitespace-nowrap pr-5">{children}</div> */}
  
          {/* Second slide (starts off to the right) */}
          {/* <div className="ramadan-widget-slide min-w-max inline-block whitespace-nowrap pr-5">{children}</div> */}
        {/* </div> */}
        {children}
      </div>
    );
  };
  