import axios from 'axios';

const Base = process.env.REACT_APP_BASE_URL;
const Header = process.env.REACT_APP_API_HEADER;

let config = {
  headers: {
    'X-user-matw': Header,
    Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
  },
};

function getAllMenus() {
  console.log(Base);

  return axios.get(`${Base}/menus`, config);
}

function getNamazTimes(location) {
  return [
    {
      id: '1',
      title: location.pathname.includes('namaz-times') ? 'Namaz Times' : 'Salah Times',
      url: location.pathname,
      status: true,
      children: []
    },
    {id: '2', title: 'Fajr', url: location.pathname, status: true, children: []},
    {id: '3', title: 'Dhuhr', url: location.pathname, status: true, children: []},
    {id: '4', title: 'Asr', url: location.pathname, status: true, children: []},
    {id: '5', title: 'Maghrib', url: location.pathname, status: true, children: []},
    {id: '6', title: 'Isha', url: location.pathname, status: true, children: []}
  ];
}

function getMegaMenuSearchSection() {
  return axios.get(`${Base}/menus?section=search&domain=matwproject.org`, config)
}

function validateRouteWithBackend(route) {
  //https://matwproject.org/backend/index.php/api/routes?route=/uk/birmingham&route_type=namaz-time
  console.log(`${Base}/routes?route=/${route}&route_type=namaz-time`)
  return axios.get(`${Base}/routes?route=/${route}&route_type=namaz-time`)
}

function getAdminAllMenus() {
  return axios.get(`${Base}/admin-dashboard/menus`, config);
}

function createMenu(data) {
  let config = {
    headers: {
      'accept': 'application/json',
      'Authorization': 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      "X-user-matw": Header
    },
  };
  return axios.post(`${Base}/admin-dashboard/menus`, data, config);
}

function createHighlightMenu(data, file) {
  let config = {
    headers: {
      accept: 'application/json',
      'content-type': file.type,
      'content-length': `${file.size}`,
      'X-user-matw': Header,
    },
  };
  return axios.post(`${Base}/admin-dashboard/menus`, data, config);
}

function updateHighlightMenu(id, data, file) {
  let config = {
    headers: {
      accept: 'application/json',
      'content-type': file ? file.type : '',
      'content-length': `${file ? file.size : ''}`,
      'X-user-matw': Header,
    },
  };
  return axios.post(`${Base}/admin-dashboard/menus/${id}?_method=patch`, data, config);
}

function updateMenu(id, data) {
  let config = {
    headers: {
      'accept': 'application/json',
      'Authorization': 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      "X-user-matw": Header
    },
  };
  return axios.put(`${Base}/admin-dashboard/menus/${id}`, data, config);
}

function deleteMenu(id) {
  let config = {
    headers: {
      'accept': 'application/json',
      'Authorization': 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      "X-user-matw": Header
    },
  };
  return axios.delete(`${Base}/admin-dashboard/menus/${id}`, config);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllMenus,
  getAdminAllMenus,
  updateMenu,
  createMenu,
  deleteMenu,
  createHighlightMenu,
  updateHighlightMenu,
  getMegaMenuSearchSection,
  validateRouteWithBackend,
  getNamazTimes
};
