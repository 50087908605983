import React from 'react'
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import banner from '../../images/ramadan/MATW_Ramadan_2025_WebBanner.jpg';
import bannerMobile from '../../images/ramadan/MATW_Ramadan_2025_WebBanner.jpg';
import RamadanDailyDonationWidget from '../../components/ramadan/ramadanDailyDonation';
import { orphansFaqs } from '../orphans/faqs';
import Accordion from '../../components/general/accordion';

const RamadanDailyDonation = () => {
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const products = [
        'OPR-GEN-SUP-2023-01-01',
        'MD-AA-2024-02',
        'MKD-MN-001',
        'MD-AA-2024-07',
        'MKD-MN-001',
        'MKD-GEN-MFA-2023-01-118'
    ]
    /* ------------------------------ Start methods ----------------------------- */
    return (
        <NewHelmetLayout hideFooter className="bg-white " childClasses={'pb-0'}>
            {seo &&
                <Helmet>
                    <title>{`${seo['ramadanDua']['title']}`}</title>
                    <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
                    <meta name="description" content={`${seo['ramadanDua']['description']}`} />
                </Helmet>
            }
            <section className="flex justify-center">
                <img src={banner} alt="ramadan 2024" className="max-[500px]:hidden w-full" />
                <img src={bannerMobile} alt="ramadan 2024" className="min-[501px]:hidden w-full" />
            </section>
            <section className="w-full md:container md:mx-auto py-4 md:py-8 gap-4">
                <div className="w-full">
                    <div className="flex justify-center">
                        <div className="font-brandingBold sm:text-center font-bold text-[#093686] text-2xl sm:text-3xl lg:text-5xl px-4 w-full sm:w-10/12">
                            Every day in Ramadan is precious, so make the most of it.
                            <br />
                            <span className="text-[#F60362] mr-2">Automate and Give Daily.</span>
                        </div>
                    </div>
                    <div className="px-4 md:px-6 mt-2 sm:mt-10 leading-5">
                        <div className="text-[#78716C] text-lg text-left font-brandingMedium">
                            Every day in Ramadan is a day in which we aim to reap Allah’s Blessings and His Rewards in abundance. Each day is a day closer to attaining Jannah.
                            <br />
                            <br />
                            At MATW Project, we’ve created our By 30 steps to Jannah daily giving platform, which enables you to automate your donations throughout the entire month of Ramadan.
                            <br />
                            <br />
                            We know that the next moment is not promised to us, neither is the next day so what better way to maximise our giving then to automate them, ensuring that we don’t miss a single reward during the holy month - especially during the last ten nights? Rest assured that your donation is in the best hands. Our team will make sure that your donations are automated, and that not a single day of Ramadan, including Laylatul Qadr, is missed.
                            <br />
                            <br />
                            Automate your donations now.
                        </div>
                    </div>
                </div>
                {/* <div className="w-full px-2 md:w-10/12 md:mx-auto max-[380px]:min-h-[209px] min-[381px]:min-h-[300px]- min-[410px]:min-h-[300px] sm:h-[300px] md:h-[600px] lg:h-[1000px]- py-4 md:py-8">
                    <div className="font-brandingBold sm:text-center font-bold text-[#093686] text-xl sm:text-4xl px-4 w-full mb-4">
                        How it works?
                    </div>
                    <iframe
                        className="video pointer-events-none rounded-md"
                        width={'100%'}
                        height={'100%'}
                        title={'youtube'}
                        frameBorder={0}
                        sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                        src={`https://www.youtube.com/embed/ciUzaL90cxA?controls=0&autoplay=1&loop=1&mute=1&playlist=ciUzaL90cxA`}
                    ></iframe>
                </div> */}
                <div className="w-full mt-4 md:mt-8">
                    <RamadanDailyDonationWidget productsList={products} />
                </div>
                <div className="">
                    <h1 className="text-[#253B7E] text-center my-4 text-[30px] sm:text-[60px] font-bold">
                        Ramadan
                        <span className="text-[#F60362]"> FAQ’s</span>
                    </h1>
                    <div className="grid grid-cols-3 gap-x-16 gap-y-8">
                        <div className="col-span-3 sm:col-span-1">
                            <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{orphansFaqs[0].question['en']}</h1>
                            <p className="text-base font-brandingMedium leading-4 mt-3">{orphansFaqs[0].answer['en']}</p>
                        </div>
                        <div className="col-span-3 sm:col-span-1">
                            <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{orphansFaqs[1].question['en']}</h1>
                            <p className="text-base font-brandingMedium leading-4 mt-3">{orphansFaqs[1].answer['en']}</p>
                        </div>
                        <div className="col-span-3 sm:col-span-1">
                            <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{orphansFaqs[2].question['en']}</h1>
                            <p className="text-base font-brandingMedium leading-4 mt-3">{orphansFaqs[2].answer['en']}</p>
                        </div>
                    </div>
                    <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                        {orphansFaqs.map((item, index) => {
                            return (
                                index > 2 && (
                                    <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                                        <Accordion key={`keyallfaq${index}`} shadow title={item.question['en']} index={index} level={1} noBorder={true}>
                                            {item.answer['en']}
                                        </Accordion>
                                    </div>
                                )
                            );
                        })}
                    </div>
                </div>
            </section>
        </NewHelmetLayout>
    )
}

export default RamadanDailyDonation