import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { IoMdClose, IoMdInformationCircle } from 'react-icons/io';
import { Tooltip } from 'react-tooltip';

const PersistentTimerMain = ({ styleMain }) => {
  const TIMER_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

  const [togglePopup, setTogglePopup] = useState(true);

  const resetToggle = useCallback(() => {
    setTimeout(() => {
      setTogglePopup(true);
    }, 300);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = getTimeRemaining();
      setTimeRemaining(remaining);

      if (remaining.total <= 0) {
        resetTimer();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function getTimeRemaining() {
    const storedEndTime = localStorage.getItem('timerEndTime');
    const now = new Date().getTime();

    if (storedEndTime) {
      const endTime = parseInt(storedEndTime, 10);
      const diff = endTime - now;

      if (diff > 0) {
        return {
          total: diff,
          minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((diff % (1000 * 60)) / 1000),
        };
      }
    }

    resetTimer();
    return getTimeRemaining();
  }

  function resetTimer() {
    const newEndTime = new Date().getTime() + TIMER_DURATION;
    localStorage.setItem('timerEndTime', newEndTime);
  }

  return (
    <div className={`font-inter font-bold text-sm text-white ${styleMain ? styleMain : ''}`}>
      <span className="flex items-center">
        <span id="clickable">
          <IoMdInformationCircle className="text-xl hidden sm:block" />
        </span>
        {togglePopup && (
          <Tooltip anchorSelect="#clickable" clickable className="max-w-xs z-[1200] flex flex-col gap-2">
            <IoMdClose
              className="text-white font-medium text-lg cursor-pointer ml-auto"
              onClick={() => {
                setTogglePopup(false);
                resetToggle();
              }}
            />
            <p>
              We are delivering food, water, essentials on a daily basis, every minute counts. 100% of your donation goes to those most in need.
              Please don’t leave without making even a small donation. Every amount counts. You are their lifeline.
            </p>
          </Tooltip>
        )}
        <span className="min-w-[160px] ml-0.5">
          <IoMdInformationCircle className="text-xl align-middle inline sm:hidden" id="clickable" /> Donate in {timeRemaining.minutes}m{' '}
          {timeRemaining.seconds}s to help Gaza
        </span>
      </span>
    </div>
  );
};

export default PersistentTimerMain;
