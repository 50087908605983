import React from 'react'

const DonatePalestineAppealSection = ({ arabic, lang = 'en' }) => {
  return (
    <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}>
      <h1 className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center ${arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}>
        YOU GIVE, THEY LIVE: <span className="text-[#f60362]">Be the Light in Their Darkest Hour</span>
      </h1>
      <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratBold'}`}>
        <p>
            For over a year, the people of Palestine have faced relentless devastation—losing their homes, loved ones, and even hope. 
            Blocked from access to basic necessities like food, water, and medical care, their reality is unimaginable. Yet, amidst the destruction, 
            one truth shines bright: your generosity saves lives.
        </p>
        <p>
            YOU GIVE, THEY LIVE isn’t just a slogan—it’s a promise. A promise that every donation you give carries more than aid—it carries hope, dignity, 
            and the strength to persevere. Your support has rebuilt lives, restored shattered spirits, and proven that humanity stands united in the face of despair.
        </p>
        <p>
            But the fight is far from over. The people of Gaza still desperately need us. Their survival depends on our continued compassion. 
            Stand with Gaza today—because when you give, they live.
        </p>
      </div>
    </div>
  )
}

export default DonatePalestineAppealSection;