import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import ReactSelect from 'react-select';
import creditImg from '../../images/payment/credit-cards.png';
import SelectCurrency from '../general/selectCurrency';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { selectProducts } from '../../store/products';
import CustomButton from '../general/button';
import ShowCurrencies from '../../utils/showCurrencies';
import useCart from '../../hooks/cart/useCart';
import { openSheet } from '../../store/sheetToggler';

const styles = {
  menuList: base => ({
    ...base,

    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
};

const WidgetHome = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    selectedCurrency,
    className,
    defaultValue = 150,
    defaultProduct: { creator = 'EMR-LEB-24-000', label = 'Lebanon Emergency Appeal', value = 674 } = {},
    addToCart = true,
  } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const products = useSelector(state => selectProducts(state));
  const [addProduct] = useHandleAddProduct();
  const [currentCurrency, setCurrentCurrency] = useState();
  const [selectedProduct, setSelectedProduct] = useState({
    creator: creator,
    label: label,
    value: value,
  });
  // const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedPrice, setSelectedPrice] = useState(props.selectedPrice || 200);
  const [productAmount, setProductAmount] = useState(selectedPrice ? null : defaultValue);
  const [currentProduct, setCurrentProduct] = useState({
    creator: creator,
    label: label,
    value: value,
  });
  const suggestedPrices = props.suggestedPrices || [100, 150, 200, 500];
  /* -------------------------------------------------------------------------- */
  // const handleAmountChange = e => {
  //   const newValue = e.target.value;
  //   const regex = /^(?:[1-9]\d*)?$/;

  //   if (regex.test(newValue)) {
  //     setProductAmount(newValue);
  //   }
  // };
  const dispatch = useDispatch();
    const {
        handleAddToGiving: handleAddToGivingCart,
      } = useCart();
  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempMakeDonationProducts = [];
    let tempProductsList = [];
    let tempImportantProducts = [];
    let tempSelectedPrd = null;
    let tempWhereMostNeeded = null;
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === creator) {
            // setSelectedProduct(inner_item_product);
            tempSelectedPrd = inner_item_product;
          }
          if (inner_item.name === 'Earthquake Appeal') {
            if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') {
              // Morocco Appeal
              tempMakeDonationProducts.push(inner_item_product);
              tempImportantProducts[3] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
            }
          }
          if (inner_item.name === 'Libya Floods') {
            if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') {
              // Libya Floods
              tempMakeDonationProducts.push(inner_item_product);
              tempImportantProducts[4] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
            }
          }
          if (inner_item_product.creator === 'EMR-LEB-24-000') {
            // Palestine Appeal
            tempImportantProducts[0] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
            tempMakeDonationProducts.push(inner_item_product);
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            // Palestine Appeal
            // setSelectedProduct(inner_item_product);
            // setCurrentProduct({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
            tempImportantProducts[1] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
            tempMakeDonationProducts.push(inner_item_product);
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') {
            // Palestine Medical Relief
            tempMakeDonationProducts.push(inner_item_product);
            tempImportantProducts[2] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
          }
          if (inner_item_product.creator === 'ORP-CON-LEB-24-00') {
            tempMakeDonationProducts.push(inner_item_product);
            tempImportantProducts.splice(3, 0, { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          }
          if (inner_item_product.creator === 'EMR-PAL-GEN-24-11-1') {
            tempMakeDonationProducts.push(inner_item_product);
            tempImportantProducts.splice(4, 0, {
              value: inner_item_product.id,
              label: inner_item_product.name,
              creator: inner_item_product.creator,
            });
          }
          if (inner_item.name === 'Make A Donation') {
            if (inner_item_product.creator === 'MKD-MN-001') {
              // Where Most Needed
              tempWhereMostNeeded = inner_item_product;
              // setCurrentProduct({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            // if (inner_item_product.creator === 'MKD-MN-001') {
            //   // Where Most Needed
            //   setSelectedProduct(inner_item_product);
            //   setCurrentProduct({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            // }
            if (inner_item_product.creator !== 'MKD-EM-TUR-EQ-01-2023') {
              tempMakeDonationProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
          }
          if (inner_item_product.creator === defaultValue.creator) {
            setSelectedProduct(inner_item_product);
            setCurrentProduct({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    if(tempSelectedPrd) {
      setSelectedProduct(tempSelectedPrd)
    } else {
      setSelectedProduct(tempWhereMostNeeded);
      setCurrentProduct({ value: tempWhereMostNeeded.id, label: tempWhereMostNeeded.name, creator: tempWhereMostNeeded.creator })
    }
    tempImportantProducts = tempImportantProducts.concat(tempProductsList);
    tempImportantProducts = tempImportantProducts.filter(n => n);
    setProductsList(tempImportantProducts);
    setAllProducts(tempMakeDonationProducts);
  };
  const handleCurrency = currency => {
    setCurrentCurrency(currency.value);
    selectedCurrency(currency);
  };
  const handleProduct = value => {
    allProducts.map(item => item.id === value.value && setSelectedProduct(item));
    setCurrentProduct(value);
  };
  const handleAddNewProduct = () => {
    let tempProduct = selectedProduct;
    let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice) ? Number(selectedPrice) : 100;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      //   recurring: selectedRecurring.value,
    });

    if (addToCart) {
      handleAddToGivingCart(tempProduct, 'custom');
      dispatch(openSheet());
    } else {
      addProduct({ product: tempProduct, currency: currentCurrency });
    }
  };
  // const handleScroll = () => {
  //     const position = window.pageYOffset;
  //     setScrollPosition(position);
  // };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // getProducts();
    // window.addEventListener('scroll', handleScroll, { passive: true });
    // return () => {
    //     window.removeEventListener('scroll', handleScroll);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    // <div className={`w-full bg-[#00a3da] px-3 md:px-6 py-3 md:py-6 flex items-center ${className} ${scrollPosition > 300 ? 'fixed top-0 md:top-[64px] lg:top-[96px] w-full z-30' : 'relative'}`}>
    <div
      className={`w-full bg-[#00a3da] px-3 md:px-6 py-3 md:py-6 flex items-center ${className} sticky top-16 md:top-[64px] lg:top-[96px] w-full z-30`}
    >
      <div className="w-full md:container md:mx-auto ">
        <div className="grid grid-cols-12 lg:grid-cols-[1.5fr_1fr_1.5fr_1fr_1fr] gap-2 lg:gap-2 md:contaier mx-auto lg:px-2">
          <div className="col-span-5 lg:col-span-1 order-1 flex items-center bg-white rounded-md h-[38px]">
            <div className="min-w-max">
              <SelectCurrency onChangeCurrency={e => handleCurrency(e)} classNamePrefix="home_select" />
            </div>
            <input
              value={productAmount || 0}
              name="amount"
              className="h-[38px] text-[#777] w-full truncate p-2 rounded-lg text-sm pl-1 md:text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
              type="number"
              min={'0'}
              onChange={e => {
                const regex = /^(?:[1-9]\d*)?$/;
                if (regex.test(e.target.value)) {
                  setProductAmount(e.target.value);
                  setSelectedPrice(null);
                }
              }}
              placeholder="Amount"
            />
          </div>
          <div className="col-span-12 lg:col-span-1 order-3 lg:order-2 grid grid-cols-12 lg:flex gap-2">
            {suggestedPrices.map((price, index) => {
              return (
                <div key={`prices${index}`} className="col-span-3">
                  <div
                    className={`h-[38px] text-sm transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[60px] rounded-md lg:hover:bg-[#F60362] lg:hover:text-white ${
                      selectedPrice === price ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362]'
                    }`}
                    onClick={() => {
                      setSelectedPrice(price);
                      setProductAmount('');
                    }}
                  >
                    {ShowCurrencies(currentCurrency, price, false)}
                    <span className={`uppercase ml-1 text-xs`}>{currentCurrency}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-span-7 lg:col-span-1 order-2 lg:order-3">
            <ReactSelect
              options={productsList}
              className="max-h-[48px] [&>div]:!rounded-md"
              classNamePrefix="home-select"
              defaultValue={currentProduct}
              isSearchable={false}
              styles={styles}
              onChange={e => handleProduct(e)}
            />
          </div>
          <div className="col-span-6 lg:col-span-1 order-4 rounded-md px-3 h-[38px] bg-[#80ceff] flex items-center justify-center">
            <img
              src={creditImg}
              alt="MATW"
              className="w-full h-auto w-[260px]- h-[20px]- sm:w-[254px]- sm:h-[28px]- md:w-[221px]- md:h-[25px]- lg:w-[125px]- lg:h-[14px]- xl:w-full- xl:h-auto-"
            />
          </div>
          <div className="order-5 col-span-6 lg:col-span-1">
            <CustomButton
              onClick={() => {
                handleAddNewProduct();
              }}
              title={'Quick Donate'}
              disabled={!isLoaded}
              className={`w-full hover:!bg-[#F60362] !h-[38px] !px-2 ${isLoaded ? 'blob red' : ''}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetHome;
