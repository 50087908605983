import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { getReceipt } from '../../services/stripe';
import headerImg from './../../images/others/header.jpg';
import footerImg from './../../images/others/MATW_Gaza_1_Year_2024_ReceiptBanner.jpg';
import thanksImg from './../../images/others/thankyou.png';
import services from '../../services';
import Moment from 'moment';
import ShowCurrencies from '../../utils/showCurrencies';
import AddUniversalUSAScript from '../../utils/addUniversalUSAScript';
// import CopyToClipboard from '../../utils/copyToClipboard';

const ReceiptPage = () => {
  const [params] = useSearchParams();
  const ID = params.get('session_id');
  const PAYPAL_ID = params.get('id');
  const Transaction_ID = params.get('transaction_id');
  const GATEWAY = params.get('gateway');
  const MODE = params.get('mode');
  const [isUk, setIsUk] = useState(false);
  const [isUsa, setIsUsa] = useState(false);
  const [checkoutData, setCheckoutData] = useState(null);
  /* ------------------------------ Start methods ----------------------------- */
  const handlePaypalOrder = async order_id => {
    try {
      const response = await services.getPaypalOrderReceipt(order_id);
      console.log('response getPaypalOrderReceipt', response);
      // response?.data.currency.toLowerCase() === 'aud' ? setIsUk(false) : setIsUk(true)
      setIsUk(false);
      let model = {
        name: response?.data.user?.name,
        email: response?.data.user?.email,
        donorID: response?.data.user?.id,
        gateway: 'PayPal',
        donationDate: response?.data.created_at,
        address: '',
        // donationID: response?.data.id,
        donationID: response?.data.paypal_order_id,
        paymentMethod: 'PayPal',
        onBehalf: response?.data?.plaque_name,
        currency: response?.data.currency,
        donationTable: response?.data.paypal_order_products?.map(i => ({
          type: i.product ? i.product.name : '',
          project: i.product ? i.product.name : '',
          // QTY: i.quantity,
          QTY: Number(i.value) === 1 ? 1 : i.quantity,
          // amount: i.value,
          // amount: (i.product && i.product.name === 'Admin Contribution') &&  response?.data.currency === 'EUR' ? 2.50 :  i.value,
          amount:
            i.product && i.product.name === 'Admin Contribution' && response?.data.currency === 'EUR'
              ? 2.5
              : Number(i.value) === 1
              ? i.quantity
              : i.value,
          currency: response?.data.currency,
          mode: 'Payment',
          frequency: 'one-off',
          plaque_name: i.plaque_name ? i.plaque_name : 'For the sake of Allah SWT',
          // plaque_name: i.plaque_name,
        })),
        totalPrice: response?.data.amount_total,
      };
      if (response?.data.paypal_order_products[0] && response?.data.paypal_order_products[0].value === 1) {
        model.donationTable[0].amount = response?.data.paypal_order_products[0].quantity;
        model.donationTable[0].QTY = response?.data.paypal_order_products[0].value;
      }
      console.log("before set paypal=",model)
      setCheckoutData(model);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handlePaypalSubscription = async order_id => {
    try {
      const response = await services.getPaypalSubscriptionReceipt(order_id);
      console.log('response getPaypalSubscriptionReceipt', response);
      // response?.data.currency.toLowerCase() === 'aud' ? setIsUk(false) : setIsUk(true)
      setIsUk(false);
      let model = {
        name: response?.data.user?.name,
        email: response?.data.user?.email,
        donorID: response?.data.user?.id,
        gateway: 'PayPal',
        donationDate: response?.data.created_at,
        address: '',
        donationID: response?.data.id,
        paymentMethod: 'PayPal',
        currency: response?.data.currency,
        // currency: response?.data.plan.currency.name,
        donationTable: [
          {
            type: response?.data.plan.product.category.name,
            project: response?.data.plan.product.name,
            QTY: 1,
            amount: response?.data.plan.unit_amount,
            currency: response?.data.plan.currency.name,
            mode: 'Subscription',
            frequency: response?.data.plan.recurring,
            plaque_name: response?.data.plan.product.plaque_name,
          },
        ],
        // donationTable: response?.data.paypal_order_products?.map(i => ({
        //     type: i.product.name,
        //     project: i.product.name,
        //     QTY: i.quantity,
        //     amount: i.value
        // })),
        // totalPrice: response?.data.plan.unit_amount,
        totalPrice: response?.data.plan.unit_amount,
      };
      if (response?.data.plan.unit_amount === 1) {
        model.donationTable[0].amount = response?.data.paypal_order_products[0].quantity;
        model.donationTable[0].QTY = response?.data.paypal_order_products[0].value;
      }
      console.log("before set paypal subscription=",model)
      setCheckoutData(model);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleCryptoOrder = async order_id => {
    // let tempRequest = Transaction_ID ? `id=${PAYPAL_ID}&transaction_id=${Transaction_ID}` : `id=${PAYPAL_ID}`
    let tempRequest = Transaction_ID ? `id=${order_id}&transaction_id=${Transaction_ID}` : `id=${order_id}`;
    try {
      const response = await services.getCryptoReceipt(tempRequest);
      console.log('response get CryptoOrder', response);
      setIsUk(true);
      let _crypto_total_paid = 0;
      response?.data.crypto_transactions?.map(i => (_crypto_total_paid = _crypto_total_paid + Number(i.crypto_currency_total_amount_paid)));
      let model = {
        name: response?.data.user?.name,
        email: response?.data.user?.email,
        donorID: response?.data.user?.id,
        gateway: 'Crypto',
        donationDate: response?.data.created_at,
        address: '',
        donationID: response?.data.reference_id,
        paymentMethod: 'Crypto',
        onBehalf: response?.data?.plaque_name,
        currency: response?.data.fiat_currency,
        total_paid_donations: response?.data.crypto_currency_total_amount_paid,
        donationTable: response?.data.crypto_order_products?.map(i => ({
          type: i.product ? i.product.name : '',
          project: i.product ? i.product.name : '',
          // QTY: Number(i.unit_price) === 1 ? 1 : i.quantity,
          QTY: i.quantity,
          // amount: (i.product && i.product.name === 'Admin Contribution') && response?.data.fiat_currency === 'EUR' ? 2.50 : Number(i.unit_price) === 1 ? i.quantity : i.unit_price,
          amount: i.product && i.product.name === 'Admin Contribution' && response?.data.fiat_currency === 'EUR' ? 2.5 : i.unit_price,
          currency: response?.data.fiat_currency,
          mode: 'Payment',
          frequency: 'one-off',
          plaque_name: i.plaque_name ? i.plaque_name : 'For the sake of Allah SWT',
        })),
        crypto_total_paid: _crypto_total_paid,
        cryptoTable: response?.data.crypto_transactions?.map(i => ({
          type: i.type ? i.type : '',
          transaction_id: i.transaction_id ? i.transaction_id : '',
          crypto_currency: i.crypto_currency ? i.crypto_currency : '',
          total_crypto_amount: i.total_crypto_amount ? i.total_crypto_amount : '',
          crypto_currency_total_amount_paid: i.crypto_currency_total_amount_paid ? Number(i.crypto_currency_total_amount_paid) : 0,
          date: i.created_at ? i.created_at : '',
        })),
        totalPrice: Number(response?.data.fiat_currency_total_amount),
      };
      if (response?.data.crypto_order_products && response?.data.crypto_order_products[0] && response?.data.crypto_order_products[0].value === 1) {
        model.donationTable[0].amount = response?.data.crypto_order_products[0].quantity;
        model.donationTable[0].QTY = response?.data.crypto_order_products[0].value;
      }
      console.log("before set crypto=",model)
      setCheckoutData(model);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleRecurring = value => {
    switch (value) {
      case 'day':
        return 'Daily';
      case 'DAY':
        return 'Daily';
      case 'month':
        return 'Monthly';
      case 'week':
        return 'Weekly';
      case 'one-off':
        return 'Single';
      default:
        return '';
    }
  };
  const handleFirstCharCapital = text => {
    if (text && text !== '') {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  };
  const handleAdyenOrder = async order_id => {
    let tempRequest = Transaction_ID ? `id=${order_id}&transaction_id=${Transaction_ID}` : `id=${ID}`;
    try {
      const response = await services.getAdyenReceipt(tempRequest);
      const Data = response.data.checkout_session;
      let model = {
        name: Data?.user?.name,
        email: Data?.user?.email,
        donorID: Data?.user?.id,
        gateway: 'Adyen',
        donationDate: Data?.created_at,
        address: '',
        donationID: Data?.id,
        paymentMethod: 'Adyen',
        currency: Data?.currency,
        onBehalf: Data?.plaque_name,
        donationTable: Data?.checkout_session_products?.map(i => ({
          type: i.stripe_price_point.product.category ? i.stripe_price_point.product.category.name : '',
          project: i.stripe_price_point.product.name,
          QTY: i.amount ? (Number(i.amount) === 1 ? 1 : i.quantity) : Number(i.stripe_price_point.unit_amount) === 1 ? 1 : i.quantity,
          amount: i.amount
            ? Number(i.amount) === 1
              ? i.quantity
              : Number(i.amount)
            : Number(i.stripe_price_point.unit_amount) === 1
            ? i.quantity
            : Number(i.stripe_price_point.unit_amount),
          currency: Data?.currency,
          // mode: Data.mode || 'Payment',
          mode: 'Payment',
          plaque_name: i.plaque_name ? i.plaque_name : 'For the sake of Allah SWT',
          frequency: i.stripe_price_point.recurring,
        })),
        totalPrice: Data.amount_total ? Data.amount_total : 0,
      };
      // console.log("before set adyen=",model)
      setCheckoutData(model);
    } catch (error) {
     console.log("error=",error)
    }
  }
  const customUseScriptOnHead = (src) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    document.getElementsByTagName('head')[0].appendChild(script);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    if (ID) {
      if(GATEWAY === 'stripe') {
        let tempRequest = Transaction_ID ? `id=${ID}&transaction_id=${Transaction_ID}` : `id=${ID}`;
        getReceipt(tempRequest).then(data => {
          if (data.data) {
            const Data = data.data.checkout_session;
            if (Data?.stripe_account.toLowerCase() === 'au') {
              setIsUk(false);
              setIsUsa(false);
            } else if (Data?.stripe_account.toLowerCase() === 'us') {
              setIsUk(false);
              setIsUsa(true);
              // setIsUsa(false)
              // setIsUk(true);
            } else {
              setIsUk(true);
              setIsUsa(false);
            }
            // Data?.currency.toLowerCase() === 'aud' ? setIsUk(false) : setIsUk(true)
            // Data?.currency.toLowerCase() === 'usd' ? setIsUsa(false) : setIsUsa(true)
            //convert to model
            let model = {
              name: Data?.user?.name,
              email: Data?.user?.email,
              donorID: Data?.user?.id,
              gateway: 'Stripe',
              donationDate: Data?.created_at,
              address: '',
              donationID: Data?.id,
              paymentMethod: 'Card Payment',
              currency: Data?.currency,
              onBehalf: Data?.plaque_name,
              donationTable: Data?.checkout_session_products?.map(i => ({
                type: i.stripe_price_point.product.category ? i.stripe_price_point.product.category.name : '',
                project: i.stripe_price_point.product.name,
                // QTY: i.quantity,
                // amount: i.stripe_price_point.unit_amount,
                QTY: i.amount ? (Number(i.amount) === 1 ? 1 : i.quantity) : Number(i.stripe_price_point.unit_amount) === 1 ? 1 : i.quantity,
                amount: i.amount
                  ? Number(i.amount) === 1
                    ? i.quantity
                    : Number(i.amount)
                  : Number(i.stripe_price_point.unit_amount) === 1
                  ? i.quantity
                  : Number(i.stripe_price_point.unit_amount),
                currency: Data?.currency,
                mode: Data.mode,
                // plaque_name: i.plaque_name,
                plaque_name: i.plaque_name ? i.plaque_name : 'For the sake of Allah SWT',
                frequency: i.stripe_price_point.recurring,
              })),
              totalPrice: Data.amount_total ? Data.amount_total : 0,
            };
            // if(Data?.checkout_session_products[0].stripe_price_point.unit_amount === 1) {
            //   model.donationTable[0].amount = Data?.checkout_session_products[0].quantity;
            //   model.donationTable[0].QTY = Data?.checkout_session_products[0].stripe_price_point.unit_amount;
            // }
            // model.donationTable.push(model.donationTable[0]);
            console.log("before set stripe=",model)
            setCheckoutData(model);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ID]);
  useEffect(() => {
    console.log('GATEWAY', GATEWAY);
    if (GATEWAY === 'paypal') {
      if (MODE === 'subscription') {
        handlePaypalSubscription(PAYPAL_ID);
      } else {
        handlePaypalOrder(PAYPAL_ID);
      }
    } else if (GATEWAY === 'stripe') {
    } else if (GATEWAY === 'crypto') {
      handleCryptoOrder(PAYPAL_ID);
    } else if(GATEWAY === 'adyen') {
      handleAdyenOrder(ID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GATEWAY]);
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
    // customUseScriptOnHead(`var head = document.head;var script = document.createElement('script');script.type = 'text/javascript';script.src = "https://206788.t.hyros.com/v1/lst/universal-script?ph=01ca8fee456895e2b732862de7b2f523f7024ba386b77655db6e444828c725b8&tag=!clicked&ref_url=" + encodeURI(document.URL) ;head.appendChild(script);`)
    // customUseScriptOnHead(`var head = document.head;var script = document.createElement('script');script.type = 'text/javascript';script.src = "https://t.matwprojectusa.org/v1/lst/universal-script?ph=01ca8fee456895e2b732862de7b2f523f7024ba386b77655db6e444828c725b8&tag=!clicked&ref_url=" + encodeURI(document.URL);head.appendChild(script);`)
    AddUniversalUSAScript();
    if (window.location.hostname) {
      switch (window.location.hostname) {
        case 'matwproject.org.uk':
          customUseScriptOnHead('https://cdn.pagesense.io/js/matwinternational/c5fee5fb9e2c4549a414161c2ec990ae.js');
          break;
        case 'matwprojectusa.org':
          customUseScriptOnHead('https://cdn.pagesense.io/js/matwinternational/745e32aa2459469baaffff6cf38fd1a0.js');
          break;
        case 'matwprojectca.org':
          customUseScriptOnHead('https://cdn.pagesense.io/js/matwinternational/6870f7c1d5004f639d08724f4ce631fc.js');
          break;
        case 'matwprojectme.org':
          customUseScriptOnHead('https://cdn.pagesense.io/js/matwinternational/6e50ca4065ed4ebeb0edadc7bbe5ea3c.js');
          break;
        case 'matwprojectmys.org':
          customUseScriptOnHead('https://cdn.pagesense.io/js/matwinternational/58682877a41c4e5cb32ce21666906ae4.js');
          break;
        case 'matwprojectsgp.org':
          customUseScriptOnHead('https://cdn.pagesense.io/js/matwinternational/83f6abdb055f4ca48edf8693ee0ddad0.js');
          break;
        default:
          break;
      }
    }
  }, []);
  /* --------------- ----------------------------------------------------------- */
  return checkoutData ? (
    <div className="lg:container mx-auto lg:max-w-8xl bg-white">
      <div className="h-auto">
        <img src={headerImg} alt="MATW" />
      </div>
      {/* {console.log("checkout=",checkoutData)} */}
      <div className="text-white py-3 px-2 pr-2 pl-2 bg-[#f60362] grid grid-cols-10 gap-0 md:gap-4">
        <div className="flex items-center lg:flex-col col-span-10 lg:col-span-2 text-sm sm:text-md md:text-base gap-1">
          <span className="min-w-[112px]">Donor:</span>
          <span className="text-stone-200">{checkoutData.name}</span>
        </div>
        <div className="col-span-10 lg:col-span-8 flex">
          <div className="grid grid-cols-12 w-full">
            <div className="flex items-center col-span-12 sm:col-span-3 text-sm sm:text-md md:text-base gap-1">
              <span className="min-w-[112px]">Donor ID:</span>
              <span className="text-stone-200 text-sm md:text-base">{checkoutData.donorID}</span>
            </div>
            <div className="flex items-center col-span-12 md:col-span-4 text-sm sm:text-md md:text-base gap-1">
              {GATEWAY !== 'crypto' && (
                <>
                  <span className="min-w-[112px]">Transaction ID:</span>
                  <span className="text-stone-200 text-sm md:text-base">{checkoutData.donationID}</span>
                </>
              )}
            </div>
            <div className="flex items-center col-span-12 md:col-span-3 text-sm sm:text-md md:text-base gap-1">
              <span className="min-w-[112px]">Payment Method:</span>
              <span className="text-stone-200 text-sm md:text-base">{checkoutData.paymentMethod}</span>
            </div>
            <div className="flex items-center col-span-12 sm:col-span-4 lg:col-span-6 text-sm sm:text-md md:text-base gap-1">
              <span className="min-w-[112px]">Email:</span>
              <span className="text-stone-200 text-sm md:text-base">{checkoutData.email}</span>
            </div>
            {/* <div className="flex items-center col-span-12 sm:col-span-6 lg:col-span-6 text-sm sm:text-md md:text-base gap-1">
              <span className="">On Behalf of:</span>
              <span className="text-stone-200 text-sm md:text-base">{checkoutData.onBehalf}</span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="px-4 md:mt-8 lg:px-12 mt-4">
        <div className="border-x-0 border-b-2 border-black my-2 text-center hidden md:grid py-2 font-bold  md:grid-cols-9 grid-gap-4">
          <div className="text-left hidden">DONATION TYPE</div>
          <div className="col-span-2 uppercase">Project</div>
          <div className="col-span-1 uppercase">mode</div>
          <div className="col-span-1 uppercase">frequency</div>
          <div className="col-span-2 uppercase">On behalf of</div>
          <div className="col-span-1">QTY</div>
          <div className="col-span-1 text-right">ITEM AMOUNT</div>
          <div className="col-span-1 text-right">TOTAL AMOUNT</div>
        </div>
        <div className="my-3 text-center md:grid py-2 md:grid-cols-8 grid-gap-4 border-b border-stone-400 mb-5">
          {checkoutData.donationTable?.map((i, index) => {
            if (i.QTY && i.amount) {
              return (
                <div
                  className={`w-full col-span-8 md:grid md:grid-cols-9 grid-gap-4 ${
                    index === checkoutData.donationTable.length - 1 ? '' : 'border-b border-stone-200'
                  }`}
                  key={`checkout${index}`}
                >
                  {/* <div className="flex hidden text-left md:justify-start items-center">
                <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">Donation Type:</div>
                {i.type}
              </div> */}
                  <div className="col-span-2 flex text-left md:justify-center items-center">
                    <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">Project:</div>
                    <div className="md:text-center">{i.project}</div>
                  </div>
                  <div className="col-span-1 flex text-left md:justify-center items-center">
                    <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">Mode:</div>
                    <div className="whiteTitle">{handleFirstCharCapital(i.mode)}</div>
                  </div>
                  <div className="col-span-1 flex text-left md:justify-center items-center">
                    <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">Frequency:</div>
                    <div className="whiteTitle">{handleRecurring(i.frequency.toLowerCase())}</div>
                  </div>
                  <div className="col-span-2 flex text-left md:justify-center items-center">
                    <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">On Behalf Of:</div>
                    <div className="md:text-center">{i.plaque_name}</div>
                  </div>
                  <div className="col-span-1 flex text-left md:justify-center items-center">
                    <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">QTY:</div>
                    {i.QTY}
                  </div>
                  <div className="col-span-1 price flex text-left md:justify-end items-center text-[#f60362]">
                    <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">Item Amount:</div>
                    {ShowCurrencies(i.currency, i.amount)}
                  </div>
                  <div className="col-span-1 price flex text-left md:justify-end items-center text-[#f60362]">
                    <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">Total Amount:</div>
                    {ShowCurrencies(i.currency, i.amount * i.QTY)}
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
        <div className="grid py-2 grid-cols-12 gap-2">
          <div className="font-bold col-span-12 md:col-span-6 text-left">
            {GATEWAY === 'crypto' ? (
              <p className="">
                Total Donation Commitment:{' '}
                <span className="md:hidden text-[#f60362] ml-1">{ShowCurrencies(checkoutData.currency, checkoutData.totalPrice)}</span>
              </p>
            ) : (
              <p className="">
                Total Initial Donations:{' '}
                <span className="md:hidden text-[#f60362] ml-1">{ShowCurrencies(checkoutData.currency, checkoutData.totalPrice)}</span>
              </p>
            )}
            {GATEWAY !== 'crypto' && <p className="text-md md:text-md">Date of Issue: {Moment(checkoutData.donationDate).format('LL')}</p>}
          </div>
          <div className="font-bold text-lg col-span-12 md:col-span-6 text-center md:text-right text-[#f60362] uppercase hidden md:flex md:justify-end">
            {ShowCurrencies(checkoutData.currency, checkoutData.totalPrice)}
          </div>
        </div>
        <div className="grid py-2 grid-cols-12 gap-2 mt-2"></div>
        {GATEWAY === 'crypto' && (
          <>
            <div className="border-x-0 border-b-2 border-black my-2 text-center hidden md:grid py-2 font-bold md:grid-cols-12 grid-gap-4">
              <div className="col-span-3 uppercase">Transaction ID</div>
              <div className="col-span-2 uppercase">Crypto Currency</div>
              <div className="col-span-2 uppercase">Crypto Amount</div>
              <div className="col-span-1 uppercase">Type</div>
              <div className="col-span-2 uppercase">Date</div>
              <div className="col-span-2 uppercase text-right">AMOUNT Paid</div>
            </div>
            <div className="my-3 text-center md:grid py-2 md:grid-cols-8 grid-gap-4 border-b border-stone-400 mb-5">
              {checkoutData.cryptoTable?.map((i, index) => {
                return (
                  <div
                    className={`w-full col-span-8 md:grid md:grid-cols-12 grid-gap-4 ${
                      index === checkoutData.cryptoTable.length - 1 ? '' : 'border-b border-stone-200'
                    }`}
                    key={`checkout${index}`}
                  >
                    <div className="col-span-3 flex text-left md:justify-center items-center">
                      <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">Transaction ID:</div>
                      <div className="md:text-center overflow-hidden- flex">
                        <span className="overflow-hidden pr-4 break-all">{i.transaction_id}</span>
                        {/* {i.transaction_id.length > 30 && <span className="ml-1">...</span>} */}
                        {/* <div className="flex justify-center items-center w-6 h-6 rounded bg-slate-100 ml-1 cursor-pointer text-slate-500" onClick={()=> copyTransactionID(i.transaction_id)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.3438 16.875H6.46875C5.79742 16.875 5.15359 16.6083 4.67889 16.1336C4.20418 15.6589 3.9375 15.0151 3.9375 14.3438V6.46875C3.9375 5.79742 4.20418 5.15359 4.67889 4.67889C5.15359 4.20418 5.79742 3.9375 6.46875 3.9375H14.3438C15.0151 3.9375 15.6589 4.20418 16.1336 4.67889C16.6083 5.15359 16.875 5.79742 16.875 6.46875V14.3438C16.875 15.0151 16.6083 15.6589 16.1336 16.1336C15.6589 16.6083 15.0151 16.875 14.3438 16.875Z" fill="currentColor" />
                          <path d="M5.625 2.8125H13.9177C13.7426 2.31934 13.4193 1.89242 12.9921 1.59029C12.5648 1.28816 12.0545 1.12563 11.5312 1.125H3.65625C2.98492 1.125 2.34109 1.39168 1.86639 1.86639C1.39168 2.34109 1.125 2.98492 1.125 3.65625V11.5312C1.12563 12.0545 1.28816 12.5648 1.59029 12.9921C1.89242 13.4193 2.31934 13.7426 2.8125 13.9177V5.625C2.8125 4.87908 3.10882 4.16371 3.63626 3.63626C4.16371 3.10882 4.87908 2.8125 5.625 2.8125Z" fill="currentColor" />
                        </svg>
                      </div> */}
                      </div>
                    </div>
                    <div className="col-span-2 flex text-left md:justify-center items-center">
                      <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">Crypto Currency:</div>
                      <div className="whiteTitle">{i.crypto_currency}</div>
                    </div>
                    <div className="col-span-2 flex text-left md:justify-center items-center">
                      <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">Crypto Amount:</div>
                      <div className="whiteTitle">
                        {i.total_crypto_amount} {i.crypto_currency}
                      </div>
                    </div>
                    <div className="col-span-1 flex text-left md:justify-center items-center">
                      <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">Type:</div>
                      <div className="whiteTitle">{i.type}</div>
                    </div>
                    <div className="col-span-2 flex text-left md:justify-center items-center">
                      <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">Date:</div>
                      {Moment(i.date).format('LL')}
                    </div>
                    <div className="col-span-2 price flex text-left md:justify-end items-center text-[#f60362]">
                      <div className="md:hidden text-stone-400 text-sm mr-2 min-w-[110px]">Paid Amount:</div>
                      {/* {ShowCurrencies(i.currency, i.crypto_currency_total_amount_paid)} */}
                      {ShowCurrencies(checkoutData.currency, i.crypto_currency_total_amount_paid)}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="grid py-2 grid-cols-12 gap-2">
              <div className="font-bold col-span-12 md:col-span-6 text-left">
                <p className="">
                  Total Paid Donations:{' '}
                  <span className="md:hidden text-[#f60362] ml-1">
                    {ShowCurrencies(checkoutData.currency, Number(checkoutData.crypto_total_paid))}
                  </span>
                </p>
                <p className="text-md md:text-md">Date of Issue: {Moment(checkoutData.donationDate).format('LL')}</p>
              </div>
              <div className="font-bold text-lg col-span-12 md:col-span-6 text-center md:text-right text-[#f60362] uppercase hidden md:flex md:flex-col md:justify-start">
                {/* <p>{ShowCurrencies(checkoutData.currency, checkoutData.totalPrice)}</p> */}
                <p>{ShowCurrencies(checkoutData.currency, Number(checkoutData.crypto_total_paid))}</p>
              </div>
            </div>
          </>
        )}
        <div className="grid py-4 px-8 pr-2 pl-2 grid-cols-4 gap-4 items-center lg:items-end">
          <div className="col-span-4 md:col-span-2 lg:col-span-1 flex flex-col justify-center items-center">
            <img className="w-[50%] sm:w-[35%] md:w-[150px] mx-auto md:mx-0" src={thanksImg} alt="" />
            <span className="font-bold text-[#777] text-xl tracking-[2px]">FOR YOUR DONATIONS</span>
            {!isUk && (
              <>
                <p className="text-[#777] text-center text-md">Donations above $2 are tax deductible in Australia*</p>
                <p className="text-center block text-[#777] md:hidden">
                  *Excluding Build a Masjid and Dawah projects those are not eligible for tax-deductibility
                </p>
              </>
            )}
          </div>
          <div className="col-span-4 md:col-span-1 md:col-start-4">
            {isUk && (
              <>
                <p className="font-bold py-2 text-[#777] text-center md:text-left tracking-[2px]">
                  MATW <br />
                </p>
                <p className="text-center md:text-left text-[#777]">
                  Head office: <br />
                  275 Featherstall <br />
                  Road North <br />
                  OLDHAM OL1 2NJ <br />
                  UK
                </p>
              </>
            )}
            {isUsa && (
              <>
                <p className="font-bold py-2 text-[#777] text-center md:text-left tracking-[2px]">
                  MATW Project USA <br />
                </p>
                <p className="text-center md:text-left text-[#777]">
                  Head office: <br />
                  2461 Eisenhower Ave. 2nd Floor <br />
                  Alexandria VA 22314 <br />
                  IRS ruling year: 2023 <br />
                  EIN: 92-1915834
                </p>
              </>
            )}
            {!isUk && !isUsa && (
              <>
                <p className="font-bold py-2 text-[#777] text-center md:text-left tracking-[2px]">
                  MATW INTERNATIONAL LTD <br />
                  ABN: 60 610 666 325
                </p>
                <p className="text-center md:text-left text-[#777]">
                  Head office: <br />
                  Australia HQ <br />
                  L2, 47 Rickard Rd <br />
                  Bankstown 2200 <br />
                  Australia
                </p>
              </>
            )}
          </div>
          <div className="col-span-4 mt-2">
            <p className="col-span-5 text-stone-500 mb-4 text-center">
              Check out our most recent
              <Link to={`../pdf/Ramadan Impact Report - Week 3_compressed.pdf`} target="_blank" className="text-blue-500 ml-1">
                project impact report.
              </Link>
            </p>
            <p className="col-span-5 text-stone-500 mb-4 text-center">
              {/* To check or cancel any subscriptions please visit <a href="https://matwproject.org/cancel" className='text-blue-500'>matwproject.org/cancel</a>. */}
              To check or cancel any subscriptions{' '}
              <a href={`${window.location.protocol}//${window.location.host}/cancel`} className="text-blue-500">
                please visit this page
              </a>
              .
            </p>
            <p className="text-center hidden md:block text-[#777]">
              *Excluding Build a Masjid and Dawah projects those are not eligible for tax-deductibility
            </p>
          </div>
        </div>
      </div>
      <div className="">
        <img src={footerImg} alt="MATW" className="w-full" />
      </div>
    </div>
  ) : (
    <div role="status" className="w-full flex justify-center items-center min-h-[500px]">
      <svg
        aria-hidden="true"
        className="w-20 h-20 mr-2 text-gray-200 animate-spin fill-[#00a3da]"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default ReceiptPage;
