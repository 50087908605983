import { Builder } from '@builder.io/react';
import React from 'react';
import { toast } from 'react-toastify';
import { useCountdown } from "../../../../../hooks/useCountDown";
const CountdownTimer = ({
  targetDate = new Date('2025-03-02T00:00')
}) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  if (days + hours + minutes + seconds <= 0) {
    return toast.error('Expired!!!');
  } else {
    return <div className="flex w-full">
                <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
                {/* <p className='text-2xl font-light mt-1'>:</p> */}
                <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
                {/* <p className='text-2xl font-light mt-1'>:</p> */}
                <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
                {/* <p>:</p> */}
                <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
            </div>;
  }
};
const DateTimeDisplay = ({
  value,
  type,
  isDanger
}) => {
  return <div className={`${isDanger ? 'countdown danger' : 'countdown'} w-full font-light text-center`}>
            <p className='text-4xl mb-0 font-brandingBold'>{value}</p>
            <span className='text-lg font-light -mt-2'>{type}</span>
        </div>;
};
Builder.registerComponent(CountdownTimer, {
  name: 'CountdownTimer',
  inputs: [
  {
    name: 'targetDate',
    type: 'date',
    defaultValue: '2025-03-02T00:00',
    friendlyName: 'Target Date',
  },
]
});
export default CountdownTimer;