// components/DateSelector.jsx
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import { FiCalendar } from 'react-icons/fi';
import { DateTime } from 'luxon';

const DateSelector = ({ startDate, setStartDate, timeZone, ramadanStartDate }) => {
  // Convert ramadanStartDate to Luxon DateTime in Asia/Tehran timezone
  const ramadanStart = DateTime.fromISO(ramadanStartDate, { zone: 'Asia/Tehran' });
  const ramadanEnd = ramadanStart.plus({ days: 29 }); // 30 nights total

  // Convert Ramadan start and end dates to user's selected timezone
  const ramadanStartInUserTZ = ramadanStart.setZone(timeZone);
  const ramadanEndInUserTZ = ramadanEnd.setZone(timeZone);

  // Get today's date in user's timezone, start of the day
  const todayLuxon = DateTime.now().setZone(timeZone).startOf('day');

  // Determine the minimum start date based on current date
  let calculatedMinDate;
  if (todayLuxon < ramadanStartInUserTZ) {
    // Before Ramadan: Min date is the first day of Ramadan
    calculatedMinDate = ramadanStartInUserTZ.toJSDate();
  } else if (todayLuxon >= ramadanStartInUserTZ && todayLuxon <= ramadanEndInUserTZ) {
    // During Ramadan: Min date is today
    calculatedMinDate = todayLuxon.toJSDate();
  } else {
    // After Ramadan: Disable date selection by setting minDate to ramadanEnd + 1 day
    // Alternatively, you can handle this as per your application's requirements
    calculatedMinDate = null;
  }
  // calculatedMinDate = ramadanStartInUserTZ.toJSDate();
  // Set maxDate as the last day of Ramadan
  const calculatedMaxDate = ramadanEndInUserTZ.toJSDate();

  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="max-w-[49%] md:min-w-[300px]">
      <label className='text-stone-500 flex items-center'><span className='text-red-600 mr-1'>*</span>Start Date</label>
      <div
        onClick={() => setIsOpen(true)}
        className={`cursor-pointer flex h-12 relative rounded-md items-center justify-center border border-[#282828]`}
      >
        {!startDate && (
          <div className={`absolute z-[10] flex items-center justify-between w-full px-4`}>
            <div className="text-[#1E3271]">Select Start Date</div>
            <div className="text-[#1E3271]">
              <FiCalendar size={20} />
            </div>
          </div>
        )}
        <div ref={datePickerRef} className="w-full h-full">
          <DatePicker
            onChange={(date) => {
              if (date) {
                // Ensure the selected date is within Ramadan
                const selectedDate = DateTime.fromJSDate(date, { zone: timeZone });
                if (selectedDate < ramadanStartInUserTZ) {
                  // Prevent selecting a date before Ramadan starts
                  setStartDate(ramadanStartInUserTZ.toISODate());
                } else if (selectedDate > ramadanEndInUserTZ) {
                  // Prevent selecting a date after Ramadan ends
                  setStartDate(ramadanEndInUserTZ.toISODate());
                } else {
                  setStartDate(selectedDate.toISODate());
                }
              } else {
                // Clear the selection
                setStartDate(null);
              }
            }}
            isClearable={true}
            selected={startDate ? new Date(startDate) : null}
            className="red-border w-full px-2 h-full rounded-md"
            minDate={calculatedMinDate}
            maxDate={calculatedMaxDate}
            open={isOpen}
            onCalendarClose={() => setIsOpen(false)}
            dateFormat="yyyy-MM-dd"
          // placeholderText="Select a date"
          />
        </div>
      </div>
    </div>
  );
};

DateSelector.propTypes = {
  startDate: PropTypes.string,
  setStartDate: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
  ramadanStartDate: PropTypes.string.isRequired, // New prop
};

export default DateSelector;
