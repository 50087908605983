import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useKeenSlider } from 'keen-slider/react';
import AppealFundraiserProductBox from './appealFundraiserProductBox';
import { selectSelectedCurrency } from '../../../store/user';
import ProductBox from '../../../components/general/productBox';
import AppealFundraiserProductBoxV3 from '../cartComponents/appealFundraiserProductBoxV3';
import { ProductBoxCart } from '../../home/components/productBoxCart';
import useCart from '../../../hooks/cart/useCart';
import AppealFundraiserProductBoxCartV3 from '../cartComponents/appealFundraiserProductBoxCartV3';

const AppealWidgetNew = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { className, usedOnTab, title = 'MATW Appeals', isHomePage = false, allProducts } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();

  const {
        handleAddToGiving,
        handleRemoveProduct,
        handleCheckProduct,
      } = useCart()
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */
  return (
    <section
      className={`${className ? className : 'bg-LighterBlue'} w-full ${usedOnTab ? 'py-2 md:py-4' : 'py-4 md:py-12'}`}
    >
      <div className="md:container w-full mx-auto">
        {!isHomePage && (
          <div className="w-full justify-center items-center flex pb-4 md:pb-8">
            <h2 className={`text-DarkerBlue font-brandingBold ${usedOnTab ? 'text-lg md:text-3xl' : 'text-2xl md:text-4xl'}`}>{title}</h2>
          </div>
        )}
        <div className="flex flex-wrap gap-3 sm:gap-4 justify-center items-start w-full">
          {allProducts.map((item, index) => {
            return (
              <div
                key={`active_carousel_${index}`}
                className={`w-full sm:max-w-[300px] sm:basis-[calc(50%-8px)]`}
              >
                {isHomePage ? (
                  !item.campaign ? (
                    <>
                    <div className="w-full hidden sm:block">
                        <ProductBox
                          product={item}
                          currency={selectedCurrency ? selectedCurrency : 'AUD'}
                          btnContainerClass={'h-[106px]'}
                          titleClasses={`uppercase px-1 !text-lg !h-auto !mt-3 min-h-[56px]`}
                          childClasses="!rounded-none !p-0 !pb-3 md:!pb-5 md:!p-0"
                          imageContainerClass="!rounded-none"
                          imageClass="!rounded-none"
                          descriptionStyle="px-2 md:px-3"
                          skeletonStyle="px-2"
                          squareButton
                          addToCart={true}
                        />
                    </div>
                    <div className="w-full sm:hidden">
                        <ProductBoxCart
                          key={index}
                          product={item}
                          currency={selectedCurrency ? selectedCurrency : 'AUD'}
                          onCheck={handleCheckProduct}
                          onAdd={handleAddToGiving}
                          onRemove={handleRemoveProduct}
                          stateVariableObj={{ productName: item.name }}
                          linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                          isStaticProduct={item.isStaticProduct}
                          staticLink={item.staticLink}
                          staticLinkText={item.staticLinkText}
                        />
                    </div>
                    </>
                  ) : (
                    <div className="">
                        <div className="hidden sm:block">
                          <AppealFundraiserProductBoxV3
                            campaign={item.campaign || 'palestine'}
                            goal={item.goal}
                            totalRaised={item.totalRaised}
                            subTitle="Orphan Education"
                            product={item}
                            isHomePage={isHomePage}
                            currency={selectedCurrency ? selectedCurrency : 'AUD'}
                          />
                        </div>
                        <div className="sm:hidden">
                          <AppealFundraiserProductBoxCartV3
                            campaign={item.campaign || 'palestine'}
                            goal={item.goal}
                            totalRaised={item.totalRaised}
                            subTitle="Orphan Education"
                            product={item}
                            isHomePage={isHomePage}
                            currency={selectedCurrency ? selectedCurrency : 'AUD'}
                          />
                        </div>
                    </div>
                  )
                ) : (
                  <AppealFundraiserProductBox
                    campaign={item.campaign || 'palestine'}
                    goal={item.goal}
                    totalRaised={item.totalRaised}
                    subTitle="Orphan Education"
                    product={item}
                    isHomePage={isHomePage}
                    currency={selectedCurrency ? selectedCurrency : 'AUD'}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default AppealWidgetNew;
