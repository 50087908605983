import { useEffect, useState } from 'react';
import ProductBox from '../../components/general/productBox';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { useKeenSlider } from 'keen-slider/react'
import "keen-slider/keen-slider.min.css";
import { useNavigate } from 'react-router-dom';
import ConvertUSD from '../../utils/convertUSD';
import ShowCurrencies from '../../utils/showCurrencies';
import MasjidFundation from '../../images/landings/love-ones/masjid-fundation.jpg';
import MatwLogo from '../../images/landings/love-ones/MATW-logo-2-1.png';
import { selectProducts } from '../../store/products';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
import { checkDomain } from '../../utils/checkDomain';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import ProductsTabVertical from '../../components/general/productsTabVertical';

const LovedOnesLegacyLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [waterWellsProducts, setWaterWellsProducts] = useState([0, 0, 0]);
  const [masjidProducts, setMasjidProducts] = useState([0, 0, 0, 0]);
  const [orphanProducts, setOrphanProducts] = useState([0, 0, 0, 0]);
  const [specialProducts, setSpecialProducts] = useState([0, 0]);
  const [educationProducts, setEducationProducts] = useState([0, 0, 0]);
  const [masjidProductDonation, setMasjidProductDonation] = useState([0]);
  // const [allProducts, setAllProducts] = useState();
  const [loaded, setLoaded] = useState(false);
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentSlideStory, setCurrentSlideStory] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [sliderRefStory, instanceRefStory] = useKeenSlider({
    mode: 'free-snap',
    slideChanged(slider) {
      setCurrentSlideStory(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 8,
    },
  });
  
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempWaterWells = [];
    let tempMasjid = [];
    let tempOrphan = [];
    let tempSpecial = [];
    let tempEducation = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item.name === 'Build A Water Well') {
            if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') tempWaterWells.push(inner_item_product); // Africa Basic Water Well
            if (inner_item_product.creator === 'WWC-CON-L1W-2023-01-026') tempWaterWells.push(inner_item_product); // L1 Africa Water Well
            if (inner_item_product.creator === 'WWC-CON-L2W-2023-01-027') tempWaterWells.push(inner_item_product); // L2 Africa Water Well
          }
          if (inner_item.name === 'Build A Masjid') {
            if (inner_item_product.creator === 'MSJ-CON-IMJ-2023-01-014') tempMasjid.push(inner_item_product); // Indonesia Masjid
            // if (inner_item_product.creator === 'MSJ-CON-YMJ-2023-01-015') tempMasjid.push(inner_item_product); // Yemen Masjid
            if (inner_item_product.creator === 'MSJ-WA-SMJ-2023-01') tempMasjid.push(inner_item_product); // Africa Small Masjid
            if (inner_item_product.creator === 'MSJ-WA-LRG-2023-02') tempMasjid.push(inner_item_product); // Africa Large Masjid
            // if (inner_item_product.creator === 'MSJ-CON-PKS-2023-01-016') tempMasjid.push(inner_item_product); // Pakistan Small Masjid
            if (inner_item_product.creator === 'GD-MP015') {
              // General Masjid Donations
              inner_item_product = Object.assign({}, inner_item_product, {
                goal: 29400,
                raised: 20500,
              });
              setMasjidProductDonation([inner_item_product]);
            }
          }
          if (inner_item.name === 'Support An Orphan') {
            if (inner_item_product.creator === 'ORP-DIS-SOQ-2023-01-040') tempOrphan.push(inner_item_product); // Support An Orphan For Three Months
            if (inner_item_product.creator === 'ORP-DIS-OMT-2023-01-042') tempOrphan.push(inner_item_product); // Orphanage Maintenance
            if (inner_item_product.creator === 'ORP-CON-ORH-2023-01-043') tempOrphan.push(inner_item_product); // Orphanage House
            if (inner_item_product.creator === 'OPR-GEN-SUP-2023-01-01') tempOrphan.push(inner_item_product); // Orphan Support
          }
          if (inner_item.name === 'Health') {
            if (inner_item_product.creator === 'HLT-DIS-ERI-2023-01-062') tempSpecial.push(inner_item_product); // Eye Restoration For 1 Person
          }
          if (inner_item.name === 'Education') {
            if (inner_item_product.creator === 'EDU-GEN-TSL-2023-01-059') tempEducation.push(inner_item_product); // Teacher Salary
            if (inner_item_product.creator === 'EDU-GEN-EDS-2023-01-060') tempEducation.push(inner_item_product); // Education Scholarship
            if (inner_item_product.creator === 'EDU-GEN-SCC-2023-01-128') tempEducation.push(inner_item_product); // Support Classroom Construction
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    setWaterWellsProducts(tempWaterWells);
    setMasjidProducts(tempMasjid);
    setOrphanProducts(tempOrphan);
    setSpecialProducts(tempSpecial);
    setEducationProducts(tempEducation);
  };
  const handlePercentage = (raised, goal) => {
    return `${((Number(raised) / Number(goal)) * 100).toFixed(2)}%`;
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const allProductsTab = [
    {
      title: 'WATER WELLS',
      products: waterWellsProducts,
    },
    {
      title: 'Masjid Construction',
      products: masjidProducts,
      shouldRenderProducts:!checkDomain('matwprojectfr.org')
    },
    {
      title: 'Orphan Care',
      products: orphanProducts,
    },
    // {
    //   title: 'Special',
    //   products: specialProducts,
    // },
    // {
    //   title: 'Education',
    //   products: educationProducts,
    // },
  ];
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout className="!bg-white" hideFooter={true}>
      {seo && (
        <Helmet>
          <title>{`${seo['crisisAndEmergencies']['palestine']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['crisisAndEmergencies']['palestine']['description']}`} />
        </Helmet>
      )}
      {/* /* --------------------------------- Banner --------------------------------- */}
      <div className="w-full flex flex-wrap items-start justify-center pt-4 sm:pt-0 bg-[url('../public/images/landings/love-ones/headbanner-mobile.jpg')] sm:bg-[url('../public/images/landings/love-ones/headbanner.jpg')] bg-top sm:bg-center bg-cover md:py-8 max-[500px]:min-h-[350px] min-[501px]:min-h-[500px] sm:min-h-[380px] md:min-h-[500px] lg:min-h-[550px] xl:min-h-[700px] 2xl:min-h-[840px] min-[1640px]:min-h-[900px] min-[1880px]:min-h-[1024px] px-4 md:px-0">
        <h3 className="text-white text-center sm:hidden text-xl">
          Losing a loved one
          <br />
          is never easy
        </h3>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* ------------------------------- Story ------------------------------------ */}
      <div className="w-full bg-[url('../public/images/landings/love-ones/bg-wife-quote.jpg')] bg-center sm:bg-center bg-cover py-4 md:py-6 flex flex-wrap justify-center items-center">
        <h1 className="text-5xl md:text-5xl basis-full text-[#F60362] font-['Gotcha'] h-[50px] xl:h-[100px] mt-4 text-center font-medium mb-4 xl:mb-16 lg:mb-18">
          Our Story
        </h1>
        <div className="basis-full hidden sm:flex flex-wrap items-center">
          <div className="basis-full flex flex-wrap justify-center md:basis-1/2 min-h-[200px] md:min-h-[240px] px-4 mb-4 md:mb-0">
            <div className="basis-full 2xl:basis-3/4 rounded-xl mt-6 bg-white p-2 lg:p-4 text-center shadow-lg">
              <div className="basis-full h-[200px] sm:h-[300px] md:h-[200px] lg:h-[300px]">
                <iframe
                  className="video"
                  width={'100%'}
                  height={'100%'}
                  title={'youtube'}
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://youtube.com/embed/dzL6BLPAFBo?autoplay=0`}
                ></iframe>
              </div>
            </div>
          </div>
          <div className="basis-full md:basis-1/2 lg:basis-1/3 px-8 md:px-0">
            <p className="text-[#14A2DC] text-xs sm:text-base md:text-lg">
              “His death was more comforting than his life in this world. I knew Allah (swt) had taken him on La Illaha Ilallah in sha Allah, but I
              could not help but have this overwhelming feeling of what next? What can I do for my dear husband now that I cannot hear him tell me
              what comforts him? This was the hardest part because I was not done loving and caring for him.”
              <br />
              <br />– Ali Banat’s wife
            </p>
          </div>
        </div>
        <div className="basis-full lg:container lg:mx-auto hidden sm:flex flex-wrap justify-center items-center">
          <div className="basis-full md:basis-1/3 xl:basis-1/4 order-2 md:order-1 flex justify-center md:justify-start flex-wrap px-8 md:px-0">
            <h2 className="text-[#253B7E] basis-full text-center md:text-left text-2xl md:text-2xl">Dealing with grief & worry</h2>
            <div className="basis-1/3 sm:basis-1/4 md:basis-1/6 h-1 md:h-2 bg-[#F60362] mt-1 mb-4"></div>
            <p className="text-[#78716C] text-xs sm:text-sm md:text-base basis-full text-center md:text-left">
              After the loss of someone we love, we are often left with unanswered questions, usually “What next?”
              {/* <br /> */}
              <br />
              One of the best ways to way to help overcome this is to dedicate a charitable project in their loving memory.
            </p>
          </div>
          <div className="basis-full md:basis-1/3 xl:basis-1/4 order-1 md:order-2 flex flex-wrap h-[300px] bg-[url('../public/images/landings/love-ones/imageholder-card.png')] bg-center md:bg-left bg-contain bg-no-repeat" />
        </div>
        <div className="w-full sm:hidden flex-wrap justify-center items-center relative">
          <div ref={sliderRefStory} className="keen-slider">
            <div className="flex flex-col basis-full keen-slider__slide">
              <div className="basis-full flex flex-wrap justify-center px-4 mb-4">
                <div className="basis-full rounded-xl mt-6 bg-white p-2 text-center shadow-lg">
                  <div className="basis-full h-[200px] sm:h-[300px]">
                    <iframe
                      className="video"
                      width={'100%'}
                      height={'100%'}
                      title={'youtube'}
                      sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                      src={`https://youtube.com/embed/dzL6BLPAFBo?autoplay=0`}
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="basis-full px-8 md:px-0">
                <p className="text-[#14A2DC] text-xs sm:text-base md:text-lg">
                  “His death was more comforting than his life in this world. I knew Allah (swt) had taken him on La Illaha Ilallah in sha Allah, but
                  I could not help but have this overwhelming feeling of what next? What can I do for my dear husband now that I cannot hear him tell
                  me what comforts him? This was the hardest part because I was not done loving and caring for him.”
                  <br />
                  <br />– Ali Banat’s wife
                </p>
              </div>
            </div>
            <div className="flex flex-col basis-full keen-slider__slide">
              <div className="basis-full flex flex-wrap h-[300px] bg-[url('../public/images/landings/love-ones/imageholder-card.png')] bg-center md:bg-left bg-contain bg-no-repeat" />
              <div className="basis-full flex justify-center md:justify-start flex-wrap px-8 md:px-0">
                <h2 className="text-[#253B7E] basis-full text-center md:text-left text-2xl md:text-2xl">Dealing with grief & worry</h2>
                <div className="basis-1/3 sm:basis-1/4 md:basis-1/6 h-1 md:h-2 bg-[#F60362] mt-1 mb-4"></div>
                <p className="text-[#78716C] text-xs sm:text-sm md:text-base basis-full text-center md:text-left">
                  After the loss of someone we love, we are often left with unanswered questions, usually “What next?”
                  <br />
                  One of the best ways to way to help overcome this is to dedicate a charitable project in their loving memory.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full sm:hidden flex justify-center">
          {loaded && instanceRefStory.current && (
            <div className="dots flex pt-3 pb-6">
              {[...Array(2).keys()].map(idx => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      instanceRefStory.current?.moveToIdx(idx);
                    }}
                    className={
                      'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                      (currentSlideStory === idx ? ' active bg-[#F60362]' : ' bg-gray-300')
                    }
                  ></button>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Legacy --------------------------------- */}
      <div className="md:container md:mx-auto flex justify-center flex-wrap mt-5 px-4 md:px-0">
        <div className="basis-full md:basis-2/3 text-center text-2xl md:text-3xl lg:text-6xl bg-gradient-to-r from-[#F60362] to-[#A00340] text-white flex justify-center items-center p-2 md:p-4 rounded-lg">
          How can you build a legacy for your loved one?
        </div>
        <p className="basis-full md:basis-2/3 text-center text-base md:text-lg text-[#78716C] my-2 md:my-4 hidden lg:block">
          Below are some <strong className="mx-1 text-[#F60362]">Sadaqah Jariyah</strong> Projects that bring comfort and ease the heart.
        </p>
        <div className="basis-full lg:container lg:mx-auto flex flex-wrap justify-center items-center mt-4 md:mt-8">
          <div className="basis-full lg:basis-1/2 flex flex-wrap pr-0 lg:pr-16">
            <p className="basis-full mb-4 lg:mb-8 text-xl md:text-2xl lg:text-3xl text-[#F60362] text-center lg:text-left">
              The Messenger of Allah (pbuh) said:
              <br />
              <br />
              <strong>
                "When a man dies, his deeds come to an end except for three things: Sadaqah Jariyah (ongoing charity); a knowledge which is
                beneficial, or a virtuous child who prays for him (for the deceased)."
              </strong>
            </p>
            <div className="basis-full min-h-[250px] lg:min-h-[330px] bg-[url('../public/images/landings/love-ones/imageholder-legacy.png')] bg-center lg:bg-right bg-contain bg-no-repeat" />
            <p className="basis-full text-center text-base md:text-lg text-[#78716C] my-2 lg:my-4 lg:hidden">
              Below are some <strong className="mx-1 text-[#F60362]">Sadaqah Jariyah</strong> Projects that bring comfort and ease the heart.
            </p>
          </div>
          <div className="basis-full lg:basis-1/2 flex flex-wrap">
            {!checkDomain('matwprojectfr.org') && (
              <div className="basis-full border-l-8 border-[#F60362] bg-gradient-to-r from-[#F5F5F5] to-[#fff] p-4 md:p-6 mb-6">
                <h3 className="text-[#093686] text-lg md:text-2xl mb-2 font-bold">Build a Masjid</h3>
                <p className="basis-full mb-4 md:mb-8 text-base md:text-lg text-[#282828]">
                  Prophet Muhammad (pbuh) said:
                  <strong className="text-[#00A3DA] mx-1">
                    “Whoever builds a mosque for Allah, Allah will build for him a house like it in Paradise.”
                  </strong>
                  (Bukhari and Muslim)
                </p>
              </div>
            )}
            <div className="basis-full border-r-8 border-[#F60362] bg-gradient-to-l from-[#F5F5F5] to-[#fff] p-4 md:p-6 mb-6">
              <h3 className="text-[#093686] text-lg md:text-2xl mb-2 font-bold">Housing and Support for Widows and Orphans</h3>
              <p className="basis-full mb-4 md:mb-8 text-base md:text-lg text-[#282828]">
                Abu Hurairah (ra): The Prophet (pbuh) said:
                <strong className="text-[#00A3DA] mx-1">
                  “One who strives to help the widows and the poor is like the one who fights in the way of Allah.”
                </strong>
                The narrator said: “I think that he (pbuh) added also:
                <strong className="text-[#00A3DA] mx-1">
                  “I shall regard him as the one who stands up (for prayer) without rest and as the one who observes fasts continuously”.
                </strong>
                (Bukhari and Muslim)
              </p>
            </div>
            <div className="basis-full border-l-8 border-[#F60362] bg-gradient-to-r from-[#F5F5F5] to-[#fff] p-4 md:p-6 mb-6">
              <h3 className="text-[#093686] text-lg md:text-2xl mb-2 font-bold">Build a Water Well</h3>
              <p className="basis-full mb-4 md:mb-8 text-base md:text-lg text-[#282828]">
                Sa’d ibn ‘Ubadah (ra) reported: I said,
                <strong className="text-[#00A3DA] mx-1">“O Messenger of Allah, my mother has died. Shall I give charity on her behalf?”</strong>
                The Prophet, peace and blessings be upon him, said,
                <strong className="text-[#00A3DA] mx-1">“Yes”</strong>I said,
                <strong className="text-[#00A3DA] mx-1">“Which charity is best?” The Prophet said, “A drink of water”</strong>
                (An-Nasa’i)
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Project header ------------------------- */}
      <div className="flex justify-center items-center flex-wrap mt-5 py-8 md:py-12 bg-[url('../public/images/landings/love-ones/headboard.jpg')] bg-center sm:bg-center bg-cover">
        <h2 className="text-white text-2xl md:text-4xl lg:text-5xl">Sadaqah Jariyah Projects</h2>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Reward --------------------------------- */}
      <div className="w-full mt-16">
        <div className="md:container md:mx-auto mt-5 flex flex-wrap justify-center text-white pb-8 md:pb-16">
          <h1 className="text-4xl md:text-5xl basis-full text-[#F60362] font-['Gotcha'] md:h-[100px] mt-4 text-center font-medium mb-4 lg:mb-8 mx-1">
            Ongoing reward in this life and the hereafter
          </h1>
          <div className="basis-full flex flex-wrap items-center justify-center">
            <div className="basis-1/2 order-2 md:order-1 lg:basis-1/3 h-[360px] bg-[url('../public/images/landings/love-ones/imageholder-reward.png')] bg-center bg-contain bg-no-repeat" />
            <div className="basis-1/2 order-1 md:order-2 lg:basis-1/3 px-2 pl-4 md:px-6 mt-2">
              <h3 className="text-[#093686] text-lg md:text-2xl mb-3 font-bold">Did you know?</h3>
              <p className="basis-full mb-4 md:mb-8 text-sm md:text-lg text-[#14A2DC]">
                Sadaqah Jariyah is not specifically for the deceased! It is also highly beneficial for the living to leave behind good deeds that will
                continue to be rewarded by Allah (swt) even after they have passed on.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Products ------------------------------- */}
      <ProductsTabVertical
        sections={allProductsTab}
      />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Loss banner ---------------------------- */}
      <div className="flex justify-center items-center flex-wrap p-4 md:p-8 bg-[url('../public/images/landings/love-ones/headboard-loss.jpg')] bg-center sm:bg-center bg-cover">
        <h2 className="text-white text-center text-3xl md:text-4xl lg:text-5xl basis-full md:basis-1/3">Some words on how to cope with loss</h2>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Disaster ------------------------------- */}
      <div className="md:container md:mx-auto flex justify-center flex-wrap mt-12 px-4 md:px-0">
        <div className="basis-full md:basis-5/12 flex flex-wrap pr-0 md:pr-16">
          <p className="basis-full mt-4 md:mt-8 mb-4 md:mb-8 text-xl md:text-2xl lg:text-3xl text-[#14A2DC] text-center md:text-left">
            Allah (swt) says:
            <br />
            <br />
            <strong>
              “Who, when disaster strikes them, say, “Indeed we belong to Allah, and indeed to Him we will return. Those are the ones upon whom are
              blessings from their Lord and mercy. And it is those who are the (rightly) guided.”
            </strong>
          </p>
        </div>
        <div className="basis-full md:basis-7/12 grid grid-cols-4">
          <div className="col-span-4 md:col-span-2 p-2 md:p-3">
            <div className="border border-[#093686] rounded-lg overflow-hidden">
              <h3 className="text-white text-center bg-[#093686] text-lg md:text-2xl py-3 mb-2 font-bold">Supplicate (dua)</h3>
              <p className="basis-full m-2 text-sm md:text-base text-[#78716C] text-center">
                Make dua for your loved one. Below is a comforting dua to recite:
              </p>
              <p className="basis-full m-2 text-base md:text-lg text-[#093686] text-center">
                ‘O Allah, (mentioned deceased by name) is under Your care and protection so protect him from the trial of the grave and torment of the
                Fire. Indeed, You are faithful and truthful. Forgive and have mercy upon him, surely You are The Oft-Forgiving, The Most-Merciful.’
              </p>
            </div>
          </div>
          <div className="col-span-4 md:col-span-2 p-2 md:p-3">
            <div className="border border-[#093686] rounded-lg overflow-hidden">
              <h3 className="text-white text-center bg-[#093686] text-lg md:text-2xl py-3 mb-2 font-bold">Patience (sabr)</h3>
              <p className="basis-full m-2 text-sm md:text-base text-[#78716C] text-center">
                Patience at the time of grief is a way to express one’s contentment with and reliance on the decree of Allah (swt). Find comfort
                through the sunnah of patience.
              </p>
              <p className="basis-full m-2 text-base md:text-lg text-[#093686] text-center">
                Our beloved Prophet Muhammad (SAW) said: “Whoever persists in being patient, Allah will make him patient. Nobody can be given a
                blessing better and greater than patience.” (Bukhari)
              </p>
            </div>
          </div>
          <div className="col-span-4 p-2 md:p-3">
            <div className="border border-[#093686] rounded-lg overflow-hidden">
              <h3 className="text-white text-center bg-[#093686] text-lg md:text-2xl py-3 mb-2 font-bold">Give in Charity</h3>
              <p className="basis-full m-4 text-base md:text-lg text-[#093686] text-center">
                The sunnah of giving in charity brings ease and benefits for those we have lost. MATW International, is a legacy that our dear brother
                Ali Banat (may Allah have mercy on him) left behind and we can only imagine the countless rewards and blessings he receives on a daily
                basis for every act of charity it facilitates, In sha Allah.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* ---------------------------------- Aisha --------------------------------- */}
      <div className="flex justify-center items-center flex-wrap mt-5 py-8 md:py-16 bg-[url('../public/images/landings/love-ones/aisha.jpg')] bg-center sm:bg-center bg-cover">
        <div className="md:container md:mx-auto flex justify-center flex-wrap px-4 md:px-0">
          <p className="basis-full text-2xl md:text-4xl text-center mb-12 text-white">
            Aisha (ra) narrated that a man came to the Prophet (pbuh) and said:
            <strong className="mx-1">
              “My mother died suddenly and did not leave a will, but I think that if she could have spoken she would have given in charity. Will she
              have a reward if I give in charity on her behalf?”
            </strong>
            <br />
            <br />
            The Prophet (pbuh) replied:
            <strong className="mx-1">“Yes.”</strong>
            <br />
            (Bukhari)
          </p>
          <div className="basis-full md:basis-5/12 pr-0 md:pr-8">
            <p className="text-white">
              <strong>A PERSONAL TOUCH</strong>
              <br />
              <br />
              In remembrance there is also comfort. MATW offers a special way to express that remembrance, through the personalizations of projects.
              We allocate your loved one’s name to projects so that daily a special dua is made by the beneficiaries of that project.
              <br />
              We go a little further and provide you with that special dua as a comfort and a keep sake. A digital completion portfolio for each
              construction project is also provided as a reminder of the ongoing benefits and rewards that result from it.
            </p>
          </div>
          <div className="basis-full md:basis-5/12 mt-8 md:mt-0 pl-0 md:pl-4">
            <p className="text-white">
              <strong>A HEALING REFLECTION</strong>
              <br />
              <br />
              Once we have taken the time to process the emotions of loss, it is important that we take a moment to reflect on the reality of this
              world.
              <br />
              Why are we here? What am I doing to please Allah (swt)? Through increased learning and reconnection with Allah (swt) after the loss of a
              loved one, we pray that the deceased too will be rewarded and showered with the blessings of our efforts, Insha-Allah.
            </p>
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Info ----------------------------------- */}
      <div className="md:container md:mx-auto flex justify-center flex-wrap mt-12 px-4 md:px-0">
        <div className="basis-full md:basis-1/2 flex flex-wrap pr-0 md:pr-2 mb-8 md:mb-0">
          <p className="basis-full mb-1 md:mb-3 font-bold text-2xl md:text-3xl lg:text-4xl text-[#F60362]">
            So how do I donate a Sadaqah Jariyah with MATW?
          </p>
          <p className="basis-full mb-3 md:mb-6 text-sm md:text-base text-[#78716C]">
            We know you are going through a difficult time, so we have made it easy for you (in{' '}
            <strong className="ml-1 text-[#F60362]">4 simple steps!</strong>)
          </p>
          <ul>
            <li className="flex items-center mb-2">
              <div className="text-lg md:text-2xl text-white bg-[#F60362] rounded-full w-12 min-w-[48px] h-12 flex justify-center items-center mr-3">
                1
              </div>
              <p className="font-bold text-lg md:text-xl text-[#093686]">Select the project of your choice</p>
            </li>
            <li className="flex items-center mb-2">
              <div className="text-lg md:text-2xl text-white bg-[#F60362] rounded-full w-12 min-w-[48px] h-12 flex justify-center items-center mr-3">
                2
              </div>
              <p className="font-bold text-lg md:text-xl text-[#093686]">
                <a href="/contact" className="text-[#093686] mx-1 underline" rel="noopener noreferrer">
                  Contact us
                </a>
              </p>
            </li>
            <li className="flex items-center mb-2">
              <div className="text-lg md:text-2xl text-white bg-[#F60362] rounded-full w-12 min-w-[48px] h-12 flex justify-center items-center mr-3">
                3
              </div>
              <p className="font-bold text-lg md:text-xl text-[#093686]">Donate your sadaqah jariyah</p>
            </li>
            <li className="flex items-center mb-2">
              <div className="text-lg md:text-2xl text-white bg-[#00A3DA] rounded-full w-12 min-w-[48px] h-12 flex justify-center items-center mr-3">
                4
              </div>
              <p className="font-bold text-lg md:text-xl text-[#00A3DA]">Done! (We take care of the rest)</p>
            </li>
          </ul>
        </div>
        <div className="basis-full md:basis-1/2 flex flex-wrap pl-0 md:pl-2">
          <p className="basis-full mb-1 md:mb-3 font-bold text-2xl md:text-3xl lg:text-4xl text-[#253A7E]">Leave a Legacy Campaign</p>
          <p className="basis-full mb-3 md:mb-6 text-sm md:text-base text-[#78716C]">
            <strong className="text-[#253A7E]">Honour a loved one through fundraising</strong>
            <br />
            We have taken all the hard work out of leaving your loved one a legacy. <strong className="ml-1 text-[#253A7E]">Bismillah....</strong>
          </p>
          <ul>
            <li className="flex items-center mb-2">
              <div className="text-lg md:text-2xl text-white bg-[#F60362] rounded-full w-12 min-w-[48px] h-12 flex justify-center items-center mr-3">
                1
              </div>
              <p className="font-bold text-lg md:text-xl text-[#093686]">Select the project of your choice</p>
            </li>
            <li className="flex items-center mb-2">
              <div className="text-lg md:text-2xl text-white bg-[#F60362] rounded-full w-12 min-w-[48px] h-12 flex justify-center items-center mr-3">
                2
              </div>
              <p className="font-bold text-lg md:text-xl text-[#093686]">Submit an application to fundraise</p>
            </li>
            <li className="flex items-center mb-2">
              <div className="text-lg md:text-2xl text-white bg-[#F60362] rounded-full w-12 min-w-[48px] h-12 flex justify-center items-center mr-3">
                3
              </div>
              <p className="font-bold text-lg md:text-xl text-[#093686]">
                Our Fundraising team will create your personal campaign and send the link to your email
              </p>
            </li>
            <li className="flex items-center mb-2">
              <div className="text-lg md:text-2xl text-white bg-[#00A3DA] rounded-full w-12 min-w-[48px] h-12 flex justify-center items-center mr-3">
                4
              </div>
              <p className="font-bold text-lg md:text-xl text-[#00A3DA]">Now reach your goal and we will do the rest!</p>
            </li>
          </ul>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Fundrais ------------------------------- */}
      {!checkDomain('matwprojectfr.org') && (
        <div className="md:container md:mx-auto flex justify-center flex-wrap mt-12 px-4 lg:px-0">
          <div className="basis-full max-w-[300px] min-[590px]:basis-5/12 md:basis-1/3 xl:basis-1/4 flex flex-wrap mb-8 md:mb-0 pl-0 lg:pl-3">
            <div className="w-full rounded-xl shadow-lg bg-white overflow-hidden">
              <div className="overflow-hidden rounded-t-xl w-full sm:h-[210px] md:w-[240px] md:h-[176px] lg:w-[329px] lg:h-[239px] xl:w-[410px] xl:h-[300px] 2xl:w-[384px] 2xl:h-[270px]">
                <img className="w-full" src={MasjidFundation} alt="MasjidFundation" />
              </div>
              <div className="p-2 md:p-3 md:pb-5">
                <div className="flex items-center">
                  <img src={MatwLogo} alt="matw" className="w-6 md:w-8 mr-2" />
                  <p className="text-sm">MATW Project</p>
                </div>
                <h3 className="text-[#252A34] text-sm sm:text-md md:text-md lg:text-lg xl:text-xl font-medium h-[34px] sm:h-[32px] lg:h-[48px]  my-2 leading-4 md:leading-none">
                  We're building a Masjid in honour of Yasar Bilal
                </h3>
                <div className="basis-full flex-col md:basis-1/2 flex justify-center py-2 md:py-3">
                  <div className="flex w-full h-2 bg-green-600 bg-opacity-20 mb-2">
                    <div
                      className={`bg-green-600`}
                      style={{
                        width: `${handlePercentage(
                          ConvertUSD(selectedCurrency, masjidProductDonation[0].raised, false),
                          ConvertUSD(selectedCurrency, masjidProductDonation[0].goal, false),
                        )}`,
                      }}
                    ></div>
                  </div>
                  <div className="flex flex-wrap w-full items-center">
                    <div className="basis-1/2 flex flex-col">
                      <h3 className="text-xl">
                        {ShowCurrencies(selectedCurrency, ConvertUSD(selectedCurrency, masjidProductDonation[0].raised, false))}
                      </h3>
                      <p className="text-sm">funded of</p>
                      <h3 className="text-sm">
                        {ShowCurrencies(selectedCurrency, ConvertUSD(selectedCurrency, masjidProductDonation[0].goal, false))}
                      </h3>
                    </div>
                    <div className="basis-1/2 flex flex-col">
                      <h3 className="text-lg md:text-2xl bg-green-600 text-white w-full rounded-full py-2 text-center">Success!</h3>
                    </div>
                  </div>
                </div>
                <div className="basis-full flex justify-center items-center"></div>
              </div>
            </div>
          </div>
          <div className="basis-full min-[590px]:basis-7/12 md:basis-2/3 xl:basis-1/2 flex flex-wrap flex-col- items-start- mb-8 md:mb-0 pl-4 md:pl-12">
            <p className="basis-full mb-2 md:mb-4 font-bold text-2xl md:text-3xl lg:text-4xl text-[#093686]">Why do we fundraise for a loved one?</p>
            <ul className="basis-full">
              <li className="flex items-center">
                <div className="text-lg md:text-2xl text-[#093686] w-10 min-w-[40px] md:w-12 flex justify-center items-center mr-1 md:mr-3">1.</div>
                <p className="text-lg md:text-xl text-[#78716C]">Honour and remember your loved one.</p>
              </li>
              <li className="flex items-center">
                <div className="text-lg md:text-2xl text-[#093686] w-10 min-w-[40px] md:w-12 flex justify-center items-center mr-1 md:mr-3">2.</div>
                <p className="text-lg md:text-xl text-[#78716C]">Keeps our loved ones legacy alive while improving the lives of others.</p>
              </li>
              <li className="flex items-center">
                <div className="text-lg md:text-2xl text-[#093686] w-10 min-w-[40px] md:w-12 flex justify-center items-center mr-1 md:mr-3">3.</div>
                <p className="text-lg md:text-xl text-[#78716C]">Bring family and friends together in a time of grief.</p>
              </li>
              <li className="flex items-center">
                <div className="text-lg md:text-2xl text-[#093686] w-10 min-w-[40px] md:w-12 flex justify-center items-center mr-1 md:mr-3">4.</div>
                <p className="text-lg md:text-xl text-[#78716C]">Reminds us to be patient and thankful in times of hardship.</p>
              </li>
            </ul>
            <button
              className="text-white flex items-center justify-center mt-4 text-lg md:text-2xl transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[64px] px-6 sm:px-8 py-4 rounded-md"
              onClick={() => navigate(generateLinkWithLang('/fundraising', lang))}
            >
              Register to fundraise
            </button>
          </div>
        </div>
      )}
      {/* /* -------------------------------------------------------------------------- */}
    </NewHelmetLayout>
  );
};

function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <div
      className={`bg-[#F60362] w-[30px] h-[60px] absolute top-1/2 translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${
        props.left ? 'arrow--left left-[0px]' : 'arrow--right left-auto right-[0px]'
      }`}
      onClick={props.onClick}
    >
      <svg
        className={`w-[24px] h-[24px] fill-white cursor-pointer arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
        {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
      </svg>
    </div>
  );
}

export default LovedOnesLegacyLandingPage;
