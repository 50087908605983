import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import Skeleton from 'react-loading-skeleton';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
// import useHandleAddProduct from '../../../utils/handleAddProduct';
// import YoutubeVideoHero from '../../../components/general/youtubeVideoHero';
import CustomButton from '../../../components/general/button';
// import ShowCurrencies from '../../../utils/showCurrencies';
// import ConvertAUD from '../../../utils/convertAUD';
import ConvertUSD from '../../../utils/convertUSD';
// import Sticky from 'react-sticky-el';
import moment from 'moment';
import { endDateOfAppealCampaign, endDateOfGazaCampCampaign, endDateOfLimbsAppealCampaign } from '../details';
import getSymbolFromCurrency from 'currency-symbol-map';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import SelectCurrency from '../../../components/general/selectCurrency';
import creditImg from '../../../images/payment/credit-cards.png';
// import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import handleDaysLeft from '../handleDaysLeft';

const AppealCurrentProductUAT = ({ lang = 'en', currentProduct, goal, campaign, selectedGivingProducts }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  // const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [addProduct] = useHandleAddProduct();
  // const location = useLocation();
  // const [isRefugeePage, setIsRefugeePage] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  // const [isTabsFixed, setIsTabsFixed] = useState(false);

  const moreInfo = [
    {
      title:
        'More than 39,000 Palestinians have lost their lives including nearly 15,000 children and more than 90,000 have been wounded since October 7, 2023.',
      description: (
        <div className="text-[16px] md:text-[18px]">
          Overwhelmingly, they are broken families with family members left behind or deceased. They have survived the unimaginable - conflict,
          bombing, displacement and hunger, having seen people they know or family members dying, only to escape to another country to receive no
          support. We cannot let that happen. MATW is providing support to refugee families who have fled in the form of housing, rental support,
          furnishing homes, food support, expense support and so much more. Help us support them NOW. Click to know more.
          <br />
          More than 1.9 million people are internally displaced having left everything they have ever known behind to flee south, where conditions are
          barely liveable. As famine and hunger spreads, so will waterborne diseases due to lack of access to safe and clean drinking water.
        </div>
      ),
      box_title: (
        <div className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl ">Time is running out for our brothers and sisters in Palestine.</div>
      ),
      box_description: (
        <div className="text-[16px] md:text-[18px]">
          Having delivered more than 100 trucks carrying essential relief items to Palestine, MATW has one of the largest relief operations on the
          ground. Our teams are working round the clock to reach as many people as possible but we can’t do it alone.
          <br />
          <p className="font-black text-[20px] mt-1"> Help us help them. Donate Now.</p>
        </div>
      ),
    },
    {
      title: 'Your support is needed now more than ever.',
      description: `You can start by using your Sadaqah for Palestine, which will help us provide urgent medical support, food, clean water, shelter, and family relocation assistance today. You can also donate your Zakat to Palestine, as our Palestine emergency response program is 100% Zakat-applicable.`,
      box_title: '',
      box_description: ``,
    },
    {
      title: 'Empower Lives with Prosthetic Support',
      description: (
        <p>
          Your generosity can transform a life. By donating to our Prosthetic Limb Fundraise, you’re helping to provide a prosthetic limb to someone
          in need. Every contribution, big or small, goes toward covering the costs of acquiring, customizing, and fitting a prosthetic limb.
          Together, we can offer the gift of mobility, independence, and a brighter future for individuals who deserve a chance to live fully again.
          Join us in making a profound impact today!
          <br />
          <br />
          Among them, thousands have become amputees - losing an arm, a leg or in some cases all their limbs. <i>Imagine</i> not being able to stand
          in Salat, or wrap your arms around your loved ones!
          <br />
          <br />
          The people of Palestine have already lost so much. They’ve been forced from their homes, lost their livelihoods, their belongings and their
          loved ones - don’t let them lose their dignity or hope.
          <br />
          <br />
          <strong>
            As the official named partner of the Jordan Hashemite Charity Organisation, Muslims Around The World Project (MATW Project), will be
            providing prosthetic limbs in Gaza to amputees through a new ground-breaking technology that can fit a limb within one hour!
          </strong>
          <br />
          <br />
          <strong>
            A Sadaqah Jariyah like this not only has the power to save lives but will also reap immense rewards for your Akhirah Insha’Allah!
          </strong>
          <br />
          <br />
          <strong>Help us provide Limbs of Hope for Gazans in need and restore dignity and hope. Donate Now.</strong>
        </p>
      ),
      box_title: '',
      box_description: ``,
    },
    {
      title: 'The Humanitarian Crisis',
      description: (
        <div>
          <p>
            More than 44,000 Palestinians have lost their lives, including over 16,000 children. Nearly 100,000 people have been wounded, with an
            estimated 10,000 buried under rubble. Despite these devastating challenges, MATW remains committed to providing critical relief:
          </p>
          <ul className="pl-4 lg:pl-8 mt-2">
            <li className="list-disc pl-2">Delivered over 148 trucks of essential relief items</li>
            <li className="list-disc pl-2">Providing daily hot meals</li>
            <li className="list-disc pl-2">Offering consistent food packages</li>
            <li className="list-disc pl-2">Delivering vital medical support</li>
          </ul>
          <h6 className="font-bold text-DarkerBlue mt-2">The camp isn’t home, Gaza is. In order to stay they need urgent shelter.</h6>
          <p>Our MATW team has established a large-scale relief camp at Yarmouk Stadium in Gaza City. </p>
          <br />
          <p>
            Equipped with a water well providing clean water and sanitation facilities to help prevent the spread of diseases, this camp aims to
            provide critical assistance to families displaced by recent violence and turmoil.
          </p>
          <h6 className="font-bold text-DarkerBlue mt-2">MATW Camp Infrastructure and Services:</h6>
          <p>
            Whilst this is only a temporary haven, our MATW team has meticulously designed the camp to ensure it delivers vital support to as many of
            our brothers and sisters as possible.
            <br />
            <br />
            The camp includes:
          </p>
          <ul className="flex flex-col gap-y-1">
            <li className="flex gap-1">
              <p className="text-DarkerBlue font-bold">Shelter -</p>
              <p>500 tents protecting families from harsh weather</p>
            </li>
            <li className="flex gap-1">
              <p className="text-DarkerBlue font-bold">Water -</p>
              <p>A central well providing clean, safe drinking water</p>
            </li>
            <li className="flex gap-1">
              <p className="text-DarkerBlue font-bold">Sanitation -</p>
              <p>Dedicated facilities to maintain hygiene and prevent disease</p>
            </li>
            <li className="flex gap-1">
              <p className="text-DarkerBlue font-bold">Energy -</p>
              <p>Solar-powered system with mobile charging stations</p>
            </li>
          </ul>
          <p className="text-DarkerBlue font-bold mt-4">
            Our brothers and sisters in Palestine are hurting and when one part of our Ummah hurts, we all feel the pain. Now is the time to stand
            together to help our brothers and sisters in Palestine.
          </p>
        </div>
      ),
      box_title: '',
      box_description: ``,
    },
    {
      title:
        'Our dream is becoming a reality with a five-story orphanage, each floor a sanctuary of 360 square meters, creating a total space of 1,800 square meters. This home will embrace 132 children, offering them warmth, security, and a chance to grow into their full potential.',
      description: (
        <p>
          In addition, a dedicated two-floor medical center will rise, providing vital healthcare services to everyone in need. This facility will
          stand as a promise of health and well-being for the entire community.
          <br />
          <br />
          <strong>Ambitions of the Project</strong>
          <br />
          <ul className="list-disc pl-6">
            <li>Safe Haven: A nurturing environment where orphaned children can feel secure and cherished.</li>
            <li>Healthcare Access: A fully equipped medical center ready to serve the health needs of orphans and local residents.</li>
            <li>Quality Construction: Building with the highest standards of safety and care.</li>
          </ul>
          <br />
          <br />
          <strong>Foreseen Impact</strong>
          <br />
          <br />
          <ul className="list-disc pl-6">
            <li>Improved Lives: Orphans will experience better living conditions and access to education, giving them a brighter future.</li>
            <li>
              Healthcare for All: The medical center will ensure that both the orphanage and the wider community have access to essential healthcare.
            </li>
            <li>Community Spirit: A united community strengthened by the presence of these new facilities.</li>
          </ul>
        </p>
      ),
      box_title: '',
      box_description: ``,
    },
  ];
  const [selectedInfo, setSelectedInfo] = useState(moreInfo[0]);
  const suggestedPrices = [50, 150, 250];
  const [selectedPrice, setSelectedPrice] = useState(suggestedPrices[2]);
  const [selectAmount, setSelectAmount] = useState('');

  const suggestedInputsPrices = [
    { value: 30, label: '30' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 15, label: '15' },
    { value: 10, label: '10' },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  /* ------------------------------ Start methods ----------------------------- */
  const handlePercentage = (raised, goal) => {
    return `${((Number(raised) / Number(goal)) * 100).toFixed(2)}%`;
  };
  // const handleDaysLeft = () => {
  //   var now = moment(new Date());
  //   // var before = moment(new Date(startDateOfAppealCampaign), 'DD-MM-YYYY HH:mm:ss');
  //   // var timeDifference = now.diff(before, 'days');
  //   // var after = moment(new Date(endDateOfAppealCampaign), 'DD-MM-YYYY HH:mm:ss');
  //   var after = moment(
  //     new Date(
  //       campaign === 'limbs-of-hope' ? endDateOfLimbsAppealCampaign : campaign === 'gaza-camp' ? endDateOfGazaCampCampaign : endDateOfAppealCampaign,
  //     ),
  //     'DD-MM-YYYY HH:mm:ss',
  //   );
  //   var timeDifference = after.diff(now, 'days');
  //   return timeDifference;
  // };
  const ShowCurrencies2 = (currency, _price, showAbbr = true) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = '';
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        _final = `${price}`;
        break;
      case 'IDR':
        _final = `Rp${price}`;
        break;
      case 'AED':
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    switch (campaign) {
      case 'palestine':
        setSelectedInfo(moreInfo[0]);
        break;
      case 'egyptian':
        setSelectedInfo(moreInfo[1]);
        break;
      case 'limbs-of-hope':
        setSelectedInfo(moreInfo[2]);
        break;
      case 'gaza-camp':
        setSelectedInfo(moreInfo[3]);
        break;
      case 'north-lebanon-orphan':
        setSelectedInfo(moreInfo[4]);
        break;

      default:
        setSelectedInfo(moreInfo[0]);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */

  return (
    <section className="w-full md:container md:mx-auto grid grid-cols-2 gap-2 md:gap-8 pb-0 md:py-8">
      <div className="col-span-2 md:col-span-1">
        <div
          className={`basis-full xs:aspect-w-16  xs:aspect-h-9 sm:h-[300px] md:h-[600px] lg:h-[890px] !max-h-[300px] min-h-fit md:!max-h-[450px]   md:!rounded-lg overflow-hidden`}
        >
          <iframe
            className="video"
            width={'100%'}
            height={'100%'}
            title={'youtube'}
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={
              campaign === 'palestine'
                ? 'https://youtube.com/embed/8fCYKifjeg4?autoplay=0&feature=oembed'
                : campaign === 'egyptian'
                ? 'https://youtube.com/embed/_KD4SL8KDk0?autoplay=0&feature=oembed'
                : campaign === 'limbs-of-hope'
                ? 'https://youtube.com/embed/ScnETpaXUSY?autoplay=0&feature=oembed'
                : campaign === 'gaza-camp'
                ? 'https://youtube.com/embed/s1NIFIYhC-I?autoplay=0&feature=oembed'
                : 'https://youtube.com/embed/pyV4cpcqvFE?autoplay=0&feature=oembed'
            }
          />
        </div>
      </div>
      <div className="col-span-2 md:col-span-1 px-4 py-3 lg:pl-2 lg:pr-0 md:pt-0 md:pb-0">
        <h3 className="w-full text-DarkerBlue text-2xl lg:text-3xl xl:text-4xl font-medium my-3 md:my-2 md:leading-none">
          {campaign === 'palestine'
            ? 'Gaza Can’t Wait!'
            : campaign === 'egyptian'
            ? 'Help Rebuild Lives for Palestinians escaping conflict'
            : campaign === 'limbs-of-hope'
            ? 'GAZA: Limbs of Hope'
            : campaign === 'gaza-camp'
            ? 'Emergency Homes for Gaza for Winter'
            : 'North Lebanon Orphan and Medical Centre'}
        </h3>
        <h4 className="text-stone-500">
          We need funds immediately to help rehouse homeless Palestinians. Help us keep them out of the cold this winter.
        </h4>
        <div className="w-full flex-col flex justify-center pt-2 md:pt-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex flex-col w-full">
              <div className="text-lg md:text-2xl text-DarkerBlue basis-full font-bold flex items-center">
                {currentProduct && currentProduct.totalRaised ? (
                  ShowCurrencies2(selectedCurrency, Math.round(currentProduct.totalRaised), true)
                ) : (
                  <div className="w-[100px]">
                    <Skeleton height={28} />
                  </div>
                )}
                <span className="text-sm ml-0.5">{selectedCurrency}</span>
                <span className="text-sm inline-block lg:w-auto text-stone-500 mx-1 font-medium">Raised of </span>
                <div className="text-DarkerBlue font-bold mr-1">
                  {ShowCurrencies2(selectedCurrency, Math.round(ConvertUSD(selectedCurrency, goal || 500000, false), false))}
                </div>
                <span className="text-sm inline-block lg:w-auto font-medium">goal</span>
              </div>
              {/* <div className="flex items-center gap-1">
                <span className="text-sm inline-block lg:w-auto">Raised of </span>
                <div className="text-DarkerBlue font-bold">
                  {ShowCurrencies2(selectedCurrency, Math.round(ConvertUSD(selectedCurrency, goal || 500000, false), false))}
                </div>
                <span className="text-sm inline-block lg:w-auto">goal</span>
              </div> */}
            </div>
          </div>
          <div className="flex w-full h-2 bg-[#E3E3E3] bg-opacity-10- mb-2">
            {currentProduct && (
              <div
                className={`bg-[#F60362]`}
                style={{
                  width: `${handlePercentage(currentProduct ? currentProduct.totalRaised : 0, ConvertUSD(selectedCurrency, goal, false))}`,
                }}
              />
            )}
          </div>
          <div className="flex w-full text-xs">
            <div className="text-DarkerBlue font-bold mr-0.5">{currentProduct ? currentProduct.totalSupporters : 0}</div>
            <span className="inline-block lg:w-auto mr-1">Supporters,</span>
            <div className="text-DarkerBlue font-bold mr-0.5">{handleDaysLeft(campaign)}</div>
            <span className="inline-block lg:w-auto">days left</span>
          </div>
        </div>

        <div className="w-full grid grid-cols-3 gap-2 md:gap-3 my-2 md:my-4 md:max-w-[520px] lg:pr-3">
          {suggestedPrices.map((item, index) => {
            return (
              <div
                key={`prices${index}`}
                onClick={() => setSelectedPrice(item)}
                className={`w-full cursor-pointer transition-all duration-300 border border-LightBlue rounded-md flex justify-center items-center py-2 h-[42px] ${
                  selectedPrice === item ? 'bg-LightBlue text-white' : 'bg-white text-LightBlue'
                }`}
              >
                {ShowCurrencies2(selectedCurrency, item, true)}
                <small className="ml-0.5 md:ml-1 mt-0.5">{selectedCurrency}</small>
              </div>
            );
          })}
          <>
            <SelectCurrency
              onChangeCurrency={e => setSelectedCurrency(e.value)}
              classNamePrefix="orphan_select- orphan_select--ghaza"
              className={`!w-full !text-sm`}
            />
            <div className="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1 relative h-[42px] flex border border-[#ccc] focus:ring-transparent focus:border-LightBlue rounded-[.375rem] w-full">
              <input
                value={selectAmount}
                name="amount"
                className="flex-1 text-[#777] w-full truncate p-2 text-sm focus:outline-none focus:ring-1 focus:ring-transparent !border-none rounded-[.375rem]"
                type="number"
                min={'0'}
                onChange={e => {
                  const value = e.target.value;

                  // Update the state only if the value is valid
                  const regex = /^(?:[1-9]\d*)?$/;
                  if (regex.test(value)) {
                    setSelectAmount(value);
                    setSelectedPrice(value ? parseInt(value, 10) : suggestedPrices[2]); // Update selectedPrice with the custom value
                  }
                }}
                placeholder="Other Amount"
              />
              <div classNames="absolute right-0 top-0 h-[42px]">
                <ReactSelect
                  defaultValue={selectAmount}
                  onChange={selectedOption => {
                    setSelectAmount(selectedOption.value);
                    setSelectedPrice(selectedOption.value);
                    setSelectedOption(selectedOption);
                  }}
                  classNamePrefix="price-select"
                  options={suggestedInputsPrices}
                  isSearchable={false}
                  placeholder=""
                  isClearable={false} // Remove clear icon
                  className="notranslate max-h-[42px] [&>*]:!cursor-pointer !w-full [&>div]:!rounded-md !max-w-6"
                />
              </div>
            </div>
            <CustomButton
              {...(selectedGivingProducts.length
                ? { link: '/checkout' } // Navigate to '/checkout' if products exist
                : {
                    onClick: () =>
                      addProduct({
                        product: currentProduct,
                        currency: selectedCurrency,
                        defaultPrice: selectedPrice,
                      }), // Add product if no products exist
                  })}
              title={'Donate Now'}
              disabled={!currentProduct}
              type="button"
              className={`w-full col-span-3 sm:col-span-1 md:col-span-3 lg:col-span-1 !h-[42px]`}
            />
          </>
        </div>

        <div className="w-full rounded-md px-3 h-[38px] bg-[#80ceff] flex items-center justify-center sm:hidden">
          <img src={creditImg} alt="MATW" className="max-w-full h-auto max-h-[38px]" />
        </div>
        <div className="w-full relative">
          <div className={`my-2 relative overflow-hidden h-auto mb-4`}>
            <p className={`text-[#78716C] text-xs- sm:text-sm overflow-hidden transition-all duration-200 min-h-[79px]-`}>
              {campaign === 'palestine' ? (
                'For almost a year, too many people have suffered for far too long due to the devastation the conflict in Palestine has left in its wake. The lives of children and their families continue to be uprooted, leaving many orphaned with no one to protect them.'
              ) : campaign === 'egyptian' ? (
                'Imagine losing your belongings, your home, loved ones - everything you’ve ever known. That is the reality for more than 100,000 Palestinians who have fled to neighbouring countries.'
              ) : campaign === 'limbs-of-hope' ? (
                `Deep inside the ruins of Gaza lies a problem not known to many. More than 94,000
                      Palestinians have been severely injured since the beginning of the most recent conflict on
                      October 7, 2023.`
              ) : campaign === 'gaza-camp' ? (
                <span>
                  After more than a year of unimaginable suffering, Palestinian families now face the brutal threat of winter without shelter. Their
                  homes destroyed, these resilient communities remain determined to survive and stay. Beneath the rubble lies Gaza - a home that beats
                  with an unbreakable spirit.
                  <br />
                  <br />
                  MATW is building a camp to shelter 400 families - 2,500 people - and we urgently need your support to provide tents, supplies, and
                  hope in their darkest hour. The people of Gaza have chosen life, and together, we can ensure they stay. Use your Zakat and Sadaqah
                  to save lives today.
                </span>
              ) : (
                `This ambitious project will feature a five-floor orphanage, each floor covering 360 square meters, resulting in a total space of 1,800 square meters. The orphanage will be a haven for 132 children, offering them comfort, care, and the opportunities they need to thrive.
                      Alongside the orphanage, a separate two-floor medical center will be built. This facility will deliver comprehensive healthcare services, ensuring that everyone in the community has access to quality medical care.`
              )}
            </p>
          </div>
        </div>
        <CustomButton
          onClick={() => setShowInfo(!showInfo)}
          title={'Click to know more'}
          icon={<ChevronDownIcon className={`w-5 h-5 transition-all duration-300 cursor-pointer ml-1 ${showInfo ? ' transform rotate-180' : ''}`} />}
          type="button"
          className={`!bg-LightBlue !text-[10px] !h-8`}
        />
        <div
          className={`flex flex-col overflow-hidden w-full bg-stone-100- rounded-md ${
            showInfo ? 'min-h-[600px] py-4 md:py-8' : 'h-0 py-0'
          } transition-all duration-200`}
        >
          <div className={`text-2xl md:text-4xl mb-4 ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-DarkerBlue`}>
            {selectedInfo.title}
          </div>
          <div className={`text-[#78716C] md:text-lg ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}>
            {selectedInfo.description}
            {/* {<RawHTML>{selectedInfo.description}</RawHTML>} */}
          </div>
          <div className="bg-white rounded-md shadow-md mt-2 grid grid-cols-2 gap-2 md:p-4">
            <div className="col-span-2">
              <div className={`text-DarkerBlue text-4xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-gotcha- font-brandingBold'}`}>
                {selectedInfo.box_title}
              </div>
            </div>
            <div className="col-span-2 mb-4">
              <div className={`text-[#78716C] md:text-lg ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}>
                {selectedInfo.box_description}
              </div>
            </div>
          </div>
          <CustomButton
            onClick={() => setShowInfo(!showInfo)}
            title={'Close'}
            icon={<ChevronDownIcon className={`w-5 h-5 transition-all duration-300 cursor-pointer ml-1 transform rotate-180`} />}
            type="button"
            className={`!bg-white !text-LightBlue !text-[10px] !h-8 shadow-md`}
          />
        </div>
      </div>
    </section>
  );
};

export default AppealCurrentProductUAT;