import {useEffect, useState} from "react";
import {
    calculateTimeLeft,
    convertTo12Hour,
    formatTimeLeft,
    getCalculationMethod,
    getDefaultPrayerTimes,
    computeNextPrayer, getLatLngFromApi, getPrayersDataUsingLatLng,
} from "./utils";
import PrayerTimesPageContent from "./HtmlContentForNamazTimes";
import MonthlyPrayerTable from "./MonthlyPrayerTable";
import NamazTimeSitemapAccordion from "./NamazTimeSitemapAccordion";

const AllNamazTimes = ({prayerTimes, locationData, monthlyPrayerTimes, city, pathType, namazOrSalah}) => {
    const [nextPrayer, setNextPrayer] = useState(null);
    const [timeLeft, setTimeLeft] = useState("");
    const [calcMethodData, setCalcMethodData] = useState({
        prayerTimeData: [], prayerTimeCalculationMethod: "UISK",
    });

    // Helper to capitalize the country name
    const formatCountryName = (name) => {
        if (!name) return "";
        if (name.length === 2) return name.toUpperCase();
        if (name.length === 3) return name.toUpperCase();
        // Replace hyphens with spaces & capitalize each word
        return name
            .replace(/-/g, " ")
            .replace(/\b\w/g, (c) => c.toUpperCase());
    };

    // Format the prayer name
    const formatPrayerName = (name) => {
        if (!name) return "";
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };

    // Fetch new prayer times with a different calc method
    const fetchPrayerTimesByMethod = async (_location, methodId) => {
        try {
            

            const locationResponse = await getLatLngFromApi(_location.country, _location.city);
            const response = await getPrayersDataUsingLatLng(locationResponse.data.lat, locationResponse.data.lng, 'times', methodId);

            
            // const response = await getPrayerDataFromApi(_location, );
            if (!response.ok) {
                setCalcMethodData({
                    prayerTimeData: getDefaultPrayerTimes(), prayerTimeCalculationMethod: "No method used",
                });
                return;
            }
            const namazData = await response.json();
            if (!namazData.data?.timings?.Fajr) {
                setCalcMethodData({
                    prayerTimeData: getDefaultPrayerTimes(), prayerTimeCalculationMethod: "No method used",
                });
                return;
            }
            setCalcMethodData({
                prayerTimeData: [{
                    name: formatPrayerName("Fajr"), time: convertTo12Hour(namazData.data.timings.Fajr),
                }, {
                    name: formatPrayerName("Zuhr"), time: convertTo12Hour(namazData.data.timings.Dhuhr),
                }, {
                    name: formatPrayerName("Asr"), time: convertTo12Hour(namazData.data.timings.Asr),
                }, {
                    name: formatPrayerName("Maghrib"), time: convertTo12Hour(namazData.data.timings.Maghrib),
                }, {
                    name: formatPrayerName("Isha"), time: convertTo12Hour(namazData.data.timings.Isha),
                }, {
                    name: formatPrayerName("Sunrise"), time: convertTo12Hour(namazData.data.timings.Sunrise),
                },], prayerTimeCalculationMethod: getCalculationMethod(namazData.data.meta?.method?.name),
            });
        } catch (err) {
            setCalcMethodData({
                prayerTimeData: getDefaultPrayerTimes(), prayerTimeCalculationMethod: "No method used",
            });
        }
    };

    // On mount (or when location changes), fetch alternate method (just method=1 in example)
    useEffect(() => {
        if (!locationData) return;

        fetchPrayerTimesByMethod(locationData, 1);
        // eslint-disable-next-line
    }, [locationData]);

    // Compute next prayer from the main prayerTimes prop
    useEffect(() => {
        if (!prayerTimes?.prayerTimeData?.length) return;

        const updateNextPrayer = () => {
            const next = computeNextPrayer(prayerTimes.prayerTimeData, prayerTimes.prayerTimeTimezone);
            if (next) {
                setNextPrayer(next);
                const left = calculateTimeLeft(next, prayerTimes.prayerTimeTimezone);
                setTimeLeft(formatTimeLeft(left));
            }
        };

        // Run once now
        updateNextPrayer();

        // Then run every second
        const interval = setInterval(updateNextPrayer, 1000);
        return () => clearInterval(interval);
    }, [prayerTimes?.prayerTimeData?.length, prayerTimes.prayerTimeTimezone]);

    const handleMethodChange = (selectedMethod) => {
        fetchPrayerTimesByMethod(locationData, selectedMethod);
    };

    return (<div className="w-full min-h-screen bg-white text-center">
        <div className="w-full h-full">
            {/* PAGE TITLE */}
            <div className="p-4 rounded-t-lg text-center">
                <h1 className="text-xl md:text-2xl text-[#F60362] font-bold break-words">
                    {namazOrSalah} Time in{" "}
                    {locationData?.city
                        ?.replace(/-/g, " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    {locationData?.country && !city ? `, ${formatCountryName(locationData.country)}` : ""}
                </h1>
                {/*{*/}
                {/*    JSON.stringify(prayerTimes)*/}
                {/*}*/}
                {!pathType.includes('timetable') && (<p className="text-xs md:text-sm text-gray-500 mt-2">
                    Local calculation method for{" "}
                    {formatCountryName(locationData?.country)} using:{" "}
                    {prayerTimes?.prayerTimeCalculationMethod}
                </p>)}
                {/*<p className="text-xs md:text-sm text-gray-500 mt-2">*/}
                {/*    Local calculation method for{" "}*/}
                {/*    {formatCountryName(locationData?.country)} using:{" "}*/}
                {/*    {prayerTimes?.prayerTimeCalculationMethod}*/}
                {/*</p>*/}
            </div>

            {!pathType.includes('timetable') && (<>
                {nextPrayer && (
                    <div className="mb-4 p-3 md:p-4 bg-blue-50 rounded-lg mx-2 md:mx-auto max-w-[800px]">
                        <h2 className="text-sm md:text-base">
              <span className="text-[#F60362] font-bold">
                Your NEXT {namazOrSalah} time in{" "}
                  {locationData?.city
                      ?.replace(/-/g, " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                  {locationData?.country && !city ? `, ${formatCountryName(locationData.country)}` : ""}{" "}
                  is at:
              </span>{" "}
                            <span className="text-[#064E98] font-bold">{nextPrayer.time}</span>
                        </h2>
                        <div className="mt-2 text-sm md:text-base">
                            <span className="mr-2">You have</span>
                            <span className="font-mono font-bold rounded-md shadow-sm w-24 text-center">
                {timeLeft}
              </span>
                            <span className="ml-2">
                time left for next {namazOrSalah} in{" "}
                                {locationData?.city
                                    ?.replace(/-/g, " ")
                                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                                {locationData?.country && !locationData?.city ? `, ${formatCountryName(locationData.country)}` : ""}
              </span>
                        </div>
                    </div>)}
            </>)}

            {!pathType.includes('timetable') ? (<>
                {/* MAIN TABLE */}
                <div className="w-full px-2 md:px-5 max-w-[800px] mx-auto mb-5">
                    <div className="overflow-x-auto">
                        <table className="w-full border border-collapse">
                            <thead>
                            <tr>
                                <th className="bg-[#064E98] text-white p-2 md:p-4 text-left font-normal text-base md:text-xl w-1/2 italic">
                                    Prayer name
                                </th>
                                <th className="bg-[#064E98] text-white p-2 md:p-4 text-left font-normal text-base md:text-xl w-1/2 italic">
                                    {namazOrSalah} time in{" "}
                                    {locationData?.city
                                        ?.replace(/-/g, " ")
                                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                                    {locationData?.country && !city ? `, ${formatCountryName(locationData.country)}` : ""}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {prayerTimes.prayerTimeData
                                ?.filter((x) => x.name.toLowerCase() !== "sunrise")
                                .map((prayer) => (<tr
                                    key={prayer.name}
                                    className={`transition-colors ${nextPrayer?.name === prayer.name ? "bg-blue-50 border-l-4 border-blue-600" : "bg-white"}`}
                                >
                                    <td className="p-2 md:p-4 text-left">
                        <span
                            className={`font-medium ${nextPrayer?.name === prayer.name ? "text-black-800 text-base md:text-lg" : "text-gray-900"}`}
                        >
                          {prayer.name}
                        </span>
                                    </td>
                                    <td
                                        className={`p-2 md:p-4 text-left ${nextPrayer?.name === prayer.name ? "text-black-800 font-medium" : "text-gray-600"}`}
                                    >
                                        {prayer.time}
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* NEXT PRAYER BANNER */}
                {nextPrayer && (
                    <div className="mb-4 p-3 md:p-4 bg-blue-50 rounded-lg mx-2 md:mx-auto max-w-[800px]">
                        <h2 className="text-sm md:text-base">
              <span className="text-[#F60362] font-bold">
                Your NEXT {namazOrSalah} time in{" "}
                  {locationData?.city
                      ?.replace(/-/g, " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                  {locationData?.country && !city ? `, ${formatCountryName(locationData.country)}` : ""}{" "}
                  is at:
              </span>{" "}
                            <span className="text-[#064E98] font-bold">{nextPrayer.time}</span>
                        </h2>
                        <div className="mt-2 text-sm md:text-base">
                            <span className="mr-2">You have</span>
                            <span className="font-mono font-bold rounded-md shadow-sm w-24 text-center">
                {timeLeft}
              </span>
                            <span className="ml-2">
                time left for next {namazOrSalah} in{" "}
                                {locationData?.city
                                    ?.replace(/-/g, " ")
                                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                                {locationData?.country && !locationData?.city ? `, ${formatCountryName(locationData.country)}` : ""}
              </span>
                        </div>
                    </div>)}

                {/* METHOD BUTTONS */}
                <div className="flex flex-col md:flex-row justify-center gap-2 md:gap-4 px-2 md:px-4">
                    {[{id: 1, text: "(UISK)"}, {id: 2, text: "(ISNA)"}, {id: 3, text: "(MWL)"}, {
                        id: 4, text: "(UIS)"
                    }].map((method) => (<button
                        key={method.id}
                        onClick={() => handleMethodChange(method.id)}
                        className="bg-[#F60362] hover:bg-[#1C9DDA] text-white px-4 py-2 rounded-md text-sm md:text-base"
                    >
                        {method.text}
                    </button>))}
                </div>

                {/* LOCATION & METHOD TEXT */}
                <div className="text-center text-[#F60362] font-medium text-sm md:text-base px-2 mt-4">
                    <h3>
                        {locationData?.city
                            ?.replace(/-/g, " ")
                            .replace(/\b\w/g, (c) => c.toUpperCase())}
                        , {formatCountryName(locationData?.country)} - {namazOrSalah} Times
                        Calculated using
                        <br/>
                        {calcMethodData.prayerTimeCalculationMethod}
                    </h3>
                </div>

                {/* SECOND TABLE (alt method) */}
                <div className="w-full px-2 md:px-5 max-w-[800px] mx-auto mt-4">
                    <div className="overflow-x-auto">
                        <table className="w-full border border-collapse">
                            <thead>
                            <tr>
                                <th className="bg-[#064E98] text-white p-2 md:p-4 text-left font-normal text-base md:text-xl w-1/2 italic">
                                    Prayer name
                                </th>
                                <th className="bg-[#064E98] text-white p-2 md:p-4 text-left font-normal text-base md:text-xl w-1/2 italic">
                                    {namazOrSalah} time in{" "}
                                    {locationData?.city
                                        ?.replace(/-/g, " ")
                                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                                    {locationData?.country && !city ? `, ${formatCountryName(locationData.country)}` : ""}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {calcMethodData.prayerTimeData
                                .filter((x) => x.name.toLowerCase() !== "sunrise")
                                .map((prayer) => (<tr key={prayer.name} className="text-left">
                                    <td className="p-2 md:p-4 text-left font-medium text-gray-900 text-sm md:text-lg">
                                        {prayer.name}
                                    </td>
                                    <td className="p-2 md:p-4 text-left text-gray-600 text-sm md:text-lg">
                                        {prayer.time}
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>) : (<>
                {/*{*/}
                {/*    JSON.stringify(monthlyPrayerTimes)*/}
                {/*}*/}

                <div className="w-full max-w-6xl mx-auto p-4">
                    <table className="w-full border-collapse bg-white">
                        <thead>
                        <tr>
                            <th className="p-3 text-center bg-[#064E98] text-white font-semibold">
                                Date
                            </th>
                            <th className="p-3 text-center bg-[#064E98] text-white font-semibold">
                                Fajr
                            </th>
                            <th className="p-3 text-center bg-[#064E98] text-white font-semibold">
                                Sunrise
                            </th>
                            <th className="p-3 text-center bg-[#064E98] text-white font-semibold">
                                Zuhr
                            </th>
                            <th className="p-3 text-center bg-[#064E98] text-white font-semibold">
                                Asr
                            </th>
                            <th className="p-3 text-center bg-[#064E98] text-white font-semibold">
                                Maghrib
                            </th>
                            <th className="p-3 text-center bg-[#064E98] text-white font-semibold">
                                Isha
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {monthlyPrayerTimes.prayerTimesData.map((prayerTime, index) => {
                            // First validate the data structure
                            if (!prayerTime || !prayerTime.date || !prayerTime.prayerTimeData || !Array.isArray(prayerTime.prayerTimeData)) {
                                console.log('Invalid prayer time data:', prayerTime);
                                return null;
                            }

                            // Check if we have all required prayer times
                            if (prayerTime.prayerTimeData.length < 6) {
                                console.log('Incomplete prayer time data:', prayerTime);
                                return null;
                            }

                            return (<tr key={prayerTime.date.readable || index}
                                        className={index % 2 === 0 ? 'bg-white' : 'bg-pink-50'}>
                                <td className="p-3 text-gray-800 font-medium">
                                    {prayerTime.date?.readable || 'N/A'}
                                </td>
                                {[0, 1, 2, 3, 4, 5].map(timeIndex => (<td key={timeIndex} className="p-3">
                    <span className="text-sm ml-1">
                        {prayerTime.prayerTimeData[timeIndex]?.time || 'N/A'}
                    </span>
                                </td>))}
                            </tr>);
                        })}
                        {/*{monthlyPrayerTimes.prayerTimesData.map((prayerTime, index) => (*/}
                        {/*    <>*/}
                        {/*        {*/}
                        {/*            JSON.stringify(prayerTime)*/}
                        {/*        }*/}

                        {/*<tr key={prayerTime?.date?.readable}*/}
                        {/*    className={index % 2 === 0 ? 'bg-white' : 'bg-pink-50'}>*/}
                        {/*    <td className="p-3 text-gray-800 font-medium">*/}
                        {/*        {prayerTime?.date?.readable}*/}
                        {/*    </td>*/}
                        {/*    <td className="p-3">*/}
                        {/*        /!*<span className="text-gray-800">{prayerTime.prayerTimeData[0].name}</span>*!/*/}
                        {/*        <span*/}
                        {/*            className=" text-sm ml-1">{prayerTime?.prayerTimeData[0]['time']}</span>*/}
                        {/*    </td>*/}
                        {/*    <td className="p-3">*/}
                        {/*        /!*<span className="text-gray-800">{prayerTime.prayerTimeData[0].name}</span>*!/*/}
                        {/*        <span*/}
                        {/*            className=" text-sm ml-1">{prayerTime?.prayerTimeData[1]['time']}</span>*/}
                        {/*    </td>*/}
                        {/*    <td className="p-3">*/}
                        {/*        /!*<span className="text-gray-800">{prayerTime.prayerTimeData[0].name}</span>*!/*/}
                        {/*        <span*/}
                        {/*            className=" text-sm ml-1">{prayerTime?.prayerTimeData[2]['time']}</span>*/}
                        {/*    </td>*/}
                        {/*    <td className="p-3">*/}
                        {/*        /!*<span className="text-gray-800">{prayerTime.prayerTimeData[0].name}</span>*!/*/}
                        {/*        <span*/}
                        {/*            className=" text-sm ml-1">{prayerTime?.prayerTimeData[3]['time']}</span>*/}
                        {/*    </td>*/}
                        {/*    <td className="p-3">*/}
                        {/*        /!*<span className="text-gray-800">{prayerTime.prayerTimeData[0].name}</span>*!/*/}
                        {/*        <span*/}
                        {/*            className=" text-sm ml-1">{prayerTime?.prayerTimeData[4]['time']}</span>*/}
                        {/*    </td>*/}
                        {/*    <td className="p-3">*/}
                        {/*        /!*<span className="text-gray-800">{prayerTime.prayerTimeData[0].name}</span>*!/*/}
                        {/*        <span*/}
                        {/*            className=" text-sm ml-1">{prayerTime?.prayerTimeData[5]['time']}</span>*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        {/*</>*/}
                        {/*))}*/}
                        </tbody>
                    </table>
                </div>
                {/*<MonthlyPrayerTable prayerTimesData={monthlyPrayerTimes}/>*/}
            </>)}

            {/* VERSE BANNER */}
            <div className="bg-[#F60362] text-white p-4 md:p-6 text-center text-sm md:text-base mt-6">
                <p className="max-w-3xl mx-auto">
                    2:3-4 Who believe in the unseen, establish prayer, and spend out of
                    what We have provided for them, And who believe in what has been
                    revealed to you, [O Muhammad], and what was revealed before you, and
                    of the Hereafter they are certain [in faith].
                </p>
            </div>

            {!pathType.includes('timetable') && (<>
                <div className="flex justify-center items-center bg-gray-50">
                    <PrayerTimesPageContent
                        city={locationData?.city
                            ?.replace(/-/g, " ")
                            .replace(/\b\w/g, (c) => c.toUpperCase())}
                        namazOrSalah={namazOrSalah}
                    />
                </div>

                {(window.location.pathname === '/namaz-times' || window.location.pathname === '/namaz-times/' || window.location.pathname === '/salah-times' || window.location.pathname === '/salah-times/') && (
                    <NamazTimeSitemapAccordion/>
                )}
            </>)}
        </div>
    </div>);
};

export default AllNamazTimes;
