import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import services from '../../services';
import ProductBox from '../../components/general/productBox';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';
// import ChangeDocumentTitle from '../../utils/changeDocumentTitle';
import Skeleton from 'react-loading-skeleton';

const OrphanHomesLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const [products, setProducts] = useState({
    name: '',
    products: [0],
  });
  const [isLoading, setIsLoading] = useState(true);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getProductsByName = async name => {
    setIsLoading(true);
    try {
      const response = await services.categoryProductsByName(name);
      let tempOrphanProducts = [];
      let tempAllProducts = response.data;
      response.data.products.map(item => {
        item.raised = 0;
        if (item.name === 'Orphanage House' && Number(item.status) === 1) tempOrphanProducts.push(item);
        return item;
      });
      tempAllProducts.products = tempOrphanProducts;
      setProducts(tempAllProducts);
      // ChangeDocumentTitle(`${response.data.name} - ${response.data.category ? response.data.category.name : ''} - MATW Project Muslim Charity`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('error========', error);
    }
  };
  const handleParagraph = text => {
    let tempText = [];
    if (text) {
      tempText = text.split('.');
      return tempText.map((txt, index) => {
        let temp =
          txt === '' ? (
            ''
          ) : (
            <p key={`p${index}`}>
              {txt}.<br />
            </p>
          );
        return temp;
      });
    }
  };
  const RawHTML = ({ children, className = '' }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '') }} />;
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    window.scroll(0, 0);
    getProductsByName('support an orphan');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout>
      <div className="flex md:w-full md:h-[120px] lg:w-full lg:h-[160px] xl:w-full xl:h-[200px] 2xl:w-full 2xl:h-[250px]">
        {!isLoading && (
          <img
            src={products.tile_image}
            alt={products.name}
            className="w-full"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/general/logo-replace.png';
              currentTarget.className = 'max-h-[150px] mx-auto';
            }}
          />
        )}
      </div>
      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />
      <div className="md:container md:mx-auto mt-5">
        <div className="grid grid-cols-12 px-3- md:px-4-">
          <div className="col-span-12 text-center text-6xl text-[#253b7e] flex justify-center items-center py-2- md:py-8- font-['Gotcha'] h-[125px]">
            {/* {!isLoading ? products.name : <Skeleton height={80} />} */}
            Orphanage House
          </div>
          <div className="col-start-1 col-span-12 rounded-md">
            <div className="flex flex-row justify-center flex-wrap">
              {/* {console.log("products",products)} */}
              {products.products.map((item, index) => {
                return (
                  <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                    <ProductBox product={item} currency={selectedCurrency} />
                  </div>
                );
              })}
            </div>
            <div
              className={`col-span-12 rounded-xl mt-6 lg:min-h-[500px]- bg-white p-6 lg:p-12 text-center shadow-lg ${
                products && (products.name === 'Food Packs') | (products.name === 'Cow Share') ? 'hidden' : ''
              }`}
            >
              <h1 className="text-[#00a3da] text-4xl mb-4">{products ? products.page_title : <Skeleton />}</h1>
              <p className="text-[#777] text-xl mb-4">{products ? products.page_subtitle : <Skeleton />}</p>
              <div className="text-[#999] text-md">
                {products ? (
                  products.page_description && products.page_description.includes('<') ? (
                    <RawHTML>{products.page_description}</RawHTML>
                  ) : (
                    handleParagraph(products.page_description)
                  )
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="col-span-12 rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
              <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                <iframe
                  className="video"
                  width={'100%'}
                  height={'100%'}
                  title={'youtube'}
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://youtube.com/embed/0FO3OfWACKY?autoplay=0`}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OrphanHomesLandingPage;