import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useKeenSlider } from 'keen-slider/react';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import AppealFundraiserProductBox from './appealFundraiserProductBox';
import { selectSelectedCurrency } from '../../../store/user';
import Arrow from '../../home/components/tab-arrow';
import ProductBox from '../../../components/general/productBox';
import AppealFundraiserProductBoxV3 from '../cartComponents/appealFundraiserProductBoxV3';

const AppealWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { className, usedOnTab, title = 'MATW Appeals', isHomePage = false, allProducts } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const MutationPlugin = slider => {
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        slider.update();
      });
    });
    const config = { childList: true };

    slider.on('created', () => {
      observer.observe(slider.container, config);
    });
    slider.on('destroyed', () => {
      observer.disconnect();
    });
  };
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      mode: 'snap',
      loop: true,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
      slides: {
        origin: 'auto',
        perView: 1,
        spacing: 8,
      },
      // breakpoints: {
      //   '(min-width: 320px)': {
      //     // slides: { origin: 'center', perView: 1.2, spacing: 10 },
      //     slides: { origin: 'center', perView: 1, spacing: 10 },
      //     range: { min: 0, max: 2 },
      //   },
      //   '(min-width: 768px)': {
      //     slides: { perView: 2, spacing: 10 },
      //   },
      //   '(min-width: 992px)': {
      //     slides: { perView: 4, spacing: 16, origin: 'center' },
      //   },
      // },
      // slides: {
      //   origin: 'center',
      //   // perView: 4,
      //   // spacing: 8,
      // },
    },
    [MutationPlugin],
  );
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */
  return (
    <section
      className={`${className ? className : 'bg-LighterBlue'} w-full overflow-hidden- px-4 md:px-0 ${usedOnTab ? 'py-2 md:py-4' : 'py-4 md:py-12'}`}
    >
      <div className="md:container w-full mx-auto">
        {!isHomePage && (
          <div className="w-full justify-center items-center flex pb-4 md:pb-8">
            <h2 className={`text-DarkerBlue font-brandingBold ${usedOnTab ? 'text-lg md:text-3xl' : 'text-2xl md:text-4xl'}`}>{title}</h2>
          </div>
        )}
        <div className="w-full hidden sm:flex flex-wrap justify-center items-center- items-start relative">
          {allProducts.map((item, index) => {
            return (
              <div
                key={`active_carousel_${index}`}
                className={`${isHomePage ? 'basis-1/2 lg:basis-1/3 xl:basis-1/4' : 'basis-1/3'} flex justify-center px-2 py-4`}
              >
                {isHomePage ? (
                  !item.campaign ? (
                    <ProductBox
                      product={item}
                      currency={selectedCurrency ? selectedCurrency : 'AUD'}
                      titleClasses={`uppercase`}
                      btnContainerClass={'h-[106px]'}
                    />
                  ) : (
                    <AppealFundraiserProductBoxV3
                      campaign={item.campaign || 'palestine'}
                      goal={item.goal}
                      totalRaised={item.totalRaised}
                      subTitle="Orphan Education"
                      product={item}
                      isHomePage={isHomePage}
                      currency={selectedCurrency ? selectedCurrency : 'AUD'}
                    />
                  )
                ) : (
                  <AppealFundraiserProductBox
                    campaign={item.campaign || 'palestine'}
                    goal={item.goal}
                    totalRaised={item.totalRaised}
                    subTitle="Orphan Education"
                    product={item}
                    isHomePage={isHomePage}
                    currency={selectedCurrency ? selectedCurrency : 'AUD'}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="w-full sm:hidden flex-wrap justify-center items-center relative border-">
          {allProducts.length > 1 && (
            <div className="hidden- md:flex ">
              {instanceRef.current && (
                <div
                  className={`absolute flex justify-center  items-center translate-y-1/2 w-8 h-8 top-[47%] -left-[16px] z-10 cursor-pointer text-white`}
                  onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
                >
                  <LiaAngleLeftSolid size={25} />
                </div>
              )}
              {instanceRef.current && (
                <div
                  className={`absolute flex justify-center items-center translate-y-1/2 w-8 h-8 top-[47%] -right-[16px] z-10 cursor-pointer text-white`}
                  onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                >
                  <LiaAngleRightSolid size={25} />
                </div>
              )}
            </div>
          )}
          {allProducts.length > 1 ? (
            <div ref={sliderRef} className={`keen-slider !w-[94%] min-[1280px]:!w-[94%] mx-auto`}>
              {allProducts.map((item, index) => {
                return (
                  <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center py-4">
                    <div className="w-full max-w-[300px]-">
                      {isHomePage ? (
                        !item.campaign ? (
                          <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} titleClasses={`uppercase`} />
                        ) : (
                          <AppealFundraiserProductBoxV3
                            campaign={item.campaign || 'palestine'}
                            goal={item.goal}
                            totalRaised={item.totalRaised}
                            subTitle="Orphan Education"
                            product={item}
                            currency={selectedCurrency ? selectedCurrency : 'AUD'}
                          />
                        )
                      ) : (
                        <AppealFundraiserProductBox
                          campaign={item.campaign || 'palestine'}
                          goal={item.goal}
                          totalRaised={item.totalRaised}
                          subTitle="Orphan Education"
                          product={item}
                          currency={selectedCurrency ? selectedCurrency : 'AUD'}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
              {/* {otherProduct && <div className="keen-slider__slide basis-full flex justify-center py-4">
                <div className="w-full max-w-[300px]-">
                  <ProductBox
                    product={otherProduct}
                    currency={selectedCurrency ? selectedCurrency : 'AUD'}
                    titleClasses={`uppercase`}
                  />
                </div>
              </div>} */}
            </div>
          ) : (
            <div className="!w-[94%] min-[1280px]:!w-[94%] mx-auto">
              {allProducts.map((item, index) => {
                return (
                  <div key={`active_carousel_${index}`} className="basis-full flex justify-center py-4">
                    <div className="w-full max-w-[300px]-">
                      {isHomePage ? (
                        <AppealFundraiserProductBoxV3
                          campaign={item.campaign || 'palestine'}
                          goal={item.goal}
                          subTitle="Orphan Education"
                          product={item}
                          currency={selectedCurrency ? selectedCurrency : 'AUD'}
                        />
                      ) : (
                        <AppealFundraiserProductBox
                          campaign={item.campaign || 'palestine'}
                          goal={item.goal}
                          subTitle="Orphan Education"
                          product={item}
                          currency={selectedCurrency ? selectedCurrency : 'AUD'}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {allProducts.length > 1 && (
            <div className="flex bg-white py-3 px-2 rounded-md justify-center items-center mx-auto mt-2 max-w-[300px]">
              {loaded && instanceRef.current && (
                <div className="flex justify-center items-center relative w-full">
                  <Arrow left onClick={e => e.stopPropagation() || instanceRef.current?.prev()} disabled={currentSlide === 0} />
                  {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                    return (
                      <button
                        key={`q${idx}`}
                        onClick={() => {
                          instanceRef.current?.moveToIdx(idx);
                        }}
                        className={
                          'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' +
                          (currentSlide === idx ? ' bg-[#F60362] hover:opacity-60' : 'bg-[#C3C3C3] hover:bg-[#F60362')
                        }
                      ></button>
                    );
                  })}
                  <Arrow
                    onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                    disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default AppealWidget;
