

const HtmlContent = ({ htmlCode = '' }) => {
    /* ----------------------------- Start variables ---------------------------- */
    const RawHTML = ({ children, className = "" }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '') }} />
    /* -------------------------------------------------------------------------- */
    return <RawHTML>{htmlCode}</RawHTML>
};

export default HtmlContent;
