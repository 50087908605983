import { Builder } from '@builder.io/react';
const H2Tag = ({
  text = '',
  className = ''
}) => {
  /* ----------------------------- Start variables ---------------------------- */
  /* -------------------------------------------------------------------------- */
  return <h2 className={`${className} w-full mb-2 text-xl md:text-3xl text-[#093686] font-brandingBold`}>{text}</h2>;
};
Builder.registerComponent(H2Tag, {
  name: 'H2Tag',
  inputs: [{
    "name": "text",
    "type": "string"
  }, {
    "name": "className",
    "type": "string"
  }]
});
export default H2Tag;