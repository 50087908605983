import { Builder } from "@builder.io/react";
import React, { useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

const CarouselComponent = ({ images }) => {
  const [loaded, setLoaded] = useState(false);

  const [currentSlideImages, setCurrentSlideImages] = useState(0);
  const [sliderRefImages, instanceRefImages] = useKeenSlider(
    {
      mode: 'free-snap',
      loop: true,
      slideChanged(slider) {
        setCurrentSlideImages(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
      slides: {
        origin: 'auto',
        perView: 1,
        spacing: 8,
      },
    },
    [
      slider => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ],
  );

  return (
      <div className="md:container md:mx-auto flex justify-center flex-wrap">
        <div className="w-full flex-wrap justify-center items-center px-6 sm:px-0 relative">
          <div ref={sliderRefImages} className="keen-slider">
            {images.map((imageObj, index) => (
              <div key={index} className="keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none ">
                <img
                  src={imageObj.image}
                  alt={imageObj.alt || `Slide ${index + 1}`} // Use provided alt or fallback
                  className=" bg-top object-cover w-full object-top"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="w-full  flex justify-center">
            {loaded && instanceRefImages.current && (
              <div className="dots flex pt-3">
                {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        instanceRefImages.current?.moveToIdx(idx);
                      }}
                      className={
                        'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                        (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                      }
                    ></button>
                  );
                })}
              </div>
            )}
          </div>
      </div>
  );
};

Builder.registerComponent(CarouselComponent, {
  name: "CarouselComponent",
  inputs: [
    {
      name: "images",
      type: "list",
      subFields: [
        {
          name: "image",
          type: "file",
          allowedFileTypes: ["jpeg", "jpg", "png", "gif"],
        },
        {
          name: "alt",
          type: "string",
          helperText: "Provide an alternate text for the image for better accessibility.",
        },
      ],
      defaultValue: [],
      helperText: "Add images with alt text for the carousel.",
    },
  ],
});

export default CarouselComponent;
