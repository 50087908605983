import { Builder } from '@builder.io/react';
const H3Tag = ({
  text = '',
  className = ''
}) => {
  /* ----------------------------- Start variables ---------------------------- */
  /* -------------------------------------------------------------------------- */
  return <h3 className={`${className} w-full mb-2 text-lg md:text-2xl text-[#093686] font-brandingBold`}>{text}</h3>;
};
Builder.registerComponent(H3Tag, {
  name: 'H3Tag',
  inputs: [{
    "name": "text",
    "type": "string"
  }, {
    "name": "className",
    "type": "string"
  }]
});
export default H3Tag;