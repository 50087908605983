import { Builder } from '@builder.io/react';
import PropTypes from 'prop-types';

function buildWellToday({
  backgroundImage,
  headingText,
  buttonText,
  buttonAction,
}) {
  const scrollToTop = () => {
    if (buttonAction === 'scrollToTop') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (typeof buttonAction === 'function') {
      buttonAction();
    }
  };

  return (
      <div
        className="px-4 flex flex-col gap-5 justify-center items-center bg-cover h-[260px] aspect-auto"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="text-white font-montserratBold text-[32px] md:text-[60px]">
          {headingText}
        </div>
        <button
          onClick={scrollToTop}
          className="text-[#F60362] rounded-xl bg-white w-[190px] py-3 text-[18px] mt-3 font-bold"
        >
          {buttonText}
        </button>
      </div>
  );
}

// Define default props
buildWellToday.defaultProps = {
  backgroundImage: '../src/images/sadaqah/water-well-sub-hero.jpg',
  headingText: 'What will yours be?',
  buttonText: 'Build a well today.',
  buttonAction: 'scrollToTop',
};

// Define prop types
buildWellToday.propTypes = {
  backgroundImage: PropTypes.string,
  headingText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

// Register the component in Builder.io
Builder.registerComponent(buildWellToday, {
  name: 'buildWellToday',
  inputs: [
    {
      name: 'backgroundImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'gif'],
      defaultValue: '../src/images/sadaqah/water-well-sub-hero.jpg',
      helperText: 'Select the background image for the section.',
    },
    {
      name: 'headingText',
      type: 'text',
      defaultValue: 'What will yours be?',
      helperText: 'Enter the heading text.',
    },
    {
      name: 'buttonText',
      type: 'text',
      defaultValue: 'Build a well today.',
      helperText: 'Enter the button text.',
    },
    {
      name: 'buttonAction',
      type: 'enum',
      enum: [
        { label: 'Scroll to Top', value: 'scrollToTop' },
        { label: 'Custom Function', value: 'custom' },
      ],
      defaultValue: 'scrollToTop',
      advanced: true,
      helperText: 'Select the button action.',
    },
  ],
});

export default buildWellToday;
