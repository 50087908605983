import { Builder } from "@builder.io/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IsGTMScriptLoaded from "../../../../../utils/isGTMScriptLoaded";
import getSymbolFromCurrency from "currency-symbol-map";
import { selectSelectedCurrency } from "../../../../../store/user";
import services from "../../../../../services";
import FundraiserProductBox from "./FundraiserProductBox";
import { checkDomain } from "../../../../../utils/checkDomain";

const ProductBoxesFundraiser = () => {
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState(
      selectedCurrencyStore || "AUD",
    );
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleIsAnyAmount = (product) => {
    const hasQuantity = product.quantity ? product.quantity > 1 : false;
    return Number(product.aud) <= 2 && !hasQuantity;
  };

  const handleViewItemDataLayer = (tempProduct, currency) => {
    const dataLayerTemp = {
      event: "view_item",
      ecommerce: {
        currency: currency.toUpperCase(),
        items: [
          {
            item_id: tempProduct.creator,
            item_name: tempProduct.name,
            item_brand: tempProduct.category || "",
            item_category: tempProduct.category || "",
            item_description: tempProduct.description || "",
            price: tempProduct[currency.toLowerCase()],
          },
        ],
      },
    };
    window.dataLayer = window.dataLayer || [];
    if (IsGTMScriptLoaded()) {
      window.dataLayer.push(dataLayerTemp);
    }
    const obj = {
      content_type: "product",
      content_id: tempProduct.creator,
      currency: currency,
      value: tempProduct[currency.toLowerCase()],
      price: tempProduct[currency.toLowerCase()],
      quantity: 1,
      content_category: tempProduct.category || "donation",
      content_name: tempProduct.name,
      description: tempProduct.description,
    };
    if (window.ttq) {
      if (!checkDomain('matwprojectme.org')) {
        window.ttq.instance(localStorage.getItem('TikTokID')).track('ViewContent', obj);
      }
    }
  };
  const ShowCurrencies = (currency, _price) => {
    const tempCurrency = currency?.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString("en", options);
    }
    let final = "";
    price = price ? price.toLocaleString() : "0";
    switch (tempCurrency) {
      case "GBP":
      case "EUR":
        final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case "MYR":
        final = `${price}`;
        break;
      case "IDR":
        final = `Rp${price}`;
        break;
      case "AED":
        final = `Dh${price}`;
        break;
      default:
        final = `$${price}`;
        break;
    }
    return final;
  };

  const getFundraiserProducts = async () => {
      let tempFutureProducts = [];
      try {
        const response = await services.fundraiserProducts();
        response.data.map(item => {
          item.raised = 0;
          if (item.name === 'Yemen Solar Powered Well') {
            item.goal = 40000;
            tempFutureProducts.push(item);
          }
          return item;
        });

        getProductsReports(tempFutureProducts);
      } catch (error) {
        console.error('error========', error);
      }
    };
    const getProductsReports = async tempFutureProducts => {
      try {
        const response = await services.orphanProductTransactions();
        response.data.length &&
          response.data.map((item, i) => {
            tempFutureProducts.map(future_item => {
              if (future_item.name === item.product_name) {
                future_item.raised = item.total_sales;
              }
              return future_item;
            });
            return null;
          });
          setSelectedProducts(tempFutureProducts);
      } catch (error) {
        console.error('error========', error);
      }
    };

    useEffect(() => {
        if (!isLoaded) {
          getFundraiserProducts();
          setIsLoaded(true); // Ensure it doesn't refetch repeatedly
        }
      }, [isLoaded]);

  useEffect(() => {
      setSelectedCurrency(selectedCurrencyStore || "AUD");
  }, [selectedCurrencyStore]);

  return (
    !!selectedProducts.length && (
      <div className="flex flex-wrap gap-3 md:gap-4 justify-center items-start w-full">
        {
          selectedProducts.map((product, index) => (
            <div
              key={`fundrising${index}`}
              className="basis-full min-[400px]:basis-1/2 md:basis-1/3 xl:basis-1/4 p-2 overflow-hidden"
            >
              <FundraiserProductBox
                goal={product.goal}
                subTitle="Orphan Education"
                product={product}
                currency={selectedCurrency ? selectedCurrency : 'AUD'}
                handleViewItemDataLayer={handleViewItemDataLayer}
                handleIsAnyAmount={handleIsAnyAmount}
                ShowCurrencies={ShowCurrencies}
              />
            </div>
          ))
        }
      </div>
    )
  );
};
Builder.registerComponent(ProductBoxesFundraiser, {
  name: "ProductBoxesFundraiser",
});
export default ProductBoxesFundraiser;
