import { useEffect, useState } from 'react';
import CustomButton from './button';
import { CloseIcon } from './customIcons';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
};

const getCookie = name => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
};

const FloatEmailSubscriptionWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isClosed, setIsClosed] = useState(false); // Track if the popup is closed
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      email: '',
      aggree: true,
    },
  });

  useEffect(() => {
    const fetchPopupSettings = async () => {
      try {
        const currentPage = window.location.pathname;
        const apiUrl = `https://matwproject.org/backend/index.php/api/popups?page=${currentPage}`;
        const response = await fetch(apiUrl);

        if (!response.ok) {
          // If the response is not OK, do not show the popup
          setIsEnabled(false);
          return;
        }

        const data = await response.json();

        // Check if there is an explicit disable for the popup on the current page
        const isPopupDisabled = data.some(
            (popup) =>
                popup.page === currentPage &&
                popup.popup_type === 'email' &&
                popup.enabled === false
        );

        // Determine if the popup should be enabled
        if (
            !isPopupDisabled && // Popup is not explicitly disabled
            getCookie('subscribed') !== 'true' && // User is not subscribed
            getCookie('popupClosed') !== 'true' // User has not closed the popup
        ) {
          setIsEnabled(true); // Enable the popup
        } else {
          setIsEnabled(false); // Disable the popup
        }
      } catch (error) {
        // Suppress error logging and disable the popup in case of any error
        setIsEnabled(false);
      }
    };

    fetchPopupSettings();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!isEnabled || isOpen || isClosed) return;

      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (scrollPercentage > 10) {
        setTimeout(() => setIsOpen(true), 100);
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isEnabled, isOpen, isClosed]);

  const onSubmitHandler = async data => {
    if (!emailRegExp.test(data.email)) {
      return toast.warning('Please enter a valid email');
    }
    if (!data.aggree) {
      return toast.warning('Please agree to receive newsletters and updates from MATW Project in line with the privacy policy.');
    }

    const response = await sendEmailToSibForm(data.email);

    if (response.ok) {
      toast.success('Thank you for subscribing to our newsletter');
      reset();
      setIsOpen(false);
      setIsClosed(true); 
      setCookie('subscribed', 'true', 30);
      setCookie('popupClosed', 'true', 30); 
    } else {
      toast.error('Something went wrong, please try again later');
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsClosed(true); 
    setCookie('subscribed', 'true', 7);
    setCookie('popupClosed', 'true', 30); // Save popup closed state in cookie
  };

  // If the popup is closed, don't render anything
  if (!isEnabled || isClosed) {
    return null;
  }

  return (
    <div
      className={`fixed top-0 left-0 bottom-0 right-0 bg-black/60 z-[210] flex justify-center items-center ${
        isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-0.9'
      } transition-all duration-500`}
      onClick={handleClose}
      style={{
        pointerEvents: isOpen ? 'auto' : 'none', 
      }}
    >
      <div
        onClick={e => e.stopPropagation()}
        className="w-full max-w-screen-xs lg:max-w-screen-md bg-popUpBackground rounded-lg grid lg:grid-cols-2 border-[0.5px] border-pink shadow-[0_0_10px_rgba(246,3,98,0.6)]"
      >
        <div className="pb-3 pt-4 lg:pt-8 px-8 relative">
          <h2 className="font-gotcha text-white text-center text-3xl lg:text-5xl">Palestine Emergency</h2>
          <p className="text-center text-white text-base lg:text-lg lg:leading-6 font-Helvetica mx-auto mt-8">
            Your donations make a huge difference to the people of Palestine.
          </p>
          <form onSubmit={handleSubmit(onSubmitHandler)} className="flex flex-col gap-y-2 mt-8">
            <div className="flex items-end gap-x-3 w-full max-w-[500px] xl:max-w-[380px]">
              <input
                {...register('email')}
                id="email"
                name="email"
                autoComplete="email"
                className="px-4 py-2 w-full outline-none rounded-md bg-popUpEmailBackground placeholder:text-white placeholder:opacity-70 shadow-2xl text-white"
                placeholder="Email"
              />
            </div>

            <div className="max-w-[500px] flex items-center gap-x-2 mt-4 px-1">
              <input
                id="aggree-receive-newsletter"
                className="relative float-left mr-1 h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-[rgba(0,0,0,0.25)] bg-white outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-[#00a3da] checked:bg-[#00a3da] checked:before:opacity-[0.16] checked:after:absolute checked:after:ml-[0.25rem] checked:after:-mt-px checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:bg-white focus:after:content-[''] checked:focus:border-[#00a3da] checked:focus:bg-[#00a3da] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:ml-[0.25rem] checked:focus:after:-mt-px checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                type="checkbox"
                {...register('aggree')}
              />

              <label
                htmlFor="aggree-receive-newsletter"
                className="hover:cursor-pointer text-white w-[calc(100%_-_1.125rem)] text-xs sm:text-sm flex items-start flex-col sm:flex-row font-sans"
              >
                <span>I agree to receive emails from MATW Project in line with the <Link to="/gdpr" target="_blank" className="underline">privacy policy</Link>.</span>
              </label>
            </div>
            <CustomButton
              onClick={() => 0}
              title="I want to help Palestine"
              className="!bg-popUpButtonBackground !h-8 w-full rounded-md normal-case"
            />
          </form>
          <p className="text-pink text-lg lg:text-xl pb-[1px] border-b border-pink w-fit mx-auto mt-4 lg:mt-6">100% Donation Policy</p>
          <button className="absolute text-black right-2 top-2 lg:hidden block bg-white rounded-full p-0.5" onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="w-full h-full relative hidden lg:block">
          <img
            src="https://cdn.matwproject.org/images/products/1729686036_ao-emr-wnt-gen-24-06.jpg"
            alt="Palestine Emergency"
            className="w-full h-full object-cover !aspect-auto rounded-r-lg"
          />

          <button className="absolute text-black right-2 top-2 hidden lg:block bg-white rounded-full p-0.5" onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

async function sendEmailToSibForm(email) {
  const formdata = new FormData();
  formdata.append('EMAIL', email);
  formdata.append('OPT_IN', '1');
  formdata.append('email_address_check', '');
  formdata.append('locale', 'en');

  return await fetch(
    'https://a942f65f.sibforms.com/serve/MUIFAJ2LQwb7EqGsSo8F-JP_MGGRXLQuYoXW7cVX3y96Lmg1yv9JYU4YXFtlnsCdJsyio9PlmlBuemUM05QvoTywEWwZEIHhWLTHGE_yRXaz5w09T5-h_pftX3Zo5rnj7t3-Tniz7YailIIJT9A70nOg7UiS4NbnKlmKk6jDLPHqD-qC1Ixm8CAQUD22GE04f0uvMneEK7X_lebj?isAjax=1',
    {
      method: 'POST',
      body: formdata,
    },
  );
}

export default FloatEmailSubscriptionWidget;
