// utils/timeZones.js

import moment from 'moment-timezone';

// Generate an array of time zone objects
const timeZones = moment.tz.names().map((tz) => ({
  value: tz,
  label: `${tz} (${moment.tz(tz).format('z')})`,
}));

export default timeZones;