import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
    selectCityName,
    selectCountryName,
    setCityName,
    setCountryName,
} from "../../store/prayer";
import {allCountries, findCountryCode} from "./utils"; // Assuming allCountries is a static import

/**
 * CountriesAndCities Component
 *
 * This component allows users to select a country and subsequently a city.
 * It optimizes performance by using memoization, efficient data structures,
 * and minimizing unnecessary re-renders.
 */
const CountriesAndCities = ({
                                pathType,
                                locationData,
                                namazOrSalah,
                                defaultCountry = "",
                                defaultCity = "",
                            }) => {
    // **Redux States**
    const preSelectedCountry = useSelector(selectCountryName);
    const preSelectedCity = useSelector(selectCityName);

    // **Local States**
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [cities, setCities] = useState([]);

    const dispatch = useDispatch();

    /**
     * **Country Lookup Map**
     *
     * Preprocesses `allCountries` into a lookup map for O(1) access.
     * Each country name, shortCode, and shortCode2 are mapped to their sorted majorCities.
     */
    const countryLookup = useMemo(() => {
        const map = new Map();
        allCountries.forEach((country) => {
            const lowerName = country.name.toLowerCase();
            const lowerShortCode = country.shortCode?.toLowerCase();
            const lowerShortCode2 = country.shortCode2?.toLowerCase();

            // Sort majorCities once during initialization
            const sortedMajorCities = country.majorCities
                ? _.orderBy(country.majorCities, ["name"], ["asc"])
                : [];

            // Map multiple keys to the same sorted majorCities
            map.set(lowerName, sortedMajorCities);
            if (lowerShortCode) map.set(lowerShortCode, sortedMajorCities);
            if (lowerShortCode2) map.set(lowerShortCode2, sortedMajorCities);
        });
        return map;
    }, []);

    /**
     * **Sorted Countries List**
     *
     * Pre-sorts the list of countries alphabetically for the dropdown.
     */
    const sortedCountries = useMemo(() => {
        return _.orderBy(allCountries, ["name"], ["asc"]);
    }, []);

    /**
     * **Handle Country Change**
     *
     * Updates the selected country and resets the city selection.
     * Retrieves and sets the sorted list of cities for the selected country.
     */
    const handleCountryChange = useCallback(
        (countryName) => {
            setSelectedCountry(countryName);
            setSelectedCity("");

            if (!countryName) {
                setCities([]);
                return;
            }

            const lowerCountryName = countryName.toLowerCase();
            const sortedCities = countryLookup.get(lowerCountryName) || [];
            setCities(sortedCities);
        },
        [countryLookup]
    );

    /**
     * **Handle City Change**
     *
     * Updates the selected city and dispatches the selections to Redux.
     */
    const handleCityChange = useCallback(
        (cityName) => {
            setSelectedCity(cityName);

            // Dispatch selections to Redux
            dispatch(setCountryName(selectedCountry || "NA"));
            dispatch(setCityName(cityName || "NA"));
        },
        [dispatch, selectedCountry]
    );

    /**
     * **Initialize on Mount**
     *
     * Sets initial selections based on Redux state or default props.
     * Ensures that cities are appropriately populated based on the initial country.
     */
    useEffect(() => {
        let initCountry =
            preSelectedCountry && preSelectedCountry !== "NA"
                ? preSelectedCountry
                : defaultCountry;
        let initCity =
            preSelectedCity && preSelectedCity !== "NA"
                ? preSelectedCity
                : defaultCity;

        if (initCountry && initCountry !== "NA") {
            const lowerInitCountry = initCountry.toLowerCase();
            const sortedCities = countryLookup.get(lowerInitCountry) || [];

            if (sortedCities.length > 0) {
                setCities(sortedCities);

                const matchedCity = sortedCities.find(
                    (city) => city.name.toLowerCase() === initCity.toLowerCase()
                );
                setSelectedCity(matchedCity ? matchedCity.name : initCity);
                setSelectedCountry(
                    allCountries.find(
                        (c) =>
                            c.name.toLowerCase() === lowerInitCountry ||
                            c.shortCode?.toLowerCase() === lowerInitCountry ||
                            c.shortCode2?.toLowerCase() === lowerInitCountry
                    )?.name || initCountry
                );
            } else {
                setCities([]);
                setSelectedCity(initCity);
                setSelectedCountry(initCountry);
            }
        } else if (initCity && initCity !== "NA") {
            // Find the country that contains the initial city
            const countryWithCity = allCountries.find((country) =>
                country.majorCities?.some(
                    (city) => city.name.toLowerCase() === initCity.toLowerCase()
                )
            );

            if (countryWithCity) {
                const lowerCountryName = countryWithCity.name.toLowerCase();
                const sortedCities = countryLookup.get(lowerCountryName) || [];
                setCities(sortedCities);

                const matchedCity = sortedCities.find(
                    (city) => city.name.toLowerCase() === initCity.toLowerCase()
                );
                setSelectedCity(matchedCity ? matchedCity.name : initCity);
                setSelectedCountry(countryWithCity.name);
            } else {
                setCities([]);
                setSelectedCity(initCity);
                setSelectedCountry("");
            }
        }
    }, [
        preSelectedCountry,
        preSelectedCity,
        defaultCountry,
        defaultCity,
        countryLookup,
    ]);

    /**
     * **Prayer Links Configuration**
     *
     * Configures prayer links based on the selected city and country.
     */
    const prayers = useMemo(() => ["Fajr", "Zuhr", "Asr", "Maghrib", "Isha"], []);
    const basePath = useMemo(() => {
        return window.location.pathname.includes("salah-times")
            ? "salah-times"
            : "namaz-times";
    }, []);
    const citySlug = useMemo(
        () => (selectedCity || "").replace(/\s+/g, "-").toLowerCase(),
        [selectedCity]
    );
    const rawCountry = useMemo(() => {
        return locationData?.country?findCountryCode(locationData.country).toLowerCase() : "";
    }, [locationData]);

    return (
        <div className="flex flex-col justify-center items-center w-full p-5">
            {/* Prayer Links */}
            <div className="flex gap-2 mb-4">
                {!pathType.includes("timetable") && (
                    <>
                        {prayers.map((prayer) => {
                            const prayerSlug = prayer.toLowerCase();
                            const linkPath =
                                !selectedCity || selectedCity === "NA"
                                    ? "#"
                                    : `/${basePath}${rawCountry ? "/" + rawCountry : ""}/${prayerSlug}-time-${citySlug}`;

                            const isDisabled = !selectedCity || selectedCity === "NA";

                            return (
                                <a
                                    key={prayer}
                                    href={linkPath}
                                    className={
                                        isDisabled
                                            ? "bg-gray-300 text-white px-3 py-1 rounded cursor-not-allowed"
                                            : "bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
                                    }
                                    onClick={(e) => {
                                        if (isDisabled) {
                                            e.preventDefault();
                                        }
                                    }}
                                >
                                    {prayer}
                                </a>
                            );
                        })}
                    </>
                )}
            </div>

            {/* Country and City Selectors */}
            <div className="w-full max-w-4xl">
                <div className="flex flex-row gap-4">
                    {/* Country Selector */}
                    <select
                        className="w-full p-2 border rounded border-gray-300"
                        value={selectedCountry}
                        onChange={(e) => handleCountryChange(e.target.value)}
                    >
                        <option value="">Select Country</option>
                        {sortedCountries.map((c) => (
                            <option key={c.shortCode || c.name} value={c.name}>
                                {c.name}
                            </option>
                        ))}
                    </select>

                    {/* City Selector */}
                    <select
                        className="w-full p-2 border rounded border-gray-300"
                        value={selectedCity}
                        onChange={(e) => handleCityChange(e.target.value)}
                        disabled={!selectedCountry || selectedCountry === "NA"}
                    >
                        <option value="">Select City</option>
                        {cities.map((city) => (
                            <option key={city.name} value={city.name}>
                                {city.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

// **Memoize the Component to Prevent Unnecessary Re-renders**
export default React.memo(CountriesAndCities);
