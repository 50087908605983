import React from 'react';
import ReactDOM from 'react-dom/client';
import 'keen-slider/keen-slider.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import allReducers from './store';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import './utils/i18n';

const store = configureStore({
    reducer: allReducers,
});

async function fetchCmsRoutes() {
    try {
        const response = await fetch('https://matwproject.org/backend/index.php/api/routes?route_type=builder');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        localStorage.setItem('storedCmsRoutes', JSON.stringify(data));
        localStorage.setItem('storedTimestamp', Date.now().toString());
    } catch (error) {
        console.error('Failed to fetch builder routes:', error);
    }
}

function initializeApp() {
    fetchCmsRoutes().then(() => {
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
                <ToastContainer position="bottom-right" bodyClassName={'text-sm font-roboto'} />
            </Provider>
        );
    });
}

initializeApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
