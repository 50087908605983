import React from "react";
import { useKeenSlider } from "keen-slider/react";
import { LiaAngleLeftSolid, LiaAngleRightSolid } from "react-icons/lia";
import SelectOneProductsBox from "../../../components/general/selectOneProductsBox";
import ProductBox from "../../../components/general/productBox";
import { useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../../store/user";
import { ProductBoxCart } from "../../home/components/productBoxCart";
import useCart from "../../../hooks/cart/useCart";

const AqiqahTabContent = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    items,
    hasCustomPrice,
    showArrows = true,
    title,
    description,
  } = props;
  const selectedCurrency = useSelector(selectSelectedCurrency);
  const { handleCheckProduct, handleAddToGiving, handleRemoveProduct } =
    useCart();
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="mb-4 md:mb-7 flex justify-center items-center flex-col gap-4 md:gap-6">
        {title && (
          <div className="flex justify-center items-center mx-auto">
            <p className="bg-[#14a2dc] text-[#fff] text-[14px] text-center rounded-none p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase">
              {title}
            </p>
          </div>
        )}
        {description && (
          <div className="flex w-full justify-center">
            <p className="text-center text-[#14a2dc] text-[18px] md:text-3xl md:w-2/3">
              {description}
            </p>
          </div>
        )}
      </div>
      {items.map((item, index) => {
        return (
          <div className="w-full sm:max-w-[390px] mx-auto">
            {item.products.length > 1 ? (
              <SelectOneProductsBox
                hasCustomPrice={hasCustomPrice}
                title={item.title}
                description={item.description}
                image={item.image}
                products={item.products}
              />
            ) : (
              <>
                <div className="hidden sm:block">
                  <ProductBox
                    product={item.products[0]}
                    currency={selectedCurrency ? selectedCurrency : "AUD"}
                  />
                </div>
                <div className="sm:hidden">
                  <ProductBoxCart
                    key={index}
                    product={item.products[0]}
                    onCheck={handleCheckProduct}
                    onAdd={handleAddToGiving}
                    onRemove={handleRemoveProduct}
                    currency={selectedCurrency ? selectedCurrency : "AUD"}
                  />
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};
export default AqiqahTabContent;
