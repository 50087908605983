import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import { closeSheet, selectSheetVisibility } from '../../../store/sheetToggler';
import { generateLinkWithLang } from '../../../utils/linkGenerator';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectSelectedCurrency } from '../../../store/user';
// import { setCartLength, setSelectWidgetProducts } from '../../../store/widgetCart';
import { ProductBoxCartSheet } from '../../../components/general/productBoxCartSheet';
import CustomButton from '../../../components/general/button';
import { Sheet } from '../../../components/ui/sheet';
import getSymbolFromCurrency from 'currency-symbol-map';
import { IoReturnUpBackOutline } from 'react-icons/io5';
import { Elements } from '@stripe/react-stripe-js';
import OneStepCheckout from '../../../components/steps/one-step-checkout';
import { loadStripe } from '@stripe/stripe-js';
const _stripe = loadStripe('pk_live_51O25i3EpTLP297fAAuxdDPYNX04EtyEqgVKa0Wxl7bdAG1elEa0Gr6kbOKsw1ntgCUX4bkSWKv7m5cF4GZ6POGM600TRvCpUVI');
// import useMouseTracker from '../../../components/ui/useMouseTracker';
// import { TfiClose } from 'react-icons/tfi';

export const CartSheet = ({ selectedProducts, onAddQty, onReduceQty, onUpdateQty, onRemove }) => {
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const dispatch = useDispatch();
  // const isVisible = useSelector(selectSheetVisibility);

  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const { products, total } = selectedProducts;

  const ShowCurrencies2 = (currency, _price) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = '';
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        _final = `${price}`;
        break;
      case 'IDR':
        _final = `Rp${price}`;
        break;
      case 'AED':
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };

  // Mouse Tracking Vars
  // const hideAreaRef = useRef(null);
  // const { position, isHidden } = useMouseTracker(hideAreaRef);
  // const showClose = !isHidden && isVisible;

  return (
    <>
      {/* {showClose && (
        <div
          className={`z-[9999] pointer-events-none h-7 w-7 fixed -translate-x-1/2 -translate-y-1/2 text-white ${
            position.y <= 12 ? 'opacity-0' : 'opacity-100'
          }`}
          style={{
            top: position.y,
            left: position.x,
          }}
        >
          <TfiClose className=" h-7 w-7 font-thin opacity-70" />
        </div>
      )} */}

      <Sheet
      // refTo={hideAreaRef}
      >
        <div className={`h-full flex flex-col`}>
          <div className="px-4 pt-3 md:pt-6 bg-[#0286c3]">
            <div className="flex justify-between gap-6 items-center">
              <div className="text-white cursor-pointer">
                <CustomButton onClick={() => dispatch(closeSheet())} 
                title="add more items"
                className="!text-sm !h-[32px] !font-signika !rounded-none"
                />
              </div>
              <div className="text-white font-light cursor-pointer h-[24px] w-[24px] rounded-full flex justify-center items-center">
                <FaTimes onClick={() => dispatch(closeSheet())} className="font-extrabold text-2xl" aria-hidden="true" />
              </div>
            </div>
            <div className="border-b border-[#00afff80] py-6 mb-6 md:mb-8">
              <p className="font-signika uppercase text-xl font-bold tracking-[2px] text-white md:max-w-[221px]">My Donations</p>
            </div>
          </div>
          <div className="px-4 md:px-10 bg-[#0286c3]">
            {products.length > 0 ? (
              <ProductBoxCartSheet
                selectedProducts={selectedProducts}
                onAddQty={onAddQty}
                onReduceQty={onReduceQty}
                onUpdateQty={onUpdateQty}
                onRemove={onRemove}
              />
            ) : (
              <p className="font-signika text-xl text-white font-semibold text-center">No Items found</p>
            )}
          </div>
          <div className="px-4 md:px-10 bg-[#0286c3] pb-4 md:pb-10 mt-auto">
            {products.length > 0 && (
              <div className="flex flex-col gap-2 md:gap-4 mt-auto">
                <div className="mt-6 md:mt-8 border-b border-[#00afff80] pb-4 mb-4 md:pb-6 md:mb-6">
                  <div className="flex items-center gap-4 justify-between">
                    <p className="font-signika text-3xl md:text-4xl font-bold text-white">Total</p>
                    <p className="font-signika text-2xl md:text-4xl font-bold text-white self-end">
                     {selectedCurrencyStore === "MYR" || selectedCurrencyStore === "SGD" ? <span className="text-sm ml-0.5">{selectedCurrencyStore}</span> : ""} {ShowCurrencies2(selectedCurrencyStore, total, false)}
                    </p>
                  </div>
                </div>
                {/* <Elements stripe={_stripe}>
                  <OneStepCheckout />
                </Elements> */}
                <div className="bg-[#0286c3] flex gap-3 group">
                  <CustomButton
                    onClick={() => dispatch(closeSheet())}
                    title="Back"
                    icoLeft={<IoReturnUpBackOutline className="mr-2" />}
                    className="!h-[52px] md:!h-[56px] !font-signika uppercase !w-full font-bold checkout-btn-bg !text-[20px] md:!text-2xl flex-1 !bg-[#00a4da3f] hover:!bg-[#00a4da6e] !rounded-none"
                  />
                  <CustomButton
                    onClick={() => {
                      navigate(generateLinkWithLang('/checkout', lang));
                      dispatch(closeSheet());
                    }}
                    title="Checkout"
                    className="!h-[52px] md:!h-[56px] !font-signika uppercase !w-full font-bold checkout-btn-bg !text-[20px] md:!text-2xl flex-grow !rounded-none"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Sheet>
    </>
  );
};
