import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-new';
import Skeleton from 'react-loading-skeleton';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import services from '../../services';
import SeoData from '../../utils/seo.json';
// import ChangeDocumentTitle from '../../utils/changeDocumentTitle';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';
import ProductBox from '../../components/general/productBox';
import { selectProducts } from '../../store/products';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
// import banner from '../../../public/images/landings/donate-now/donate-now.webp';

const MakeADonationLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const [makeDonationCategory, setMakeDonationCategory] = useState();
  const [makeDonationProducts, setMakeDonationProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const meta = {
    parent: 'donateNow',
    child: 'makeADonation',
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const products = useSelector(state => selectProducts(state));
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempMakeDonationProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-01') tempMakeDonationProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-05') tempMakeDonationProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-06') tempMakeDonationProducts[2] = inner_item_product;
          if (inner_item_product.creator === 'EMR-WNT-PAK-24-01') tempMakeDonationProducts[3] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-01') tempMakeDonationProducts[4] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-02') tempMakeDonationProducts[5] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-03') tempMakeDonationProducts[6] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-04') tempMakeDonationProducts[7] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-05') tempMakeDonationProducts[8] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-06') tempMakeDonationProducts[9] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-07') tempMakeDonationProducts[10] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-08') tempMakeDonationProducts[11] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-09') tempMakeDonationProducts[12] = inner_item_product;
          if (inner_item_product.creator === 'MD-AA-2024-10') tempMakeDonationProducts[13] = inner_item_product;
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempMakeDonationProducts = tempMakeDonationProducts.filter(n => n);
    setMakeDonationProducts(tempMakeDonationProducts);
  };
  const getMakeDonationCategory = async () => {
    try {
      let response = await services.categoryProductsByName('make a donation');
      setMakeDonationCategory(response.data);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleParagraph = text => {
    let tempText = [];
    if (text) {
      tempText = text.split('.');
      return tempText.map((txt, index) => {
        let temp =
          txt === '' ? (
            ''
          ) : (
            <p key={`p${index}`}>
              {txt}.<br />
            </p>
          );
        return temp;
      });
    }
  };
  const RawHTML = ({ children, className = '' }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '') }} />;
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    window.scroll(0, 0);
    getMakeDonationCategory();
  }, []);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <NewHelmetLayout>
      {seo && seo.hasOwnProperty(meta?.parent) && seo[meta?.parent].hasOwnProperty(meta?.child) && (
        <Helmet>
          <title>{`${seo[meta.parent][meta.child]['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={`${seo[meta.parent][meta.child]['description']}`} />
        </Helmet>
      )}
      <img
        src={'/images/landings/donate-now/donate-now.webp'}
        alt={'Make a donation'}
        className="w-full"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/images/general/logo-replace.png';
          currentTarget.className = 'max-h-[150px] mx-auto';
        }}
      />
      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />
      <div className="md:container md:mx-auto mt-5">
        <div className="grid grid-cols-12 px-3- md:px-4-">
          <div className="col-span-12 text-center text-6xl text-[#253b7e] flex justify-center items-center md:py-8- font-['Gotcha'] h-[125px]">
            Make A Donation
          </div>
          <div className="col-start-1 col-span-12 rounded-md">
            <div className="flex flex-row justify-center flex-wrap">
              {makeDonationProducts.map((item, index) => {
                return (
                  <div key={`products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                    <ProductBox product={item} currency={selectedCurrency} />
                  </div>
                );
              })}
            </div>
            <section className='mt-8 md:mt-12 lg:mt-16 py-8 md:py-12 lg:py-16 bg-white px-6 md:px-8 rounded-xl'>
              <div className="flex justify-center">
                <h1 className="md:text-center font-montserratBold text-[#093686] leading-[30px] sm:leading-[60px] text-2xl md:text-[48px] w-full sm:w-10/12 mb-8 md:mb-12 lg:mb-16">
                  Every Small Act Can
                  <span className="text-[#F60362]"> Change a Life</span>
                </h1>
              </div>
              <div className="space-y-7 md:space-y-10">
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                  Imagine waking up every morning not knowing where your next meal will come from, or if you’ll have a roof over your head tonight. For millions of people around the world, 
                  this uncertainty is their daily reality. But you have the power to change that.
                </p>
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                  The MATW Project was born out of one man’s dream to leave a legacy of hope and compassion. Ali Banat, may Allah have mercy on him, transformed his diagnosis of terminal 
                  illness into a mission to uplift those in need. Today, we continue his vision, providing food, water, shelter, education, and opportunities to some of the most vulnerable communities globally.
                </p>
                <div className='flex flex-col gap-2'>
                  <span className="text-xl font-montserratBold text-[#253B7E]">
                    A Chance to Make a Difference
                  </span>
                  <p className="text-base md:text-xl font-montserratMedium text-[#78716C] mt-2">
                    When you give to any of the appeals above, you’re not just donating—you’re transforming lives. You’re helping to feed hungry families, provide clean water to communities, 
                    educate children, and build homes for those without shelter. You’re empowering orphans, widows, and entire communities to rise from hardship and rebuild their futures.
                  </p>
                </div>
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C] mt-2">
                  Your support goes beyond material aid—it brings dignity, hope, and a brighter future to those who have lost everything.
                </p>
                <div className='flex flex-col gap-2'>
                  <span className="text-xl font-montserratBold text-[#253B7E]">
                    The Reward of Giving
                  </span>
                  <p className="text-base md:text-xl font-montserratMedium text-[#78716C] mt-2">
                    As Muslims, we know the immense blessings of Sadaqah. Every act of charity you perform leaves a legacy of goodness that echoes in this world and the Hereafter. The Prophet Muhammad (peace be upon him) said, 
                    “The believer’s shade on the Day of Resurrection will be their charity.”
                  </p>
                </div>
                <div className='flex flex-col gap-2'>
                  <span className="text-xl font-montserratBold text-[#253B7E]">
                    Why Now?
                  </span>
                  <p className="text-base md:text-xl font-montserratMedium text-[#78716C] mt-2">
                    Right now, someone is praying for the very thing you can give. Whether it’s food to ease their hunger, water to quench their thirst, or shelter to protect their family, your contribution can be the answer to their prayer. 
                    Together, we can show the world the beauty of compassion and faith in action.
                  </p>
                </div>
                <div className='flex flex-col gap-2'>
                  <span className="text-xl font-montserratBold text-[#253B7E]">
                    Join the Legacy of Ali Banat
                  </span>
                  <p className="text-base md:text-xl font-montserratMedium text-[#78716C] mt-2">
                    Ali’s legacy reminds us that our time in this world is short, but the impact we leave behind can be eternal. Choose one of the appeals above to make a difference. Be the reason someone smiles today. 
                    Be the change this world so desperately needs.
                  </p>
                </div>
                <div className='flex flex-col gap-2'>
                  <span className="text-xl font-montserratBold text-[#253B7E]">
                    Your kindness matters. Your generosity heals. Let’s change the world together, one donation at a time.
                  </span>
                </div>
              </div>
            </section>
            <div className="col-span-12 rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
              <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                <iframe
                  className="video"
                  width={'100%'}
                  height={'100%'}
                  title={'youtube'}
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://youtube.com/embed/tC_L9aTE8eI?autoplay=0`}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NewHelmetLayout>
  );
};

export default MakeADonationLandingPage;