import React from 'react';
import IftarDonationWidget from './IftarDonationWidget';

const RamadanTabContent = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    topProducts = [],
    bottomProducts = [],
    cardTitle = '',
  } = props;
  /* -------------------------------------------------------------------------- */
  return (
    <div className="flex w-full flex-wrap justify-center items-start gap-2 md:gap-4 md:h-[500px]">
      <IftarDonationWidget
        topList={topProducts}
        bottomList={bottomProducts}
        cardTitle={cardTitle}
      />
    </div>
  );
};
export default RamadanTabContent;
