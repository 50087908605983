import { Builder } from '@builder.io/react';
import React from 'react';
import Carousel from "../../../../../components/Carousel";
const AppealCarouselSection = props => {
    /* ----------------------------- Start variables ---------------------------- */
    const {
        banners = []
        // ...other props if needed
    } = props;
    // const [banners, setBanners] = useState([])
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    // const handleClick = () => {
    //   console.log('clicked');
    // };

    // const getBanners = async () => {
    //   try {
    //     const response = await services.getBanners();
    //     let temp = response.data.sort((a, b) => a.order - b.order);
    //     let temp2 = [];
    //     temp.map(item => {
    //       if (item.status !== 0 && !item.is_page_banner) {
    //         temp2.push(item);
    //       }
    //       return item;
    //     });
    //     setBanners(temp2);
    //   } catch (error) {
    //     console.error('Error fetching banners:', error);
    //   }
    // };
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    // useEffect(() => {
    //   getBanners();
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    /* -------------------------------------------------------------------------- */

    return <div className="w-full max-h-[150px] md:max-h-[950px] overflow-hidden relative">
        {banners.length ? <Carousel withArrow={false} autoSwitch={true} floatDots className="h-full [&>div]:h-full" data={banners} renderItem={({
            item,
            index,
            currentSlide
        }) => <div className="h-full" key={`carousel${index}`}>
                <div className="w-full h-full transition-all transform overflow-hidden">
                    <img className="w-full h-full object-center cursor-pointer object-cover" alt={item.alt} src={item.image} />
                </div>
            </div>} keyExtractor={item => item.image} /> : null}
    </div>;
};
Builder.registerComponent(AppealCarouselSection, {
    name: 'AppealCarouselSection',
    inputs: [
        {
            name: 'banners',
            type: 'list',
            defaultValue: [
                {
                    id: 79,
                    name: "Campagne d'Urgence Hivernale 4",
                    section: 'page_builder',
                    alt: "Campagne d'Urgence Hivernale 4",
                    link: '#',
                    image: 'https://cdn.matwproject.org/images/Winter-Landing-Page-Image-4.5c88eeaf1d0d0ca4f1a5.jpg',
                    mobile_image: null
                }
            ],
            subFields: [
                {
                    name: 'id',
                    type: 'number',
                    required: true,
                    defaultValue: 0,
                    description: 'Unique identifier for the banner'
                },
                {
                    name: 'name',
                    type: 'string',
                    required: true,
                    defaultValue: '',
                    description: 'Name of the banner'
                },
                {
                    name: 'section',
                    type: 'string',
                    required: true,
                    defaultValue: 'page_builder',
                    description: 'Section where the banner is used'
                },
                {
                    name: 'alt',
                    type: 'string',
                    required: true,
                    defaultValue: '',
                    description: 'Alt text for the banner image'
                },
                {
                    name: 'link',
                    type: 'string',
                    required: false,
                    defaultValue: '#',
                    description: 'URL the banner links to'
                },
                {
                    name: 'image',
                    type: 'file',
                    required: true,
                    allowedFileTypes: ['png', 'jpg', 'jpeg'],
                    description: 'Main image for the banner'
                },
                {
                    name: 'mobile_image',
                    type: 'file',
                    required: false,
                    allowedFileTypes: ['png', 'jpg', 'jpeg'],
                    description: 'Image for mobile view (optional)'
                }
            ]
        }
    ]
});
export default AppealCarouselSection;