import { Builder } from '@builder.io/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import RamadanTabContent from "../../../../../general/ramadanTabs/tab-content";
import services from "../../../../../../services";
import { selectProducts } from '../../../../../../store/products';
import { useSelector } from 'react-redux';
const RamadanTabsWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    tabs
  } = props;
  const contentRef = useRef(null);
  // const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  /* -------------------------------------------------------------------------- */
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const [tabTopProducts, setTabTopProducts] = useState([]);
  const [tabBottomeProducts, setTabBottomeProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const _allProducts = useSelector(state => selectProducts(state));
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  const getAllProducts = _allProducts => {
    setIsLoaded(true);
    let tempSelectedTopProducts = [];
    let tempSelectedBottomProducts = [];
    // Initialize tempSelectedProducts with an empty products array for each tab
    tabs.forEach(() => {
      tempSelectedTopProducts.push({
        products: []
      });
      tempSelectedBottomProducts.push({
        products: []
      });
    });
    _allProducts.forEach(item => {
      item.sub_categories.forEach(innerItem => {
        innerItem.products.forEach(innerProduct => {
          tabs.map((tabItem, tabIndex) => {
            if (tabItem.topProducts && tabItem.topProducts.includes(innerProduct.creator)) {
              tempSelectedTopProducts[tabIndex].products.push(innerProduct);
            }
            if (tabItem.bottomProducts && tabItem.bottomProducts.includes(innerProduct.creator)) {
              tempSelectedBottomProducts[tabIndex].products.push(innerProduct);
            }
          });
        });
      });
    });
    setTabTopProducts(tempSelectedTopProducts);
    setTabBottomeProducts(tempSelectedBottomProducts);
  };
  useEffect(() => {
    if (tabs.length > 0) {
      _allProducts && Object.keys(_allProducts).length && getAllProducts(_allProducts);
    }
  }, [_allProducts, tabs]);
  /* -------------------------------------------------------------------------- */
  return <section className="bg-[#253B7E]">
    <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
      <div className=" flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
        {tabs.map(({
          title
        }, index) => <button key={`btn_${index}`} onClick={() => {
          // window.scrollTo({
          //   behavior: 'smooth',
          //   top: contentRef.current.offsetTop - 200
          // });
          setActiveTabProducts(index);
        }} className={`${index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'} text-[14px] md:text-base- rounded-full max-[500px]:col-span-2- p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase font-['Montserrat'] font-bold`}>
            {title}
          </button>)}
      </div>
      <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
        <div className="flex flex-col w-full justify-center">
          {tabs.map((item, index) => index === activeTabProducts && <React.Fragment key={`description_${index}`}>
            <div className="flex w-full justify-center mb-6">
              <p className="text-center text-white text-[18px] md:text-3xl md:w-2/3">{item.description}</p>
            </div>
            <div ref={contentRef}>
              <RamadanTabContent topProducts={tabTopProducts[index] ? tabTopProducts[index].products : []} bottomProducts={tabBottomeProducts[index] ? tabBottomeProducts[index].products : []} cardTitle={tabs[index] ? tabs[index].cardTitle : ''} isLoaded={isLoaded} />
            </div>
          </React.Fragment>)}
        </div>
      </div>
    </div>
  </section>;
};
Builder.registerComponent(RamadanTabsWidget, {
  name: 'RamadanTabsWidget',
  inputs: [
    {
      name: 'tabs',
      type: 'list', // Defines the input as a list (array)
      defaultValue: [], // Initializes with an empty array
      minRows: 0,       // Optional: Minimum number of items
      maxRows: 6,     // Optional: Maximum number of items
      defaultItem: { title: '', description: '', cardTitle: '', topProducts: [], bottomProducts: [] }, // Defines the structure of each item
      subFields: [
        {
          name: 'title',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Tab Title.', // Provides guidance to users
        },
        {
          name: 'description',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Description.', // Provides guidance to users
        },
        {
          name: 'cardTitle',
          type: 'string', // Each item is a string
          required: false, // Makes this field mandatory
          helpText: 'Enter the Card Title.', // Provides guidance to users
        },
        {
          name: 'topProducts',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Top Products.', // Provides guidance to users
        },
        {
          name: 'bottomProducts',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Bottom Products.', // Provides guidance to users
        },
      ],
    },
  ]
});
export default RamadanTabsWidget;