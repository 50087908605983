import React, {useEffect, useState} from "react";
import {
    calculateTimeLeft,
    convertTo12Hour,
    formatTimeLeft,
    getDefaultPrayerTimes, getLatLngFromApi, getPrayersDataUsingLatLng
} from "./utils";
import {Helmet} from "react-helmet-new";

import NewHelmetLayout from "../../components/general/newHelmetLayout";
import {useNavigate} from "react-router-dom";

const NamazTime = ({locationData, prayerTimes, city, prayerName}) => {
    const navigate = useNavigate();
    const [prayerTimeByName, setPrayerTimeByName] = useState("00:00");
    const [timeLeftSunrise, setTimeLeftSunrise] = useState("");
    const [calcMethodData, setCalcMethodData] = useState({
        prayerTimeData: [],
        prayerTimeCalculationMethod: "UISK",
    });
    const [updatedPrayerTimeByMethod, setUpdatedPrayerTimeByMethod] = useState("00:00");

    // Format country name
    const formatCountryName = (name) => {
        if (!name) return "";
        if (name.length === 2) return name.toUpperCase();
        return name
            .replace(/-/g, " ")
            .replace(/\b\w/g, (c) => c.toUpperCase());
    };

    // Switch method
    const handleMethodChange = (selectedMethod) => {
        fetchPrayerTimesByMethod(locationData, selectedMethod);
    };

    const fetchPrayerTimesByMethod = async (_location, methodId) => {
        try {
            const locationResponse = await getLatLngFromApi(_location.country, _location.city);
            
            const response = await getPrayersDataUsingLatLng(locationResponse.data.lat, locationResponse.data.lng, 'times', methodId);

            if (!response.ok) {
                setCalcMethodData({
                    prayerTimeData: getDefaultPrayerTimes(),
                    prayerTimeCalculationMethod: "No method used",
                });
                return;
            }
            const namazData = await response.json();
            if (!namazData?.data?.timings?.Fajr) {
                setCalcMethodData({
                    prayerTimeData: getDefaultPrayerTimes(),
                    prayerTimeCalculationMethod: "No method used",
                });
                return;
            }
            setCalcMethodData({
                prayerTimeData: [
                    {name: "Fajr", time: convertTo12Hour(namazData.data.timings.Fajr)},
                    {name: "Zuhr", time: convertTo12Hour(namazData.data.timings.Dhuhr)},
                    {name: "Asr", time: convertTo12Hour(namazData.data.timings.Asr)},
                    {name: "Maghrib", time: convertTo12Hour(namazData.data.timings.Maghrib)},
                    {name: "Isha", time: convertTo12Hour(namazData.data.timings.Isha)},
                ],
                prayerTimeCalculationMethod: namazData.data.meta?.method?.name || "",
            });
        } catch (err) {
            setCalcMethodData({
                prayerTimeData: getDefaultPrayerTimes(),
                prayerTimeCalculationMethod: "No method used",
            });
        }
    };

    // Set single prayer time from main data
    useEffect(() => {
        if (prayerTimes?.prayerTimeData?.length) {
            const found = prayerTimes.prayerTimeData.find(
                (p) => p.name?.toLowerCase() === prayerName.toLowerCase()
            );
            setPrayerTimeByName(found?.time || "00:00");
        }
        // Also fetch alt methods
        fetchPrayerTimesByMethod(locationData, 1);
    }, [prayerTimes, prayerName, locationData]);

    // Update single prayer time from the alt method data
    useEffect(() => {
        if (!calcMethodData.prayerTimeData?.length) return;
        const found = calcMethodData.prayerTimeData.find(
            (p) => p.name?.toLowerCase() === prayerName.toLowerCase()
        );
        setUpdatedPrayerTimeByMethod(found?.time || "00:00");
    }, [calcMethodData, prayerName]);

    // If Fajr, show countdown to Sunrise
    useEffect(() => {
        if (!prayerTimes?.prayerTimeData?.length) return;

        const sunriseTimer = () => {
            const sunriseObj = prayerTimes.prayerTimeData.find(
                (p) => p.name.toLowerCase() === "sunrise"
            );
            if (!sunriseObj) return;
            const diff = calculateTimeLeft(sunriseObj, prayerTimes?.prayerTimeTimezone);
            setTimeLeftSunrise(formatTimeLeft(diff));
        };

        if (prayerName.toLowerCase().includes("fajr")) {
            sunriseTimer();
            const interval = setInterval(sunriseTimer, 1000);
            return () => clearInterval(interval);
        }
    }, [prayerName, prayerTimes?.prayerTimeData, prayerTimes?.prayerTimeTimezone]);

    // Build base path, e.g. "namaz-times" or "salah-times"
    const basePath = window.location.pathname.includes("salah-times")
        ? "salah-times"
        : "namaz-times";

    // Slugify the country and city
    const rawCountry = locationData?.country
        ?.toLowerCase()
        .replace(/\s+/g, "-") || "";
    const rawCity = locationData?.city?.toLowerCase().replace(/\s+/g, "-") || "";

    // "All times" link
    let timesHref = `/${basePath}`;
    if (rawCountry && rawCity) {
        timesHref = `/${basePath}/${rawCountry}/${rawCity}`;
    } else if (rawCity) {
        timesHref = `/${basePath}/${rawCity}`;
    }

    const prayers = ["Fajr", "Zuhr", "Asr", "Maghrib", "Isha"];
    const timesLinkName = basePath === "salah-times" ? "Salah Times" : "Namaz Times";

    const formatPrayerName = (name) => {
        if (!name) return "";
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };

    return (
        <NewHelmetLayout className="bg-white" hideFooter showRegularImg={false}>
            <Helmet>
                <title>
                    {`${formatPrayerName(prayerName)} Time in ${
                        locationData?.city
                            ?.replace(/-/g, " ")
                            ?.replace(/\b\w/g, (c) => c.toUpperCase())
                    } ${
                        locationData?.country && !city
                            ? formatCountryName(locationData.country)
                            : ""
                    }`.trim()}
                </title>
                <link
                    rel="canonical"
                    href={`${window.location.protocol}//matwproject.org${window.location.pathname}`}
                />
                <meta
                    name="description"
                    content={`${formatPrayerName(prayerName)} Time in ${
                        locationData?.city
                            ?.replace(/-/g, " ")
                            ?.replace(/\b\w/g, (c) => c.toUpperCase())
                    } ${
                        locationData?.country && !city
                            ? formatCountryName(locationData.country)
                            : ""
                    }`.trim()}
                />
            </Helmet>

            {/* Row of anchor links for each prayer + "all times" link */}
            <div className="flex flex-col items-center pt-5">
                <div className="flex gap-2 mb-4 flex-wrap justify-center">
                    {/* Link for "Namaz Times" or "Salah Times" */}
                    {rawCity ? (
                        <a
                            href={timesHref}
                            className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
                        >
                            {timesLinkName}
                        </a>
                    ) : (
                        <span className="bg-gray-300 text-white px-3 py-1 rounded cursor-not-allowed">
              {timesLinkName}
            </span>
                    )}
                    {
                        prayers.map((p) => {
                            const pSlug = p.toLowerCase();
                            // If we have no city, link is disabled
                            if (!rawCity) {
                                return (
                                    <span
                                        key={p}
                                        className="bg-gray-300 text-white px-3 py-1 rounded cursor-not-allowed"
                                    >
                  {p}
                </span>
                                );
                            }
                            // e.g. /namaz-times/fajr-time-birmingham
                            const prayerLink = `/${basePath}${rawCountry ? "/" + rawCountry : ""}/${pSlug}-time-${rawCity}`;
                            return (
                                <a
                                    key={p}
                                    href={prayerLink}
                                    className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
                                >
                                    {p}
                                </a>
                            );
                        })}
                </div>
            </div>

            <div className="w-full bg-white px-4 md:px-6">
                <div className="text-center space-y-4 md:space-y-6">
                    <h1 className="text-gray-600 text-xl md:text-2xl p-4 md:p-10">
                        {formatPrayerName(prayerName)} Time in{" "}
                        {locationData?.city
                            ?.replace(/-/g, " ")
                            ?.replace(/\b\w/g, (c) => c.toUpperCase())}
                        {locationData?.country && !city
                            ? `, ${formatCountryName(locationData.country)}`
                            : ""}{" "}
                        today is at:
                    </h1>
                    <div className="text-[#F60362] text-3xl md:text-5xl font-bold">
                        {prayerTimeByName}
                    </div>
                </div>

                {/* If it's Fajr, show sunrise countdown */}
                {prayerName.toLowerCase().includes("fajr") && (
                    <div className="text-center mt-6 md:mt-8">
                        <h2 className="text-gray-600 text-lg md:text-xl">
                            Time left till Sunrise in{" "}
                            {locationData?.city
                                ?.replace(/-/g, " ")
                                .replace(/\b\w/g, (c) => c.toUpperCase())}
                            :
                        </h2>
                        <div className="text-[#F60362] text-xl md:text-2xl font-bold mt-2">
                            {timeLeftSunrise}
                        </div>
                    </div>
                )}

                <div className="bg-[#F60362] text-white py-6 md:py-8 px-4 md:px-6 mt-6 md:mt-8 text-center rounded">
                    <h3 className="text-lg md:text-xl font-bold">
                        Start your day off right, pray your {formatPrayerName(prayerName)} Prayer
                    </h3>
                    <p className="mt-2 text-sm md:text-base">
                        Give a small amount to charity with our 100% donation policy and seek
                        your reward with Allah swt.
                    </p>
                    <button
                        onClick={() => navigate("/top-10")}
                        className="bg-white text-[#F60362] font-bold px-6 md:px-8 py-2 mt-4 rounded hover:bg-gray-100 transition-colors text-sm md:text-base"
                    >
                        Donate
                    </button>
                </div>

                <div className="mt-6 md:mt-8">
                    <div className="flex flex-col md:flex-row justify-center gap-2 md:gap-4">
                        {[{id: 1, text: "(UISK)"}, {id: 2, text: "(ISNA)"}, {id: 3, text: "(MWL)"}, {
                            id: 4,
                            text: "(UIS)"
                        }].map(
                            (method) => (
                                <button
                                    key={method.id}
                                    onClick={() => handleMethodChange(method.id)}
                                    className="bg-[#F60362] text-white px-4 md:px-8 py-2 rounded-md text-sm md:text-base whitespace-normal md:whitespace-nowrap hover:bg-[#d1034f] transition-colors"
                                >
                                    {method.text}
                                </button>
                            )
                        )}
                    </div>
                </div>

                <div className="text-center mt-8 md:mt-12">
                    <h2 className="text-gray-600 text-lg md:text-xl px-2">
                        {formatPrayerName(prayerName)} Time in{" "}
                        {locationData?.city
                            ?.replace(/-/g, " ")
                            ?.replace(/\b\w/g, (c) => c.toUpperCase())}{" "}
                        according to {calcMethodData.prayerTimeCalculationMethod}:
                    </h2>
                    <div className="text-[#F60362] text-3xl md:text-5xl font-bold mt-4">
                        {updatedPrayerTimeByMethod}
                    </div>
                </div>

                <div className="bg-blue-600 text-white p-4 md:p-6 mt-6 md:mt-8 rounded text-center">
                    <p className="text-sm md:text-base">
                        Fajr time is calculated based on the angle of the sun below the
                        horizon, with different calculation methods using varying sun angles.
                        There are multiple methods used globally, such as those by Islamic
                        organizations and observatories, but the distinction between Hanafi
                        and Shafi schools mainly pertains to Asr prayer timing rather than
                        Fajr.
                    </p>
                </div>
            </div>
        </NewHelmetLayout>
    );
};

export default NamazTime;
