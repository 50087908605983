import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ShowCurrencies from "../../utils/showCurrencies";
import Skeleton from "react-loading-skeleton";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { generateLinkWithLang } from "../../utils/linkGenerator";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openSheet } from "../../store/sheetToggler";
import { selectWidgetProducts } from "../../store/widgetCart";
import { HiShoppingCart } from "react-icons/hi";

const MultiProductsBoxCart = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const {
    title,
    description,
    image,
    currency,
    multiProducts,
    arabic = false,
    redirectUrl,
    alt,
    _lang = "en",
    btnContainerClass,
    imageClass,
    onAdd,
    onCheck,
  } = props;
  const [selectedProduct, setSelectedProduct] = useState();
  const [showMore, setShowMore] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const widgetProducts = useSelector(selectWidgetProducts);
  /* -------------------------------------------------------------------------- */
  const dispatch = useDispatch();
  const [visibleDescriptions, setVisibleDescriptions] = useState({}); // State to track visibility of descriptions

  const toggleDescription = (index) => {
    setVisibleDescriptions((prevState) => {
      const newState = {}; // Reset all descriptions to be hidden
      newState[index] = !prevState[index]; // Toggle the selected description
      return newState;
    });
  };
  /* ------------------------------ Start methods ----------------------------- */
  const handleCurrency = (currency, product) => {
    if (product.quantity) {
      return ShowCurrencies(
        currency,
        product[currency.toLowerCase()] * product.quantity,
        false,
      );
    } else {
      return ShowCurrencies(currency, product[currency.toLowerCase()], false);
    }
  };
  const handleHeight = () => {
    setShowMore(!showMore);
  };
  const handleDetailNavigation = () =>
    redirectUrl
      ? navigate(redirectUrl)
      : navigate(
          generateLinkWithLang(
            `/appeals/${multiProducts[0]?.name.replace(/\s+/g, "-").toLowerCase()}`,
            lang,
          ),
        );

  const handleAddToCart = (product) => {
    console.log("productqoun", product);
    const { quantity } = product;
    onAdd(product, quantity || 1);
    dispatch(openSheet());
  };

  const handleProductCounter = (creator) => {
    const product = widgetProducts?.cartCounter?.find(
      (item) => item.creator === creator,
    );

    if (product) {
      if (product.counter >= 99) {
        return 99;
      } else {
        return product.counter || 0;
      }
    } else {
      return 0;
    }
  };

  const toProperCase = (text) => {
    return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // console.log("multiproduct on multiproducts===", multiProducts,multiProducts[0])
    multiProducts && setSelectedProduct(multiProducts[0]);
    // multiProducts && ref.current.clientHeight > 60 && setIsOverflow(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiProducts]);
  /* -------------------------------------------------------------------------- */
  return (
    <div
      className={`w-full shadow-[0_7px_29px_0px_rgba(100,100,111,0.2)] bg-white`}
    >
      {/* Image Container */}
      <div className="w-full gap-2 sm:gap-4 grid grid-cols-[auto,1fr] ">
        <div
          className={`overflow-hidden tooltip img-hover-zoom w-[72px] sm:w-[108px] aspect-square`}
        >
          {image ? (
            image ? (
              <LazyLoadImage
                alt={alt ? alt : title}
                effect="blur"
                width={`100%`}
                delayTime={500}
                className={`rounded cursor-pointer block ${imageClass ? imageClass : ""}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/images/general/logo-replace.png";
                }}
                src={image}
              />
            ) : (
              <img
                className={`w-full ${imageClass ? imageClass : ""}`}
                src="/images/general/logo-replace.png"
                alt=""
              />
            )
          ) : (
            <Skeleton height={100} width={100} />
          )}
        </div>
        <div className={`flex flex-col justify-between sm:items-baseline gap-1 sm:gap-3 p-2 sm:p-3 ${arabic ? 'pl-2' : 'pl-0'}`}>
          <div
            className={`flex gap-1 flex-col items-center overflow-hidden transition-all duration-300 ${showMore ? "h-auto" : "max-h-[24px]"}`}
          >
            <div className="w-full flex gap-2 items-center">
              <p
                className={`text-[#00a3da] cursor-pointer sm:text-lg font-semibold sm:font-bold ${!showMore ? "line-clamp-1" : ""}`}
              >
                <span className="sm:hidden">
                  {toProperCase(title) || <Skeleton width={70} height={12} count={1} />}
                </span>
                <span className="hidden sm:block">
                  {title || <Skeleton width={200} height={12} count={1} />}
                </span>
              </p>
              <span className="block basis-[18px]">
                <ChevronDownIcon
                  onClick={handleHeight}
                  className={`sm:block w-[18px] h-[18px] text-[#00a3da] transition-all duration-300 cursor-pointer ${
                    showMore ? "transform rotate-180" : ""
                  }`}
                />
              </span>
            </div>
            <p
              className={`text-stone-500 text-sm overflow-hidden transition-all duration-200 ${showMore ? "h-auto mb-2" : "max-h-0 mb-0"}`}
            >
              {description}
            </p>
          </div>
          {/* Button Container */}
          <div className="flex gap-3 items-center w-full justify-end">
            <div className="flex gap-2 items-center">
                <button
                    className={`relative w-full max-w-max cursor-pointer transition-all duration-300 border border-LightBlue flex justify-center items-center gap-2 text-xs sm:text-base h-7 sm:h-10 px-2 sm:px-3 ${
                        selectedProduct && onCheck(selectedProduct) ? 'bg-LightBlue text-white' : 'bg-white text-LightBlue'
                    }`}
                    onClick={() => {
                        handleAddToCart(selectedProduct)
                    }}
                    >
                    Add
                    {handleProductCounter(selectedProduct?.creator) >= 1 ? (
                        <span className={`text-xs absolute top-[-8px] right-[-8px] bg-[#f60362] text-white ${handleProductCounter(selectedProduct?.creator) >= 99 ? "w-5 h-5 !text-[10px]" : "w-4 h-4"} leading-[0px]  rounded-full flex justify-center items-center`}> {handleProductCounter(selectedProduct?.creator) >= 99 ?  <span>99<sup>+</sup></span> : handleProductCounter(selectedProduct?.creator)}</span>
                    ) : ""}
                    <HiShoppingCart />
                </button>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-2 p-2">
        {multiProducts?.map(
          (item, index) =>
            item && (
              <div
                key={`btns_${index}`}
                onClick={(e) => {
                    setSelectedProduct(item);
                    e.stopPropagation(); // Prevent triggering the `setSelectedProduct`
                    toggleDescription(index);
                }}
              >
                <div
                  className={`border border-[#F60362] ${
                    selectedProduct?.name === item.name
                      ? "bg-[#F60362] text-white"
                      : "text-[#F60362] bg-white"
                  } p-1 min-h-[24px] w-full cursor-pointer flex justify-center items-center ${btnContainerClass || ""}`}
                >
                  {item.anyAmount ? (
                    <span
                      className={`text-xs text-center ${arabic || _lang === "ar" ? "font-[AlmariMedium]" : ""}`}
                    >
                      {arabic || _lang === "ar"
                        ? "أي كمية"
                        : _lang === "fr"
                          ? `N'importe quel montant`
                          : "Any Amount"}
                    </span>
                  ) : (
                    <div
                      className={`text-xs text-center [&>div]:inline uppercase flex gap-1 items-center ${arabic || _lang === "ar" ? "font-[AlmariMedium]" : ""}`}
                    >
                      {handleCurrency(currency, item)}{" "}
                      <small className="uppercase">{currency}</small>
                      <span className={`block basis-[18px] text-[#00a3da] ${selectedProduct?.name === item.name ? 'text-[#fff]' : ''}`}>
                        <ChevronDownIcon
                          className={`w-[18px] h-[18px] transition-all duration-300 cursor-pointer  ${
                            visibleDescriptions[index]
                              ? `transform rotate-180`
                              : ""
                          }`}
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ),
        )}
      </div>
      {multiProducts?.map(
        (item, index) =>
          visibleDescriptions[index] && (
            <div className="text-stone-500 text-sm overflow-hidden transition-all duration-200 px-2 pb-2">
              <p>{item.description || ""}</p>
            </div>
          ),
      )}
    </div>
  );
};

export default MultiProductsBoxCart;
