// components/RamadanDailyDonationWidget.jsx
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../../store/user";
import ConvertUSD from "../../../utils/convertUSD";
import { selectProducts } from "../../../store/products";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { calculateDonations } from "../../../utils/calculationUtils";
import { calculateRamadanStartDate } from '../../../utils/ramadanUtils'; // Import the utility function
import DateSelector from "./dateSelector";
import TimeZoneSelector from "./timeZoneSelector";
import ProductSelector from "./productSelector";
import MultiplierSelector from "./multiplierSelector";
import Summary from "./summary";
import DonationCalendar from "./donationCalendar";
import { DateTime } from 'luxon';

const RamadanDailyDonationWidget = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const {
        productsList = []
    } = props;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [productAmount, setProductAmount] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState(selectedCurrencyStore ? selectedCurrencyStore : 'AUD');
    const [allProducts, setAllProducts] = useState([]);

    const products = useSelector(state => selectProducts(state));
    const [selectedProductsList, setSelectedProductsList] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [ramadanStartDate, setRamadanStartDate] = useState(null); // New State
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [selectedProducts, setSelectedProducts] = useState([]);

    // Updated State to track the donation option and selected conditions
    const [donationOption, setDonationOption] = useState('equal'); // 'equal' or 'custom'
    const [selectedConditions, setSelectedConditions] = useState([]); // Array of condition values
    const [multipliers, setMultipliers] = useState({
        friday: 2,
        lastTenNights: 2,
        oddNights: 2,
        laylatulQadr: 2,
    });

    const [donationSchedule, setDonationSchedule] = useState([]);
    const [summary, setSummary] = useState({
        totalAmount: 0,
        totalShares: 0,
        shareValue: 0,
    });
    const [donationDifference, setDonationDifference] = useState(0); // To store the rounding difference

    // New state variable to store number of nights to donate
    const [numberOfNights, setNumberOfNights] = useState(30);
    const [numberOfMultiplierNights, setNumberOfMultiplierNights] = useState(0);
    // Loading and Error States
    const [isLoadingRamadanStart, setIsLoadingRamadanStart] = useState(true);
    const [ramadanStartError, setRamadanStartError] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0); // New state for total amount
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleConvert = (currency, value) => {
        return Math.round(ConvertUSD(currency, value, false))
    }
    const handleSelectedItem = (currency, index) => {
        let tempSelectedPrice = {};
        tempSelectedPrice.index = index;
        tempSelectedPrice.value = suggestedPrices[index][currency.toLowerCase()];
        setSelectedPrice(tempSelectedPrice)
    }
    const handleProductAmount = (currency, value) => {
        setProductAmount(handleConvert(currency, value))
    }

    const suggestedPrices = [
        {
            aud: 100,
            usd: 70,
            cad: 90,
            sgd: 90,
            gbp: 60,
            eur: 60,
            myr: 300,
            aed: 240,
            idr: 1002020,
        },
        {
            aud: 250,
            usd: 160,
            cad: 220,
            sgd: 220,
            gbp: 130,
            eur: 150,
            myr: 760,
            aed: 580,
            idr: 2505050,
        },
        {
            aud: 500,
            usd: 320,
            cad: 430,
            sgd: 440,
            gbp: 260,
            eur: 300,
            myr: 1510,
            aed: 1160,
            idr: 5010090,
        },
    ];
    const [selectedPrice, setSelectedPrice] = useState({
        value: suggestedPrices[0][currentCurrency ? currentCurrency.toLowerCase() : 'aud'],
        index: 0
    });
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    const getAllProducts = (products) => {
        setIsLoaded(true);
        let tempAllProducts = [];
        let tempSelectedProductsList = [];
        let tempAlternativeProductsList = [];
        let tempAlternativeProduct;
        let tempCurrentProduct;
        let alternativeProducts = [
            'OPR-GEN-SUP-2023-01-01',
            'MD-AA-2024-02',
            'MKD-MN-001',
            'MD-AA-2024-07',
            'MKD-MN-001',
            'MKD-GEN-MFA-2023-01-118'
        ];
        let tempPrds = [];
        products.forEach(item => {
            item.sub_categories.forEach(inner_item => {
                inner_item.products.forEach(inner_item_product => {
                    if(inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118' || inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') {
                        tempPrds.push(inner_item_product)
                    }
                    tempAllProducts.push(inner_item_product);
                    // Find the matching product in productsList
                    const matchedProduct = productsList.find(
                        (product) => product.productId === inner_item_product.creator
                    );
                    if (matchedProduct) {
                        tempSelectedProductsList.push(inner_item_product)
                        // If this product is marked as default, set tempCurrentProduct
                        if (matchedProduct.defaultProduct) {
                            tempAlternativeProduct = inner_item_product;
                        }
                    }
                    if (alternativeProducts.includes(inner_item_product.creator)) {
                        tempAlternativeProductsList.push(inner_item_product)
                    }
                });
            });
        });
        console.log("prds=",tempPrds,products,alternativeProducts,tempAlternativeProduct,tempSelectedProductsList)
        let uniqueItems;
        if (tempSelectedProductsList.length) {
            uniqueItems = tempSelectedProductsList.filter((item, index, self) => index === self.findIndex(t => t.creator === item.creator));
        } else {
            uniqueItems = tempAlternativeProductsList.filter((item, index, self) => index === self.findIndex(t => t.creator === item.creator));
        }
        if (!tempCurrentProduct) {
            tempCurrentProduct = uniqueItems[0];
        }
        // setSelectedProductsList(uniqueItems);
        setSelectedProductsList(tempPrds);
        setAllProducts(tempAllProducts);
    };
    useEffect(() => {
        if (products && Object.keys(products).length && productsList.length) {
            getAllProducts(products);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, productsList]);
    useEffect(() => {
        if (selectedCurrencyStore) {
            setCurrentCurrency(selectedCurrencyStore);
            if (selectedPrice) {
                handleSelectedItem(selectedCurrencyStore, selectedPrice.index);
            } else {
                handleProductAmount(selectedCurrencyStore, productAmount)
            }
        } else {
            setCurrentCurrency('AUD')
            handleSelectedItem('AUD', selectedPrice.index)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore]);
    useEffect(() => {
        if (timeZone) {
            // Determine the current Gregorian year based on user's time zone
            const currentYear = DateTime.now().setZone(timeZone).year;
            // Calculate Ramadan start date
            const calculatedRamadanStartDate = calculateRamadanStartDate(currentYear, timeZone);

            if (calculatedRamadanStartDate) {
                console.log("calculatedRamadanStartDate=", calculatedRamadanStartDate)
                setRamadanStartDate(calculatedRamadanStartDate);
                setRamadanStartError(null);
            } else {
                setRamadanStartDate(null);
                setRamadanStartError('Unable to determine Ramadan start date for the selected year and time zone.');
            }
            setIsLoadingRamadanStart(false);
        }
    }, [timeZone]);
    useEffect(() => {
        console.log("use effect=",startDate,selectedProducts.length,ramadanStartDate)
        if (startDate && selectedProducts.length > 0 && ramadanStartDate) { // Ensure ramadanStartDate is available
            try {
                // Define Ramadan start and end dates in timeZone
                const ramadanStart = DateTime.fromISO(ramadanStartDate, { zone: timeZone });
                const ramadanEnd = ramadanStart.plus({ days: 29 }); // 30 nights total

                // Convert Ramadan start and end dates to user's selected timezone
                const ramadanStartInUserTZ = ramadanStart.setZone(timeZone);
                const ramadanEndInUserTZ = ramadanEnd.setZone(timeZone);

                // Convert user's selected start date to Luxon DateTime in selected timezone
                const userStartDate = DateTime.fromISO(startDate, { zone: timeZone }).startOf('day');

                // Calculate the number of nights the user wants to donate
                let nightsToDonate = ramadanEndInUserTZ.diff(userStartDate, 'days').days + 1; // Inclusive of start date

                // Ensure nightsToDonate is between 1 and 30
                nightsToDonate = Math.max(1, Math.min(30, Math.floor(nightsToDonate)));

                setNumberOfNights(nightsToDonate);

                // Adjust the Ramadan period based on user selection
                const adjustedRamadanStart = userStartDate;
                const adjustedRamadanEnd = adjustedRamadanStart.plus({ days: nightsToDonate - 1 });

                // Calculate donations based on adjusted Ramadan dates
                const { donationSchedule: adjustedDonationSchedule, difference } = calculateDonations({
                    startDate: adjustedRamadanStart.toISODate(),
                    timeZone,
                    selectedProducts,
                    donationOption, // Updated parameter
                    selectedConditions, // Updated parameter
                    multipliers,
                    currency: selectedCurrencyStore,
                    numberOfNights: nightsToDonate, // Pass the number of nights
                });
                console.log("use effect==",adjustedDonationSchedule,difference,startDate,selectedProducts.length,ramadanStartDate,adjustedRamadanStart)

                setDonationSchedule(adjustedDonationSchedule);
                setDonationDifference(difference);

                // Calculate exact totalAmount based on selectedProducts and currency
                const exactTotalAmount = selectedProducts.reduce((sum, p) => {
                    const price = p[selectedCurrencyStore.toLowerCase()];
                    const quantity = p.quantity || 0;
                    if (typeof price === 'number') {
                        return sum + price * p.quantity;
                    }
                    return sum;
                }, 0);

                // Rounded total amount
                const totalAmountRounded = Math.round(exactTotalAmount);

                // Calculate totalShares by summing the 'shares' property
                const totalShares = adjustedDonationSchedule.reduce(
                    (sum, day) => sum + (day.shares || 1), // Ensure 'shares' exists
                    0
                );

                // Calculate shareValue
                const shareValue =
                    totalShares > 0 ? totalAmountRounded / totalShares : 0;

                setSummary({ totalAmount: totalAmountRounded, totalShares, shareValue });

                // Calculate the number of multiplier nights
                const multiplierNights = adjustedDonationSchedule.filter(
                    (day) => day.isMultiplierNight
                ).length;

                setNumberOfMultiplierNights(multiplierNights);
            } catch (error) {
                console.error('Error calculating donations:', error);
                // Optionally, set an error state to display to the user
            }
        } else {
            // Reset donation schedule and summary if inputs are incomplete
            setDonationSchedule([]);
            setDonationDifference(0);
            setSummary({ totalAmount: 0, totalShares: 0, shareValue: 0 });
            setNumberOfNights(30);
            setNumberOfMultiplierNights(0);
        }
    }, [startDate, timeZone, selectedProducts, donationOption, selectedConditions, multipliers, selectedCurrencyStore, ramadanStartDate]);

    // Determine the selected strategies based on donationOption and selectedConditions
    const determineSelectedStrategies = () => {
        if (donationOption === 'equal') {
            return [
                {
                    label: 'Equal Division',
                    multiplier: 1,
                    nights: numberOfNights,
                }
            ];
        } else if (donationOption === 'custom') {
            return selectedConditions.map(condition => {
                let label = '';
                let multiplier = multipliers[condition] || 1;
                let applicableNights = 0;

                switch (condition) {
                    case 'friday':
                        label = 'on Jummah (Friday)';
                        // Calculate number of Fridays in the donation period
                        applicableNights = donationSchedule.filter(day => {
                            const date = DateTime.fromISO(day.date, { zone: timeZone });
                            return date.weekday === 5;
                        }).length;
                        break;
                    case 'lastTenNights':
                        label = 'in the last 10 nights';
                        applicableNights = donationSchedule.filter((day, index) => index >= Math.max(0, numberOfNights - 10)).length;
                        break;
                    case 'oddNights':
                        label = 'on odd nights (within last 10 nights)';
                        applicableNights = donationSchedule.filter((day, index) => {
                            const nightNumber = index + 1;
                            const isOdd = nightNumber % 2 !== 0;
                            const isInLastTen = index >= Math.max(0, numberOfNights - 10);
                            return isOdd && isInLastTen;
                        }).length;
                        break;
                    case 'laylatulQadr':
                        label = 'on the 27th night - Laylat-ul-Qadr';
                        applicableNights = donationSchedule.some((day) => day.date === ramadanStartDate && numberOfNights >= 27) ? 1 : 0;
                        break;
                    default:
                        label = '';
                        applicableNights = 0;
                }

                return {
                    label,
                    multiplier,
                    nights: applicableNights,
                };
            }).filter(strategy => strategy.nights > 0); // Exclude strategies with 0 applicable nights
        } else {
            return [];
        }
    };

    const selectedStrategies = determineSelectedStrategies();

    return (
        <div className={`w-full scrollarea min-h-[950px]`}>
            <div className={`w-full py-2 md:py-4 flex flex-wrap items-start`}>
                <div className="basis-full lg:basis-2/3 px-2 md:px-4 flex flex-col gap-4 md:gap-6">
                    <SectionTitle count={1} text={'Choose how you want to distribute the amount'} />
                    <div>
                        <h3 className="text-stone-600 text-lg md:text-xl mb-2">
                            Select the Starting Date of your daily giving
                        </h3>
                        <p className="text-[#78716C] text-sm md:text-base">
                            This will be the day your daily giving will begin from until the end of Ramadan
                        </p>
                    </div>
                    <div className="w-full flex gap-2">
                        <DateSelector
                            startDate={startDate}
                            setStartDate={setStartDate}
                            timeZone={timeZone}
                            ramadanStartDate={ramadanStartDate} // Pass ramadanStartDate
                        />
                        <TimeZoneSelector timeZone={timeZone} setTimeZone={setTimeZone} />
                    </div>
                    <SectionTitle count={2} text={'Choose the projects you want to donate to'} />
                    <MultiplierSelector
                        donationOption={donationOption}
                        setDonationOption={setDonationOption}
                        selectedConditions={selectedConditions}
                        setSelectedConditions={setSelectedConditions}
                        multipliers={multipliers}
                        setMultipliers={setMultipliers}
                        numberOfNights={numberOfNights}
                    />
                    <div className="bg-[#78716C] h-[3px] w-full"></div>
                    <SectionTitle count={3} text={'Get the breakdown of how your donation is distributed'} />
                    <div>
                        <h3 className="text-stone-600 text-lg md:text-xl mb-2">
                        Breaking it down
                        </h3>
                        <p className="text-[#78716C] text-sm md:text-base">
                        Below you’ll find a breakdown of your donations across the month of Ramadan. If you wish to divide your amount by project or cause, you can do this also in the designated project boxes shown below!
                        </p>
                    </div>
                    {/* <p className="text-[#78716C] text-lg md:text-xl font-normal">
                        All of the nights of Ramadan are blessed which is why we’re encouraged to maximise every opportunity for Ibadah. Don’t miss your chance to make the most of this month. Be the vessel of Allah’s Mercy for someone in need and reap endless blessings and rewards of your own Insha’Allah!
                    </p> */}
                    <ProductSelector
                        products={selectedProductsList}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        setTotalAmount={setTotalAmount} // Pass the setter for total amount
                        currency={selectedCurrencyStore}
                    />
                </div>
                <div className="basis-full lg:basis-1/3 px-2 md:px-4 sticky top-28" id="sticky">
                    <Summary
                        selectedProducts={selectedProducts}
                        numberOfMultiplierNights={numberOfMultiplierNights}
                        numberOfNights={numberOfNights}
                        selectedStrategies={selectedStrategies} // Updated prop
                        totalAmount={summary.totalAmount}
                        currency={selectedCurrencyStore}
                    />
                </div>
            </div>
            <div className="w-full mt-4 py-2 md:py-4 px-0 md:px-4" id="calendar">
                <DonationCalendar
                    donationSchedule={donationSchedule}
                    currency={selectedCurrencyStore}
                    // ramadanStartDate={startDate} // Pass Ramadan start date
                    ramadanStartDate={ramadanStartDate} // Pass Ramadan start date
                    timeZone={timeZone} // Pass user's time zone
                />
            </div>
        </div>
    )
};

const SectionTitle = props => {
    const { count = 0, text = '' } = props;
    return <div className="w-full flex items-center border border-[#253B7E] bg-white rounded-full p-2 md:p-4 shadow-md">
        <div className="text-white bg-[#F60362] rounded-full w-10 h-10 md:w-14 md:h-14 min-w-[40px] flex justify-center items-center font-bold text-lg md:text-2xl mr-2 md:mr-4">
            {count}
        </div>
        <p className="text-lg md:text-xl text-[#253B7E] font-bold">
            {text}
        </p>
    </div>
}

export default RamadanDailyDonationWidget;
