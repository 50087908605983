import { Builder } from "@builder.io/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../../../../store/user";
import ReactSelect from "react-select";
import SelectCurrency from "../../../../general/selectCurrency";
import useHandleAddProduct from "../../../../../utils/handleAddProduct";
import { selectProducts } from "../../../../../store/products";
import CustomButton from "../../../../general/button";
import ShowCurrencies from "../../../../../utils/showCurrencies";
import staticImages from "../../../../../constants/staticImages";
import useCart from "../../../../../hooks/cart/useCart";
import { openSheet } from "../../../../../store/sheetToggler";
import PersistentTimerMain from "../../../../general/persistTimerMain";
const styles = {
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "6px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};
const WidgetHomeVerticalLight = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    defaultPrice,
    defaultProduct,
    dropDownProducts = [],
    importantDonation,
    mode = 0, // 0 for 'Single', 1 for 'Monthly' if needed
    addToCart = true,
    showTimer,
    title
  } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector((state) => selectProducts(state));
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [addProduct] = useHandleAddProduct();
  const [currentCurrency, setCurrentCurrency] = useState();
  const [selectedProduct, setSelectedProduct] = useState({
    creator: defaultProduct.creator,
    label: defaultProduct.label,
    value: defaultProduct.value,
  });
  const [selectedPrice, setSelectedPrice] = useState(defaultPrice || 250);
  const [productAmount, setProductAmount] = useState(selectedPrice || 250);
  const [currentProduct, setCurrentProduct] = useState({
    creator: defaultProduct.creator,
    label: defaultProduct.label,
    value: defaultProduct.value,
  });

  const importantDonationTemp = importantDonation || 'Make A Donation';

  // Added for recurring modes
  const suggestedRecurringModes = [
    {
      value: "one-off",
      label: "Single",
    },
    {
      value: "month",
      label: "Monthly",
    },
  ];
  const [selectedRecurring, setSelectedRecurring] = useState(
    mode ? suggestedRecurringModes[mode] : suggestedRecurringModes[0],
  );
  const suggestedPrices = [50, 150, 250, 500, 1000, 2000];
  /* -------------------------------------------------------------------------- */
  const dispatch = useDispatch();
  const { handleAddToGiving: handleAddToGivingCart } = useCart();
  /* ------------------------------ Start methods ----------------------------- */
  const handleCurrency = (currency) => {
    setCurrentCurrency(currency.value);
    // selectedCurrency(currency.value);
  };
  const handleProduct = (value) => {
    allProducts.map(
      (item) => item.id === value.value && setSelectedProduct(item),
    );
    setCurrentProduct(value);
  };
  const handleAddNewProduct = () => {
    let tempProduct = selectedProduct;
    let customAmount = Number(productAmount)
      ? Number(productAmount)
      : Number(selectedPrice)
        ? Number(selectedPrice)
        : 250;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      recurring: selectedRecurring.value,
    });

    if (addToCart) {
      handleAddToGivingCart(tempProduct, "custom");
      dispatch(openSheet());
    } else {
      addProduct({ product: tempProduct, currency: currentCurrency });
    }
  };

  /* ------------------------------- Start hooks ------------------------------ */
  const getAllProducts = (products) => {
    setIsLoaded(true);
    let tempMakeDonationProducts = [];
    let tempProductsList = [];
    let tempImportantProducts = [];
    products.forEach((item) => {
      item.sub_categories.map((inner_item) => {
        inner_item.products.map((inner_item_product) => {
          if (Number(inner_item_product.status) === 1) {
            if (inner_item_product.creator === defaultProduct.creator) {
              console.log('first',inner_item_product)
              setSelectedProduct(inner_item_product);
            }
            if (dropDownProducts.includes(inner_item_product.creator) || importantDonationTemp.includes(inner_item.name)) {
              tempMakeDonationProducts.push(inner_item_product);
              tempProductsList.push({
                value: inner_item_product.id,
                label: inner_item_product.name,
                creator: inner_item_product.creator,
              });
            }
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    
    tempImportantProducts = tempImportantProducts.concat(tempProductsList);
    // Ensure no empty values
    tempImportantProducts = tempImportantProducts.filter((n) => n);

    // Sort so dropDownProducts come first
    tempImportantProducts.sort((a, b) => {
      const aIsDropDown = dropDownProducts.includes(a.creator);
      const bIsDropDown = dropDownProducts.includes(b.creator);
      
      if (aIsDropDown && !bIsDropDown) return -1;
      if (!aIsDropDown && bIsDropDown) return 1;
      return 0;
    });

    // Check if default product exists in the list
    const hasDefaultProduct = tempImportantProducts.some(product => product.creator === defaultProduct.creator);

    if (!hasDefaultProduct) {
      tempImportantProducts.unshift({
        value: defaultProduct.value,
        label: defaultProduct.label,
        creator: defaultProduct.creator,
      });
    }


    const defaultProductIndex = tempImportantProducts.findIndex(product => product.creator === defaultProduct.creator);

    if (defaultProductIndex !== -1) {
      const [defaultProductCreator] = tempImportantProducts.splice(defaultProductIndex, 1); 
      tempImportantProducts.splice(0, 0, defaultProductCreator); 
    }

    setProductsList(tempImportantProducts);
    setAllProducts(tempMakeDonationProducts);
    try {
    } catch (error) {
      console.error("Error fetching selected products:", error);
    }
  };
  useEffect(() => {
    products && Object.keys(products).length && getAllProducts(products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    selectedCurrencyStore
      ? setCurrentCurrency(selectedCurrencyStore)
      : setCurrentCurrency("AUD");
  }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */

  return (
    <div
      className={`w-full bg-[#eaf7ff] px-3 p-6 md:p-6 sm:px-6 flex flex-col gap-[18px] items-center`}
    >
      {/* Donate Now Header */}
      <div className="flex justify-between gap-3 items-center w-full">
        <div className="flex flex-col">
          <h2
            className={`text-[#00a3da] text-[28px] sm:text-[30px] font-bold`}
          >
            {title ? title : "Donate Urgently"}
          </h2>
          {showTimer && <PersistentTimerMain styleMain="!text-[#312e2b] !font-signika" /> }
        </div>
        <img
          {...staticImages.policyDonationLogo}
          className="w-[80px] sm:w-[80px]"
          alt="palestine"
        />
      </div>
      {/* Recurring Modes */}
      <div className="w-full flex gap-5 justify-center">
        {suggestedRecurringModes.map((modeOption, index) => (
          <button
            key={index}
            className={`flex-1 text-center !h-[60px] font-signika text-xl text-[#b2a79d] lg:hover:bg-[#F60362] lg:hover:text-white border border-[#cccac5] ${selectedRecurring.value === modeOption.value ? 'bg-[#F60362] !text-white' : 'bg-white text-[#F60362]'}`}
            onClick={() => setSelectedRecurring(modeOption)}
          >
            {modeOption.label}
          </button>
        ))}
      </div>

      {/* Product Select */}
      <div className="w-full">
        <ReactSelect
          options={productsList}
          // className="max-h-[50px] [&>div]:!rounded-none"
          classNamePrefix="donate-select donate-select--main"
          value={currentProduct}
          defaultValue={defaultProduct}
          isSearchable={false}
          styles={styles}
          onChange={(e) => handleProduct(e)}
        />
      </div>

      {/* Suggested Prices */}
      <div className="w-full grid grid-cols-3 gap-[18px]">
        {suggestedPrices.map((price, index) => (
          <div key={`prices${index}`} className="col-span-1">
            <div
              className={`font-signika text-xl text-[#b1a79d] transition-all duration-200 cursor-pointer flex justify-center items-center h-[50px] lg:hover:bg-[#F60362] lg:hover:text-white border border-[#cccac5] ${
                selectedPrice == price ? 'bg-[#F60362] !text-white' : 'bg-white text-[#333333]'
              }`}
              onClick={() => {
                setSelectedPrice(price);
                setProductAmount(price);
              }}
              style={{
                width: "100%",
              }}
            >
              {ShowCurrencies(currentCurrency, price, false)}
              {/* <span className={`uppercase ml-1 text-xs`}>{currentCurrency}</span> */}
            </div>
          </div>
        ))}
      </div>

      {/* Currency Select and Amount Input */}
      <div className="w-full items-center rounded-none !h-[60px] flex">
        <div className="flex-[0_0_calc(33%-10px)] !h-[60px]">
          <SelectCurrency
            onChangeCurrency={(e) => handleCurrency(e)}
            classNamePrefix="currency-select-new currency-select-new--main"
            className="[&>div]:!rounded-none !max-h-[60px]"
          />
        </div>
        <input
          value={productAmount}
          name="amount"
          className="!h-[60px] text-[#b1a79d] w-full truncate p-2 text-xl pl-4 focus:outline-none !rounded-none border border-[#cccac6] border-l-0 !font-signika input_widget"
          type="number"
          min={"0"}
          onChange={(e) => {
            const regex = /^(?:[1-9]\d*)?$/;
            if (regex.test(e.target.value)) {
              setProductAmount(e.target.value);
              setSelectedPrice(null);
            }
          }}
          placeholder="Other Amount"
        />
      </div>

      {/* Donate Now Button */}
      <div className="w-full">
        <CustomButton
          onClick={() => {
            handleAddNewProduct();
          }}
          icon={<img {...staticImages.donateNowIcon} />}
          title={"Donate Now"}
          disabled={!isLoaded}
          className={`w-full hover:!bg-[#F60362] !h-[60px] !rounded-none !text-[20px] !capitalize !font-signika ${isLoaded ? "blob red" : ""}`}
        />
      </div>

      {/* Payment Methods Image */}
      <div className="w-full mt-1 bg-[#80CEFF] !h-[60px] flex justify-center items-center">
        <img {...staticImages.supportingPaymentBig} />
      </div>
    </div>
  );
};
Builder.registerComponent(WidgetHomeVerticalLight, {
  name: "WidgetHomeVerticalLight",
  inputs: [
    {
      name: "selectedCurrency",
      type: "string",
    },
    {
      name: "className",
      type: "string",
    },
    {
      name: "defaultPrice",
      type: "number",
    },
    {
      name: "defaultProduct",
      type: "object",
      defaultValue: {
        creator: "DD-EMR-PAL-24-11-01",
        label: "Save Lives in Gaza - Where Needed",
        value: 751,
      },
      subFields: [
        {
          name: 'creator',
          type: 'string',
          helperText: 'Enter creator ID',
          required: true,
          hideFromUI: false
        },
        {
          name: 'label',
          type: 'string',
          helperText: 'Enter label',
          required: true,
          hideFromUI: false
        },
        {
          name: 'value',
          type: 'number',
          helperText: 'Enter value',
          required: true,
          hideFromUI: false
        },
      ]
    },
    
    {
      name: 'dropDownProducts',
      type: 'string', 
      required: true,
    },
    {
      name: "mode",
      type: "string",

    },
    {
      name: "title",
      defaultValue: "Donate Urgently",
      type: "string",
    },
    {
      name: "importantDonation",
      defaultValue: "Make A Donation",
      type: "string",
    },
    {
      name: "showTimer",
      type: "boolean",
      defaultValue: false,
      friendlyName: "Show Timer"
    }
  ],
});
export default WidgetHomeVerticalLight;
