const countryData = {
    muslimCountries: [
        {
            name: "Indonesia",
            shortCode: "id",
            totalPopulation: 278200000,
            muslimPercentage: 87.2,
            muslimPopulation: 242587200,
            majorCities: [
                { name: "Jakarta", population: 10562088 },
                { name: "Surabaya", population: 2874699 },
                { name: "Medan", population: 2435252 },
                { name: "Bandung", population: 2339463 },
                { name: "Bekasi", population: 2143825 },
                // 5 more cities
                { name: "Depok", population: 2180000 },
                { name: "Tangerang", population: 2159000 },
                { name: "Palembang", population: 1695000 },
                { name: "Makassar", population: 1493000 },
                { name: "Semarang", population: 1600000 }
            ]
        },
        {
            name: "Pakistan",
            shortCode: "pk",
            totalPopulation: 235824862,
            muslimPercentage: 96.5,
            muslimPopulation: 227570991,
            majorCities: [
                { name: "Karachi", population: 16839950 },
                { name: "Lahore", population: 13541764 },
                { name: "Faisalabad", population: 3203846 },
                { name: "Islamabad", population: 2098231 },
                { name: "Multan", population: 1871843 },
                // 5 more cities
                { name: "Rawalpindi", population: 2100000 },
                { name: "Gujranwala", population: 2027000 },
                { name: "Peshawar", population: 1972000 },
                { name: "Quetta", population: 1203000 },
                { name: "Sargodha", population: 855000 }
            ]
        },
        {
            name: "Bangladesh",
            shortCode: "bd",
            totalPopulation: 171186372,
            muslimPercentage: 90.4,
            muslimPopulation: 154752560,
            majorCities: [
                { name: "Dhaka", population: 10356500 },
                { name: "Chattogram", population: 2581643 },
                { name: "Khulna", population: 1781425 },
                { name: "Rajshahi", population: 449756 },
                { name: "Sylhet", population: 479837 },
                // 5 more cities
                { name: "Gazipur", population: 1300000 },
                { name: "Mymensingh", population: 480000 },
                { name: "Barisal", population: 328278 },
                { name: "Comilla", population: 296010 },
                { name: "Rangpur", population: 307000 }
            ]
        },
        {
            name: "Egypt",
            shortCode: "eg",
            totalPopulation: 104258327,
            muslimPercentage: 90,
            muslimPopulation: 93832494,
            majorCities: [
                { name: "Cairo", population: 9539000 },
                { name: "Alexandria", population: 5200000 },
                { name: "Giza", population: 3628062 },
                { name: "Shubra El Kheima", population: 1099354 },
                { name: "Port Said", population: 749371 },
                // 5 more cities
                { name: "Suez", population: 744000 },
                { name: "Luxor", population: 549000 },
                { name: "Mansoura", population: 512000 },
                { name: "Tanta", population: 444300 },
                { name: "Asyut", population: 418500 }
            ]
        },
        {
            name: "Iran",
            shortCode: "ir",
            totalPopulation: 85028759,
            muslimPercentage: 99.4,
            muslimPopulation: 84518586,
            majorCities: [
                { name: "Tehran", population: 9039000 },
                { name: "Mashhad", population: 3001184 },
                { name: "Isfahan", population: 2016000 },
                { name: "Karaj", population: 1592492 },
                { name: "Shiraz", population: 1565572 },
                // 5 more cities
                { name: "Tabriz", population: 1613000 },
                { name: "Qom", population: 1244000 },
                { name: "Ahvaz", population: 1197000 },
                { name: "Kermanshah", population: 946000 },
                { name: "Urmia", population: 735000 }
            ]
        },
        {
            name: "Turkey",
            shortCode: "tr",
            totalPopulation: 84680273,
            muslimPercentage: 99.8,
            muslimPopulation: 84510912,
            majorCities: [
                { name: "Istanbul", population: 15460000 },
                { name: "Ankara", population: 5663000 },
                { name: "Izmir", population: 4320519 },
                { name: "Bursa", population: 3056120 },
                { name: "Antalya", population: 2548308 },
                // 5 more cities
                { name: "Adana", population: 2230000 },
                { name: "Gaziantep", population: 2035000 },
                { name: "Konya", population: 1309000 },
                { name: "Kayseri", population: 1229000 },
                { name: "Mersin", population: 1146000 }
            ]
        },
        {
            name: "Nigeria",
            shortCode: "ng",
            totalPopulation: 213401323,
            muslimPercentage: 53.5,
            muslimPopulation: 114169708,
            majorCities: [
                { name: "Lagos", population: 14862111 },
                { name: "Kano", population: 3848885 },
                { name: "Ibadan", population: 3552000 },
                { name: "Kaduna", population: 1582102 },
                { name: "Port Harcourt", population: 1865000 },
                // 5 more cities
                { name: "Benin City", population: 1667000 },
                { name: "Maiduguri", population: 1426000 },
                { name: "Zaria", population: 1075000 },
                { name: "Aba", population: 897560 },
                { name: "Jos", population: 816824 }
            ]
        },
        {
            name: "Saudi Arabia",
            shortCode: "sa",
            shortCode2: "ksa",
            totalPopulation: 35950000,
            muslimPercentage: 93,
            muslimPopulation: 33433500,
            majorCities: [
                { name: "Riyadh", population: 7231447 },
                { name: "Jeddah", population: 4076000 },
                { name: "Mecca", population: 1919000 },
                { name: "Medina", population: 1488782 },
                { name: "Dammam", population: 1225000 },
                // 5 more cities
                { name: "Al Ahsa (Hofuf)", population: 1533000 },
                { name: "Ta'if", population: 688693 },
                { name: "Tabuk", population: 667000 },
                { name: "Buraidah", population: 614093 }
            ]
        },
        {
            name: "Iraq",
            shortCode: "iq",
            totalPopulation: 40462701,
            muslimPercentage: 95.7,
            muslimPopulation: 38723005,
            majorCities: [
                { name: "Baghdad", population: 7511920 },
                { name: "Basra", population: 2750000 },
                { name: "Mosul", population: 1683000 },
                { name: "Erbil", population: 879000 },
                { name: "Najaf", population: 751000 },
                // 5 more cities
                { name: "Kirkuk", population: 875000 },
                { name: "Karbala", population: 700000 },
                { name: "Sulaymaniyah", population: 656000 },
                { name: "Diwaniyah", population: 620000 },
                { name: "Ramadi", population: 570000 }
            ]
        },
        {
            name: "Morocco",
            shortCode: "ma",
            totalPopulation: 37457000,
            muslimPercentage: 99,
            muslimPopulation: 37082430,
            majorCities: [
                { name: "Casablanca", population: 3359818 },
                { name: "Fez", population: 1112072 },
                { name: "Tangier", population: 947952 },
                { name: "Marrakesh", population: 928850 },
                { name: "Salé", population: 903485 },
                // 5 more cities
                { name: "Rabat", population: 580000 },
                { name: "Oujda", population: 580000 },
                { name: "Meknes", population: 632000 },
                { name: "Kenitra", population: 431000 },
                { name: "Agadir", population: 421000 }
            ]
        },
        // ---------- GCC COUNTRIES ----------
        {
            name: "Bahrain",
            shortCode: "bh",
            totalPopulation: 1780000,
            muslimPercentage: 73,
            muslimPopulation: 1299400,
            majorCities: [
                { name: "Manama", population: 200000 },
                { name: "Aali", population: 66000 },
                { name: "Isa Town", population: 38000 },
                { name: "Jidhafs", population: 16000 },
                { name: "Hamala", population: 21000 },
            ]
        },
        {
            name: "Kuwait",
            shortCode: "kw",
            totalPopulation: 4450000,
            muslimPercentage: 74.6,
            muslimPopulation: 3326700,
            majorCities: [
                { name: "Kuwait City", population: 2990000 },
                { name: "Al Jahra", population: 50189 },
                { name: "Abu Halifa", population: 137000 }
            ]
        },
        {
            name: "Oman",
            shortCode: "om",
            totalPopulation: 5300000,
            muslimPercentage: 85.9,
            muslimPopulation: 4552700,
            majorCities: [
                { name: "Muscat", population: 1550000 },
                { name: "Seeb", population: 238000 },
                { name: "Salalah", population: 163140 },
                { name: "Bawshar", population: 179000 },
                { name: "Sohar", population: 140000 },
                // 5 more cities
                { name: "Nizwa", population: 93000 },
                { name: "Sur", population: 75000 },
                { name: "Rustaq", population: 72000 }
            ]
        },
        {
            name: "Qatar",
            shortCode: "qa",
            totalPopulation: 2900000,
            muslimPercentage: 65.2,
            muslimPopulation: 1890800,
            majorCities: [
                { name: "Doha", population: 1850000 },
                { name: "Al Wakrah", population: 141000 },
            ]
        },
        {
            name: "United Arab Emirates",
            shortCode: "ae",
            shortCode2: "uae",
            totalPopulation: 9900000,
            muslimPercentage: 76,
            muslimPopulation: 7524000,
            majorCities: [
                { name: "Dubai", population: 3400000 },
                { name: "Abu Dhabi", population: 1500000 },
                { name: "Sharjah", population: 1300000 },
                { name: "Al Ain", population: 766000 },
                { name: "Ajman", population: 540000 },
                // 5 more cities
                { name: "Ras Al Khaimah", population: 345000 },
                { name: "Fujairah", population: 100000 },
                { name: "Umm Al Quwain", population: 49000 },
                { name: "Khor Fakkan", population: 38000 }
            ]
        }
    ],
    westernCountries: [
        {
            name: "United States",
            shortCode: "us",
            shortCode2: "usa",
            totalPopulation: 334233854,
            muslimPercentage: 1.1,
            muslimPopulation: 3676572,
            majorCities: [
                { name: "New York", population: 8804190 },
                { name: "Los Angeles", population: 3898747 },
                { name: "Chicago", population: 2746388 },
                { name: "Houston", population: 2313000 },
                { name: "Phoenix", population: 1608139 },
                // 5 more cities
                { name: "Philadelphia", population: 1584203 },
                { name: "San Antonio", population: 1547253 },
                { name: "San Diego", population: 1423851 },
                { name: "Dallas", population: 1341075 },
                { name: "San Jose", population: 1035317 }
            ]
        },
        {
            name: "Germany",
            shortCode: "de",
            totalPopulation: 83237124,
            muslimPercentage: 6.1,
            muslimPopulation: 5077464,
            majorCities: [
                { name: "Berlin", population: 3669495 },
                { name: "Hamburg", population: 1841179 },
                { name: "Munich", population: 1471508 },
                { name: "Cologne", population: 1085664 },
                { name: "Frankfurt", population: 753056 },
                // 5 more cities
                { name: "Stuttgart", population: 634830 },
                { name: "Düsseldorf", population: 619477 },
                { name: "Leipzig", population: 597493 },
                { name: "Dortmund", population: 587696 },
                { name: "Essen", population: 583084 }
            ]
        },
        {
            name: "United Kingdom",
            shortCode: "gb", // Officially "gb" for ISO code
            shortCode2: "uk",
            totalPopulation: 67220000,
            muslimPercentage: 6.3,
            muslimPopulation: 4234860,
            majorCities: [
                { name: "London", population: 9002488 },
                { name: "Birmingham", population: 1144900 },
                { name: "Leeds", population: 789194 },
                { name: "Glasgow", population: 633120 },
                { name: "Sheffield", population: 584853 },
                // 5 more cities
                { name: "Manchester", population: 553230 },
                { name: "Edinburgh", population: 506520 },
                { name: "Liverpool", population: 498042 },
                { name: "Bristol", population: 467099 },
                { name: "Leicester", population: 368601 }
            ]
        },
        {
            name: "France",
            shortCode: "fr",
            totalPopulation: 67391582,
            muslimPercentage: 8.8,
            muslimPopulation: 5930459,
            majorCities: [
                { name: "Paris", population: 2175601 },
                { name: "Marseille", population: 863310 },
                { name: "Lyon", population: 516092 },
                { name: "Toulouse", population: 479553 },
                { name: "Nice", population: 342669 },
                // 5 more cities
                { name: "Nantes", population: 303382 },
                { name: "Montpellier", population: 285121 },
                { name: "Strasbourg", population: 285912 },
                { name: "Bordeaux", population: 252040 },
                { name: "Lille", population: 232741 }
            ]
        },
        {
            name: "Italy",
            shortCode: "it",
            totalPopulation: 59011000,
            muslimPercentage: 4.8,
            muslimPopulation: 2832528,
            majorCities: [
                { name: "Rome", population: 4342212 },
                { name: "Milan", population: 1396059 },
                { name: "Naples", population: 962003 },
                { name: "Turin", population: 875698 },
                { name: "Palermo", population: 663401 },
                // 5 more cities
                { name: "Genoa", population: 580223 },
                { name: "Bologna", population: 391686 },
                { name: "Florence", population: 381037 },
                { name: "Bari", population: 320475 },
                { name: "Catania", population: 310000 }
            ]
        },
        {
            name: "Canada",
            shortCode: "ca",
            totalPopulation: 38250000,
            muslimPercentage: 3.7,
            muslimPopulation: 1415250,
            majorCities: [
                { name: "Toronto", population: 2731571 },
                { name: "Montreal", population: 1704694 },
                { name: "Vancouver", population: 631486 },
                { name: "Calgary", population: 1239220 },
                { name: "Edmonton", population: 932546 },
                // 5 more cities
                { name: "Ottawa", population: 1016000 },
                { name: "Winnipeg", population: 749534 },
                { name: "Quebec City", population: 542298 },
                { name: "Hamilton", population: 569353 },
                { name: "Kitchener", population: 470015 }
            ]
        },
        {
            name: "Spain",
            shortCode: "es",
            totalPopulation: 47615034,
            muslimPercentage: 2.6,
            muslimPopulation: 1237991,
            majorCities: [
                { name: "Madrid", population: 3223334 },
                { name: "Barcelona", population: 1620343 },
                { name: "Valencia", population: 789744 },
                { name: "Seville", population: 688592 },
                { name: "Zaragoza", population: 666880 },
                // 5 more cities
                { name: "Málaga", population: 578460 },
                { name: "Murcia", population: 460349 },
                { name: "Palma", population: 416065 },
                { name: "Las Palmas", population: 379925 },
                { name: "Bilbao", population: 345821 }
            ]
        },
        {
            name: "Australia",
            shortCode: "au",
            // Note: Should be ~25.69 million, not 2,569,000
            totalPopulation: 25690000,
            muslimPercentage: 3.2,
            muslimPopulation: 822080,
            majorCities: [
                { name: "Sydney", population: 5367206 },
                { name: "Melbourne", population: 5078193 },
                { name: "Brisbane", population: 2560720 },
                { name: "Perth", population: 2192229 },
                { name: "Adelaide", population: 1402393 },
                // 5 more cities
                { name: "Gold Coast", population: 679127 },
                { name: "Canberra", population: 462213 },
                { name: "Newcastle", population: 322278 },
                { name: "Wollongong", population: 302739 },
                { name: "Sunshine Coast", population: 333436 }
            ]
        },
        {
            name: "Netherlands",
            shortCode: "nl",
            totalPopulation: 17441139,
            muslimPercentage: 7.1,
            muslimPopulation: 1238321,
            majorCities: [
                { name: "Amsterdam", population: 872757 },
                { name: "Rotterdam", population: 651446 },
                { name: "The Hague", population: 545838 },
                { name: "Utrecht", population: 359355 },
                { name: "Eindhoven", population: 234456 },
                // 5 more cities
                { name: "Groningen", population: 232826 },
                { name: "Tilburg", population: 219632 },
                { name: "Apeldoorn", population: 163818 },
                { name: "Haarlem", population: 161265 },
                { name: "Arnhem", population: 159265 }
            ]
        },
        {
            name: "Belgium",
            shortCode: "be",
            totalPopulation: 11492641,
            muslimPercentage: 7.6,
            muslimPopulation: 873441,
            majorCities: [
                { name: "Brussels", population: 185103 },
                { name: "Antwerp", population: 523248 },
                { name: "Ghent", population: 262219 },
                { name: "Charleroi", population: 201816 },
                { name: "Liège", population: 197355 },
                // 5 more cities
                { name: "Bruges", population: 118284 },
                { name: "Namur", population: 110939 },
                { name: "Mons", population: 95000 },
                { name: "Mechelen", population: 86951 }
            ]
        }
    ]
};

export default countryData;
