import React from 'react';
import { Builder } from '@builder.io/react';
import scrollToTop from '../../../../../hooks/ui/useScrollToTop';

const ButtonDonateRounded = ({ title, subTitle, className }) => {
  return (
    <button
      className={`font-inter flex flex-col items-center gap-1 bg-[#F60362] rounded-full px-[26px] py-[10px] border border-[#0003] shadow-[inset_0_2px_2px_0_rgba(255,255,255,0.22),0_233px_233px_0_rgba(255,255,255,0.12)_inset] hover:bg-[#f60360eb] ${className}`}
      onClick={scrollToTop}
    >
      <span className="uppercase text-[26px] font-bold text-white">{title}</span>
      {subTitle && <span className="uppercase text-white opacity-75 font-bold text-sm">{subTitle}</span>}
    </button>
  );
};

Builder.registerComponent(ButtonDonateRounded, {
  name: "ButtonDonateRounded",
  inputs: [
    {
      name: "title",
      type: "string",
    },
    {
      name: "subTitle",
      type: "string",
    },
  ],
});

export default ButtonDonateRounded;