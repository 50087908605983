
import ReactSelect from "react-select";
import usaImg from "../../images/flags/usa.svg";
import ausImg from "../../images/flags/aus.svg";
import canImg from "../../images/flags/can.svg";
import eurImg from "../../images/flags/eu.svg";
import gbrImg from "../../images/flags/gbr.svg";
import mysImg from "../../images/flags/mys.svg";
import sgpImg from "../../images/flags/sgp.svg";
import pkImg from "../../images/flags/pk.svg";
import inImg from "../../images/flags/in.svg";
import bdImg from "../../images/flags/bd.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCurrency,  setSelectedCurrencyInStore } from "../../store/user";

const styles = {
    menuList: (base) => ({
        ...base,

        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        },
        "& *": {
            cursor: "pointer"
        }
    })
}

const SelectCurrencyZakatWidget = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const dispatch = useDispatch();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const { onChangeCurrency } = props;
    const currencies = [
        { value: 'AUD', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] inline-block`} src={ausImg} width="25px" alt='aus' />AUD</span> },
        { value: 'USD', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] inline-block`} src={usaImg} width="25px" alt='usa' />USD</span> },
        { value: 'GBP', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] inline-block`} src={gbrImg} width="25px" alt='gbp' />GBP</span> },
        { value: 'CAD', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] inline-block`} src={canImg} width="25px" alt='can' />CAD</span> },
        { value: 'EUR', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] inline-block`} src={eurImg} width="25px" alt='eur' />EUR</span> },
        { value: 'SGD', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] inline-block`} src={sgpImg} width="25px" alt='sgp' />SGD</span> },
        { value: 'MYR', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] inline-block`} src={mysImg} width="25px" alt='mys' />MYR</span> },
        { value: 'PKR', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] inline-block`} src={pkImg} width="25px" alt='PKR' />PKR</span> },
        { value: 'INR', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] inline-block`} src={inImg} width="25px" alt='PKR' />INR</span> },
        { value: 'BDT', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] inline-block`} src={bdImg} width="25px" alt='PKR' />BDT</span> },
    ];
    const [currentCurrency, setCurrentCurrency] = useState(currencies[0]);
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleSelectCurrency = (currency) => {
        const supportedCurrencies = ['PKR', 'INR', 'BDT'];
        setCurrentCurrency(currency);
        onChangeCurrency(currency);
        if (!supportedCurrencies.includes(currency.value)) {
            // Code for unsupported currencies
            dispatch(setSelectedCurrencyInStore(currency.value));
        }
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        let temp = {};
        currencies.map((item) => {
            if (item.value === selectedCurrencyStore) {
                temp = item;
            }
            return null
        })
        temp.value && setCurrentCurrency(temp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore]);
    /* -------------------------------------------------------------------------- */
    return (
        <ReactSelect
            options={currencies}
            className={`notranslate max-h-[48px] [&>*]:!cursor-pointer w-full [&>div]:!rounded-md`}
            classNamePrefix={''}
            value={currentCurrency}
            isSearchable={false}
            styles={styles}
            onChange={(e) => handleSelectCurrency(e)}
            // onMenuOpen={onMenuOpen}
            // onMenuClose={onMenuClose}
        />
    );
}

export default SelectCurrencyZakatWidget;
