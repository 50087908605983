import { Builder } from '@builder.io/react';
import { useEffect, useState } from 'react';
import services from "../../../../../services";
import Multiselect from 'multiselect-react-dropdown';
const ProductDropDown = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsCodes, setSelectedProductsCodes] = useState([]);
  const [listOfAllProducts, setListOfAllProducts] = useState([]);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getListOfAllProducts = async () => {
    try {
      const response = await services.searchProducts();
      function compare(a, b) {
        if (a.name.trim() < b.name.trim()) {
          return -1;
        }
        if (a.name.trim() > b.name.trim()) {
          return 1;
        }
        return 0;
      }
      let temp = response.data.sort(compare);
      setListOfAllProducts(temp);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleSelectProduct = selectedList => {
    console.log("products=", selectedList);
    setSelectedProducts(selectedList);
    let temp = [];
    selectedList.map(item => {
      temp.push(item.creator);
    });
    setSelectedProductsCodes(temp);
  };
  const handleRemoveProduct = selectedList => {
    setSelectedProducts(selectedList);
    let temp = [];
    selectedList.length && selectedList.map(item => {
      temp.push(item.creator);
    });
    setSelectedProductsCodes(temp);
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    getListOfAllProducts();
  }, []);
  /* -------------------------------------------------------------------------- */
  return <div className="w-full">
            <label htmlFor="products">Products</label>
            <Multiselect avoidHighlightFirstOption={true} name="products" options={listOfAllProducts} // Options to display in the dropdown
    selectedValues={selectedProducts} // Preselected value to persist in dropdown
    onSelect={val => {
      handleSelectProduct(val);
    }} // Function will trigger on select event
    onRemove={val => {
      handleRemoveProduct(val);
    }} displayValue="name" // Property name to display in the dropdown options
    />
        </div>;
};
Builder.registerComponent(ProductDropDown, {
  name: 'ProductDropDown',
  inputs: []
});
export default ProductDropDown;