import { Builder } from '@builder.io/react';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../../../../store/user";
import SelectCurrency from "../../../../../components/general/selectCurrency";
import ReactSelect from "react-select";
import useHandleAddProduct from "../../../../../utils/handleAddProduct";
import { selectProducts } from "../../../../../store/products";
import { useNavigate } from "react-router-dom";
import { generateLinkWithLang } from "../../../../../utils/linkGenerator";
import { useTranslation } from "react-i18next";
const styles = {
  control: base => ({
    ...base,
    height: '48px',
    border: 'none',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)'
  }),
  menuList: base => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "6px",
      height: "0px"
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1"
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555"
    }
  }),
  shadow: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
  }
};
const ZakatAlFitrWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    defaultName = 'Zakat Al Mal',
    defaultCreator = 'RM24-ZM-013',
    mode = 'one-off',
    arabic = false,
  } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [productAmount, setProductAmount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState();
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [addProduct] = useHandleAddProduct();
  const [selectedProduct, setSelectedProduct] = useState({
    creator: defaultCreator,
    label: defaultName,
    value: 255
  });
  const [currentProduct, setCurrentProduct] = useState({
    creator: defaultCreator,
    label: defaultName,
    value: ''
  });
  const products = useSelector(state => selectProducts(state));
  const navigate = useNavigate();
  const {
    i18n
  } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleSelectedProduct = product => {
    allProducts.map(item => item.creator === product.creator && setSelectedProduct(item));
  };
  const suggestedRecurringModes = [{
    value: 'one-off',
    label: arabic ? 'فردي' : 'Single'
  }, {
    value: 'week',
    label: arabic ? 'أسبوعي' : 'Weekly'
  }, {
    value: 'month',
    label: arabic ? 'شهري' : 'Monthly'
  }];
  // Function to get the recurring mode based on the mode value
  const getRecurringMode = (mode) => {
    return suggestedRecurringModes.find((recurringMode) => recurringMode.value === mode) || suggestedRecurringModes[0];
  };

  const [selectedRecurring] = useState(getRecurringMode(mode));
  const handleAddNewProduct = () => {
    // 
    let tempProduct = selectedProduct;
    let customAmount = Number(productAmount) ? Number(productAmount) : 100;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      recurring: selectedRecurring.value
    });
    addProduct({
      product: tempProduct,
      currency: currentCurrency
    });
  };
  const handleQty = (value) => {
    let tempCount = productAmount;
    if (parseInt(value) > 0) {
      tempCount = parseInt(value);
    } else {
      tempCount = 0;
    }
    setProductAmount(tempCount);
  };
  const handleKeyDown = value => {
    let checkIfNum;
    if (value.key !== undefined) {
      checkIfNum = value.key === '.';
    }
    return checkIfNum && value.preventDefault();
  };
  const handleOnBlurQty = (value) => {
    if (Number(value) === 0) {
      handleQty(1);
    }
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  const getAllProducts = products => {
    setIsLoaded(true);
    let tempProductsList = [];
    let crisisWidgetProducts = [];
    let tempDefaultProduct;
    let tempAlternativeProduct;
    if (arabic) {
      products.forEach(item => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            if (Number(inner_item_product.status) === 1) {
              if (inner_item_product.creator === defaultCreator) {
                // console.log("found=", defaultCreator, inner_item_product);
                // setSelectedProduct(inner_item_product);
                tempDefaultProduct = inner_item_product;
              }
              if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
                // Morocco Appeal
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'فلسطين أكثر ما يحتاج',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') {
                // Afghanistan Earthquake Appeal
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'الإغاثة الطبية في فلسطين',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'MKD-GD-MP001') {
                // Libya Floods
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'حيث يحتاج الأكثر',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'نداء المغرب',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'فيضانات ليبيا',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'EMR-GEN-MER-2023-01-117') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'الإغاثة الطارئة',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'مساعدة غذائية',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'مساعدة المياه',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'الأيتام حول العالم',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'MKD-GEN-MSD-2023-01-121') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'الصدقة الجارية',
                  creator: inner_item_product.creator
                });
                tempAlternativeProduct = inner_item_product;
              }
              if (inner_item_product.creator === 'MKD-GEN-MED-2023-01-122') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'التعليم',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'MKD-GEN-MPN-2023-01-124') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'مشروع النور',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'MKD-GEN-MPB-2023-01-125') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'امشروع البناء',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'SST-GEN-IGN-2023-01-069') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'توليد الدخل',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'MKD-EM-SYR-EQ-01-2023') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'حالة طوارئ في سوريا',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'RM24-ZM-013') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'زكاة المال',
                  creator: inner_item_product.creator
                });
              }
              if (inner_item_product.creator === 'INT-001') {
                // Keep Orphans Warm Where Most Needed
                crisisWidgetProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: 'تنقية الربا',
                  creator: inner_item_product.creator
                });
              }
            }
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
      tempProductsList = tempProductsList.filter(function ({
        creator
      }) {
        return !this.has(creator) && this.add(creator);
      }, new Set());
    } else {
      products.forEach(item => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            if (Number(inner_item_product.status) === 1) {
              if (inner_item_product.creator === defaultCreator) {
                // console.log("found=", defaultCreator, inner_item_product);
                // setSelectedProduct(inner_item_product);
                tempDefaultProduct = inner_item_product;
              }
              if (inner_item.name === 'Build A Water Well') {
                if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
                  crisisWidgetProducts.push(inner_item_product); // Water well
                  tempProductsList.push({
                    value: inner_item_product.id,
                    label: inner_item_product.name,
                    creator: inner_item_product.creator
                  });
                }
              }
              ;
              if (inner_item.name === 'Orphans') {
                if (inner_item_product.creator === 'MKD-SDQ-OAW-2023-02-119') {
                  crisisWidgetProducts.push(inner_item_product); // Support an Orphan Centre
                  tempProductsList.push({
                    value: inner_item_product.id,
                    label: inner_item_product.name,
                    creator: inner_item_product.creator
                  });
                }
              }
              ;
              if (inner_item.name === 'Caravan') {
                if (inner_item_product.creator === 'EM-SDQ-EQ1-02-2023') {
                  crisisWidgetProducts.push(inner_item_product); // Emergency Caravan Long Term Shelter
                  tempProductsList.push({
                    value: inner_item_product.id,
                    label: inner_item_product.name,
                    creator: inner_item_product.creator
                  });
                }
              }
              ;
              if (inner_item.name === 'Palestine') {
                if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
                  crisisWidgetProducts.push(inner_item_product); // Palestine Appeal
                  tempProductsList.push({
                    value: inner_item_product.id,
                    label: inner_item_product.name,
                    creator: inner_item_product.creator
                  });
                }
              }
              ;
              if (inner_item.name === 'Make A Donation') {
                if (inner_item_product.creator !== 'MKD-EM-TUR-EQ-01-2023') {
                  crisisWidgetProducts.push(inner_item_product);
                  tempProductsList.push({
                    value: inner_item_product.id,
                    label: inner_item_product.name,
                    creator: inner_item_product.creator
                  });
                }
                tempAlternativeProduct = inner_item.products[0];
              };
              if (inner_item.name === 'Winter Campaign') {
                if (inner_item_product.creator === 'MKD-EMR-WNT-2023-01') {
                  crisisWidgetProducts.push(inner_item_product); // Keep Orphans Warm Where Most Needed
                  tempProductsList.push({
                    value: inner_item_product.id,
                    label: inner_item_product.name,
                    creator: inner_item_product.creator
                  });
                }
              }
              ;
              if (defaultCreator) {
                if (inner_item_product.creator === defaultCreator) {
                  setSelectedProduct(inner_item_product);
                }
              } else {
                if (inner_item.name === 'Make A Donation') {
                  setSelectedProduct(inner_item.products[0]);
                }
                ;
              }
            }
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
    }
    if (tempDefaultProduct) {
      crisisWidgetProducts.push(tempDefaultProduct);
      setSelectedProduct(tempDefaultProduct);
      setCurrentProduct({
        value: tempDefaultProduct.id,
        label: tempDefaultProduct.name,
        creator: tempDefaultProduct.creator
      });
    } else {
      if (tempAlternativeProduct) {
        setSelectedProduct(tempAlternativeProduct);
        setCurrentProduct({
          value: tempAlternativeProduct.id,
          label: tempAlternativeProduct.name,
          creator: tempAlternativeProduct.creator
        });
      }
    }
    const uniqueItems = tempProductsList.filter((item, index, self) => index === self.findIndex(t => t.creator === item.creator));
    setProductsList(uniqueItems);
    setAllProducts(crisisWidgetProducts);
  };
  useEffect(() => {
    if (selectedCurrencyStore) {
      setCurrentCurrency(selectedCurrencyStore);
      setProductAmount(selectedProduct[selectedCurrencyStore.toLowerCase()]);
    } else {
      setCurrentCurrency('AUD');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && Object.keys(products).length && getAllProducts(products);
    // products && !allProducts.length && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  return <div dir={arabic ? "rtl" : 'ltr'} className={`${arabic ? 'font-[AlmariMedium]' : ''} w-full rounded-lg bg-slate-200- h-[400px]- py-2 md:py-4 flex flex-wrap items-center`}>
    <div className="basis-full grid grid-cols-12 gap-2">
      <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-8 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
        <ReactSelect options={productsList} className='max-h-[48px] w-full pointer-events-none' defaultValue={selectedProduct} isSearchable={false} styles={styles} placeholder='Select Product' onChange={e => handleSelectedProduct(e)}
          // isDisabled
          readOnly />
      </div>
      <div className="col-span-12 hidden md:flex md:col-span-6 lg:col-span-4  justify-center items-center-">
        <button className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`} onClick={() => navigate(generateLinkWithLang('/zakat-calculator', lang))}>Calculate your Zakat</button>
      </div>
      <div className="col-span-7 sm:col-span-6 md:col-span-4 lg:col-span-4 flex justify-center items-center bg-white rounded-md h-12 px-2  min-[400px]:mb-0" style={styles.shadow}>
        <span className="text-sm border-r pr-2 text-[#78716C]">{currentCurrency}</span>
        <input
          className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
          value={productAmount || 1}
          type="number"
          onChange={e => handleQty(e.target.value)}
          onKeyDown={e => handleKeyDown(e)}
          onBlur={e => handleOnBlurQty(e.target.value)}
          placeholder="Enter amount"
        />
      </div>
      <div className="col-span-5 sm:col-span-6 md:col-span-4 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
        <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
      </div>
      <div className="col-span-12  md:hidden sm:col-span-6 md:col-span-6 lg:col-span-4  justify-center items-center-">
        <button className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`} onClick={() => navigate(generateLinkWithLang('/zakat-calculator', lang))}>Calculate your Zakat</button>
      </div>
      <div className="col-span-12 md:col-span-4 lg:col-span-4 flex justify-center items-center-">
        <button className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`} onClick={handleAddNewProduct}>Quick Donate</button>
      </div>
    </div>
  </div>;
};
Builder.registerComponent(ZakatAlFitrWidget, {
  name: 'ZakatAlFitrWidget',
  inputs: [{
    name: "defaultName",
    defaultValue: 'Zakat Al Mal',
    friendlyName: 'Default Name',
    type: "string"
  }, {
    name: "defaultCreator",
    defaultValue: 'RM24-ZM-013',
    friendlyName: 'Default Creator',
    type: "string"
  },
  {
    name: 'mode',
    type: 'enum', // Use 'enum' to define a dropdown with specific options
    enum: ['one-off', 'week', 'month'], // Define the available options
    defaultValue: 'one-off', // Set a default value if desired
    enumLabels: ['Single', 'Weekly', 'Monthly'],
  },
  {
    name: 'arabic',
    type: 'boolean',
    // defaultValue: false,
    friendlyName: 'Show Arabic Content',
  }]
});
export default ZakatAlFitrWidget;