import { useEffect } from 'react';

const useElementRemoval = (elementSelector, customStyles = '') => {
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      ${elementSelector} {
        display: none !important;
        ${customStyles}
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [elementSelector, customStyles]);

  return null;
}

export default useElementRemoval;
