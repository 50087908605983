import { Builder } from '@builder.io/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from "../../../../../../store/user";
import ProductBoxCart from "../../../../../general/ramadanDailyWidget/productBoxCart";
import useCart from "../../../../../../hooks/cart/useCart";
import services from "../../../../../../services";
import { selectProducts } from '../../../../../../store/products';
const ProductBoxCarts = ({
  products = []
}) => {
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const _allProducts = useSelector(state => selectProducts(state));
  const {
    handleAddToGiving,
    handleRemoveProduct,
    handleCheckProduct
  } = useCart();
  const getAllProducts = _allProducts => {
    setIsLoaded(true);
    const tempSelectedProducts = [];
    _allProducts.forEach(item => {
      item.sub_categories.forEach(innerItem => {
        innerItem.products.forEach(innerProduct => {
          if (products.includes(innerProduct.creator)) {
            tempSelectedProducts.push(innerProduct);
          }
        });
      });
    });
    setSelectedProducts(tempSelectedProducts);
  };
  useEffect(() => {
    if (products.length > 0) {
      _allProducts && Object.keys(_allProducts).length && !isLoaded && getAllProducts(_allProducts);
    }
  }, [_allProducts, products]);
  return <div className="grid grid-cols-1 gap-3 sm:gap-6">
      {selectedProducts.map((product, index) => <ProductBoxCart key={index} product={product} onCheck={handleCheckProduct} onAdd={handleAddToGiving} onRemove={handleRemoveProduct} currency={selectedCurrencyStore ? selectedCurrencyStore : 'AUD'} />)}
    </div>;
};
Builder.registerComponent(ProductBoxCarts, {
  name: 'ProductBoxCarts',
  inputs: [{
    "name": "products",
    "type": "string"
  }]
});
export default ProductBoxCarts;