// AutoplayPlugin.js
const AutoplayPlugin = (interval = 2000) => (slider) => {
    let timeout;
    let mouseOver = false;

    const clearNextTimeout = () => {
      clearTimeout(timeout);
    };

    const nextTimeout = () => {
      clearTimeout(timeout);
      if (mouseOver) return;
      timeout = setTimeout(() => {
        slider.next();
      }, interval);
    };

    slider.on('created', () => {
      slider.container.addEventListener('mouseover', () => {
        mouseOver = true;
        clearNextTimeout();
      });
      slider.container.addEventListener('mouseout', () => {
        mouseOver = false;
        nextTimeout();
      });
      nextTimeout();
    });

    slider.on('dragStarted', clearNextTimeout);
    slider.on('animationEnded', nextTimeout);
    slider.on('updated', nextTimeout);

    // Optional: Expose pause and resume methods
    slider.pauseAutoplay = () => {
      clearNextTimeout();
      mouseOver = true;
    };

    slider.resumeAutoplay = () => {
      if (!mouseOver) return;
      mouseOver = false;
      nextTimeout();
    };

    slider.on('destroyed', () => {
      clearNextTimeout();
    });
  };

  export default AutoplayPlugin;
