import { Builder } from '@builder.io/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../../../store/user';
import SelectCurrency from '../../../../../components/general/selectCurrency';
import ReactSelect from 'react-select';
import ConvertUSD from '../../../../../utils/convertUSD';
import useHandleAddProduct from '../../../../../utils/handleAddProduct';
import { selectProducts } from '../../../../../store/products';
import { formatNumberWithTwoDecimals } from '../../../../../utils/helperFunctions';
import getSymbolFromCurrency from 'currency-symbol-map';
import useCart from '../../../../../hooks/cart/useCart';
import { openSheet } from '../../../../../store/sheetToggler';
const styles = {
  control: base => ({
    ...base,
    height: '48px',
    border: 'none',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)',
  }),
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  shadow: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)',
  },
};
const FidyaKaffarahWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { type = 'kaffarah', mode = 'one-off', lang = 'en', addToCart = true } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [productAmount, setProductAmount] = useState('');
  const [numberOfFasts, setNumberOfFasts] = useState('1');
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState(selectedCurrencyStore ? selectedCurrencyStore : 'AUD');
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [addProduct] = useHandleAddProduct();
  const [selectedProduct, setSelectedProduct] = useState({
    creator: type.toLowerCase() === 'kaffarah' ? 'RM25-00-001' : 'RM25-00-002',
    label: type.toLowerCase() === 'kaffarah' ? 'Kaffarah' : 'Fidya',
    value: type.toLowerCase() === 'kaffarah' ? 759 : 760,
  });
  const [currentProduct, setCurrentProduct] = useState({
    creator: type.toLowerCase() === 'kaffarah' ? 'RM25-00-001' : 'RM25-00-002',
    label: type.toLowerCase() === 'kaffarah' ? 'Kaffarah' : 'Fidya',
    value: type.toLowerCase() === 'kaffarah' ? 759 : 760,
  });
  const products = useSelector(state => selectProducts(state));
  const dispatch = useDispatch();
  const { handleAddToGiving: handleAddToGivingCart } = useCart();
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleConvert = (currency, value) => {
    return Math.round(ConvertUSD(currency, value, false));
  };
  const handleSelectedProduct = product => {
    allProducts.map(item => {
      if (item.creator === product.creator) {
        setSelectedProduct(item);
        setCurrentProduct(product);
      }
    });
  };

  const handleProductAmount = (currency, value) => {
    setProductAmount(handleConvert(currency, value));
  };

  const suggestedRecurringModes = [
    {
      value: 'one-off',
      label: lang ? 'فردي' : 'Single',
    },
    {
      value: 'week',
      label: lang ? 'أسبوعي' : 'Weekly',
    },
    {
      value: 'month',
      label: lang ? 'شهري' : 'Monthly',
    },
  ];
  // Function to get the recurring mode based on the mode value
  const getRecurringMode = mode => {
    return suggestedRecurringModes.find(recurringMode => recurringMode.value === mode) || suggestedRecurringModes[0];
  };
  const [selectedRecurring, setSelectedRecurring] = useState(getRecurringMode(mode));
  const handleAddNewProduct = () => {
    let tempProduct = selectedProduct;
    let customAmount = Number(numberOfFasts) ? Number(numberOfFasts) : 100;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      recurring: selectedRecurring.value,
    });

    if (addToCart) {
      handleAddToGivingCart(tempProduct, 'custom');
      dispatch(openSheet());
    } else {
      addProduct({ product: tempProduct, currency: currentCurrency });
    }
    
  };

  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  const getAllProducts = products => {
    setIsLoaded(true);
    let tempProductsList = [];
    let widgetProducts = [];
    let tempDefaultProduct;
    let tempAlternativeProduct;
    products.forEach(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          let price = inner_item_product[`${currentCurrency.toLowerCase()}`];
          let amount = formatNumberWithTwoDecimals(numberOfFasts * price);
          if (inner_item_product.creator === 'RM25-00-001') {
            //Kaffarah
            widgetProducts.push(inner_item_product);
            tempProductsList.push({
              value: inner_item_product.id,
              label: inner_item_product.name,
              creator: inner_item_product.creator,
            });
            if (type.toLowerCase() === 'kaffarah') {
              setProductAmount(amount);
              tempDefaultProduct = inner_item_product;
            }
          }
          if (inner_item_product.creator === 'RM25-00-002') {
            //Fidya
            widgetProducts.push(inner_item_product);
            tempProductsList.push({
              value: inner_item_product.id,
              label: inner_item_product.name,
              creator: inner_item_product.creator,
            });
            if (type.toLowerCase() === 'fidya') {
              setProductAmount(amount);
              tempDefaultProduct = inner_item_product;
            }
          }
          if (inner_item.name === 'Make A Donation') {
            tempAlternativeProduct = inner_item.products[0];
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    if (tempDefaultProduct) {
      setSelectedProduct(tempDefaultProduct);
      setCurrentProduct({
        value: tempDefaultProduct.id,
        label: tempDefaultProduct.name,
        creator: tempDefaultProduct.creator,
      });
    } else {
      if (tempAlternativeProduct) {
        widgetProducts.push(tempAlternativeProduct);
        setSelectedProduct(tempAlternativeProduct);
        setCurrentProduct({
          value: tempAlternativeProduct.id,
          label: tempAlternativeProduct.name,
          creator: tempAlternativeProduct.creator,
        });
      }
    }
    tempProductsList = tempProductsList.filter(n => n);
    const uniqueItems = tempProductsList.filter((item, index, self) => index === self.findIndex(t => t.creator === item.creator));
    setProductsList(uniqueItems);
    setAllProducts(widgetProducts);
    try {
    } catch (error) {
      console.error('Error fetching selected products:', error);
    }
  };
  useEffect(() => {
    products && Object.keys(products).length && getAllProducts(products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    if (selectedCurrencyStore) {
      setCurrentCurrency(selectedCurrencyStore);
    } else {
      setCurrentCurrency('AUD');
    }

    // Recalculate productAmount on currency or product change
    if (selectedProduct) {
      const price = selectedProduct[`${currentCurrency.toLowerCase()}`];
      if (price && numberOfFasts) {
        const amount = formatNumberWithTwoDecimals(numberOfFasts * price);
        setProductAmount(amount);
      } else {
        setProductAmount('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore, selectedProduct, numberOfFasts]);

  /* -------------------------------------------------------------------------- */
  return (
    <div className={`w-full rounded-lg bg-slate-200- h-[400px]- py-2 md:py-4 flex flex-wrap items-center`}>
      <div className="text-[#093686] w-full mb-3  leading-[45px] text-center text-[35px] font-bold">{selectedProduct.name} Calculator</div>
      <div className="basis-full grid grid-cols-12 gap-2">
        <div className="order-1 col-span-12 min-[500px]:col-span-6 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <ReactSelect
            options={productsList}
            className="max-h-[48px] w-full"
            defaultValue={currentProduct}
            isSearchable={false}
            styles={styles}
            placeholder="Select Product"
            onChange={(e, index) => {
              const item = allProducts.find(i => i.creator === e.creator);
              const price = item[`${currentCurrency.toLowerCase()}`];
              if (numberOfFasts) {
                let amount = formatNumberWithTwoDecimals(numberOfFasts * price);
                setProductAmount(amount);
              }
              handleSelectedProduct(e);
            }}
          />
        </div>
        <div
          className="order-2 col-span-12 min-[500px]:col-span-6 lg:col-span-4 flex justify-center items-center bg-white rounded-md h-12 px-2 mb-2 min-[400px]:mb-0"
          style={styles.shadow}
        >
          <input
            value={numberOfFasts}
            className="w-full p-2 rounded-lg h-full text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
            type="number"
            onChange={e => {
              if (e.target.value === '0') return;
              if (!e.target.value) {
                setProductAmount('');
                setNumberOfFasts(e.target.value);
                return;
              }
              let price = selectedProduct[`${currentCurrency.toLowerCase()}`];
              let amount = formatNumberWithTwoDecimals(e.target.value * price);
              setProductAmount(amount);
              setNumberOfFasts(e.target.value);
            }}
            placeholder="No. of missed fasts"
          />
        </div>
        <div className="order-3 col-span-5 min-[500px]:col-span-6 lg:col-span-4 lg:mb-2 flex items-start justify-center">
          <SelectCurrency
            onChangeCurrency={e => {
              const price = selectedProduct[`${e.value.toLowerCase()}`];
              if (numberOfFasts) {
                let amount = formatNumberWithTwoDecimals(numberOfFasts * price);
                setProductAmount(amount);
              }
              setCurrentCurrency(e.value);
            }}
            classNamePrefix="orphan_select"
          />
        </div>
        <div className="order-5 my-3 sm:my-0 md:order-5 lg:order-4 min-[500px]:order-5 col-span-12 min-[500px]:col-span-12 lg:col-span-4 md:grid grid-cols-12 gap-2 mb-2 sm:mb-0">
          <div className="col-span-12 ">
            <div
              className={`text-[16px] h-12  leading-5 transition-all duration-200
                         flex justify-start items-center px-2 md:px-1 py-2 md:py-4 min-w-[80px] `}
            >
              {selectedProduct?.name && (selectedProduct?.name).toLowerCase() === 'kaffarah'
                ? `Kaffarah is calculated at feeding 60 people per missed fast
                                ${
                                  numberOfFasts
                                    ? ` at ${getSymbolFromCurrency(currentCurrency.toLowerCase())}${(Number(productAmount) / 60).toFixed(
                                        2,
                                      )} ${currentCurrency} per person, totaling ${getSymbolFromCurrency(
                                        currentCurrency.toLowerCase(),
                                      )}${productAmount} ${currentCurrency} per missed fast.`
                                    : '.'
                                }`
                : `Fidya is calculated ${
                    numberOfFasts ? ` at ${getSymbolFromCurrency(currentCurrency?.toLowerCase())}${Number(productAmount)} ` : ''
                  } ${currentCurrency} per missed fast.`}
            </div>
          </div>
        </div>
        <div
          className="order-4 md:order-4 lg:order-5 min-[500px]:order-4 col-span-7 min-[500px]:col-span-6 lg:col-span-4 flex justify-center items-center bg-white rounded-md h-12 px-2 mb-2 min-[400px]:mb-0"
          style={styles.shadow}
        >
          <span className="text-sm border-r pr-2 text-[#78716C]">{currentCurrency}</span>
          <input
            value={productAmount}
            readOnly
            className="w-full p-2 rounded-lg text-base placeholder:text-[#253B7E] focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
            type="number"
            onChange={e => {
              setProductAmount(e.target.value);
              // setSelectedPrice(null);
            }}
            placeholder="Amount to be paid"
          />
        </div>
        <div className="order-6 col-span-12 lg:col-span-4 flex justify-center items-center-">
          <button
            className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${
              isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
            }`}
            onClick={handleAddNewProduct}
          >
            {'Quick Donate'}
          </button>
        </div>
      </div>
    </div>
  );
};
Builder.registerComponent(FidyaKaffarahWidget, {
  name: 'FidyaKaffarahWidget',
  inputs: [
    {
      name: 'type',
      type: 'enum', // Use 'enum' to define a dropdown with specific options
      enum: ['kaffarah', 'fidya'], // Define the available options
      required: true, // Makes this field mandatory
      defaultValue: 'kaffarah', // Set a default value if desired
      enumLabels: ['Kaffarah', 'Fidya'],
      friendlyName: 'Type',
    },
    {
      name: 'mode',
      type: 'enum', // Use 'enum' to define a dropdown with specific options
      enum: ['one-off', 'week', 'month'], // Define the available options
      required: true, // Makes this field mandatory
      defaultValue: 'one-off', // Set a default value if desired
      // enumLabels: ['Single', 'Weekly', 'Monthly'],
      friendlyName: 'Mode',
    },
  ],
});
export default FidyaKaffarahWidget;
