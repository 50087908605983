import React from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import ShowCurrencies from '../../utils/showCurrencies';
import { IoCloseOutline } from 'react-icons/io5';

const countVisualHandler = (isVisible, onChange, onAddQty, onReduceQty, item) => (
  <div
    className={`${isVisible} py-[.2rem] border-[.1rem] border-[#d2d5d999] hover:border-[#25b9db] focus:border-[#25b9db] w-[92px] text-[#777] bg-white`}
  >
    <button onClick={() => onReduceQty(item)} className="w-5 h-5 flex items-center justify-center cursor-pointer">
      -
    </button>
    <input
      type="number"
      value={item.quantity}
      onChange={e => onChange(e, item)}
      className="w-12 text-center bg-transparent text-[#777] border-none outline-none hover:outline-none focus:outline-none"
    />
    <div onClick={() => onAddQty(item)} className="w-5 h-5 flex items-center justify-center cursor-pointer">
      +
    </div>
    {/* <span>{JSON.stringify(item)}</span> */}
  </div>
);

export const ProductBoxCartSheet = ({ selectedProducts, onAddQty, onReduceQty, onUpdateQty, onRemove }) => {
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);

  const { products } = selectedProducts;

  const handleInputChange = (e, item) => {
    const value = e.target.value.trim();
    const quantity = isNaN(value) || value === '' || Number(value) <= 0 ? 1 : Math.floor(Number(value));
    onUpdateQty(item, quantity);
  };

  return (
    <div className="p-5 bg-[#ebf9fc] shadow-[3px_4px_1px_rgba(0,0,0,0.1)]">
      {products.map((item, index) => (
        <div className="flex flex-col" key={item.name}>
          <div className="flex justify-between gap-2 items-start">
            {/* Left */}
            <div className="flex gap-2 items-start flex-1">
              <img src={item.image_link} alt="" className="w-16" />
              <div className="flex flex-col gap-3 max-w-[160px]">
                <p className="font-signika text-[19px] md:text-2xl font-semibold text-[#0b3741] !leading-[25px]">{item.name}</p>
                {/* <p className="font-signika font-light text-[#0b3741] truncate">{item.description}</p> */}
              </div>
            </div>
            {/* Right */}
            <div className="flex justify-between items-center">
              <div className="flex flex-col items-end gap-1">
                <p className="font-signika text-[#25b9db] font-bold text-2xl md:text-3xl pr-[2px]">
                  {ShowCurrencies(selectedCurrencyStore, item.quantity * item[selectedCurrencyStore.toLowerCase()], false)}
                </p>
                <button
                  className="font-semibold font-signika text-[#F60362] text-xs flex items-center gap-1 leading-3 uppercase"
                  onClick={() => onRemove(item)}
                >
                  remove <IoCloseOutline className="text-lg" />
                </button>
              </div>
              {/* {countVisualHandler('flex md:hidden !mt-0', handleInputChange, onAddQty, onReduceQty, item)} */}
            </div>
          </div>
          {/* <div className="flex justify-end gap-3 items-center mt-4"> */}
            {/* {countVisualHandler('flex', handleInputChange, onAddQty, onReduceQty, item)} */}
            {/* <button
              className="font-semibold font-signika text-[#F60362] text-xs flex items-center justify-center gap-1 px-3 leading-3 uppercase"
              onClick={() => onRemove(item)}
            >
              remove <IoCloseOutline className="text-lg" />
            </button> */}
          {/* </div> */}
          {index < products.length - 1 && <div className="bg-[#d4e8ed] h-[1px] my-4"></div>}
        </div>
      ))}
    </div>
  );
};
