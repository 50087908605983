import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useEffect, useState, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ConvertAUD from '../../../../../utils/convertAUD';
import CustomButton from '../../../../general/button';
import useHandleAddProduct from '../../../../../utils/handleAddProduct';

const FundraiserProductBox = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const ref = useRef(null);
    const refImg = useRef(null);
    const refLazyImg = useRef(null);
    const { 
        goal, 
        hideStatus, 
        disableOnClick = false, 
        onClick,
        product,
        currency,
        handleViewItemDataLayer,
        handleIsAnyAmount,
        ShowCurrencies,

    } = props;
    const [showMore, setShowMore] = useState(false);
    const [isOverflow, setIsOverflow] = useState(false);
    // const [isAnyAmount, setIsAnyAmount] = useState(false);
    const [addProduct] = useHandleAddProduct();
    /* -------------------------------------------------------------------------- */
    const handleHeight = () => {
        setShowMore(!showMore);
    }
    const handlePercentage = (raised, goal) => {
        return `${((Number(raised) / Number(goal)) * 100).toFixed(2)}%`
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        product && handleIsAnyAmount(product)
        product && ref.current.clientHeight > 80 && setIsOverflow(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);
    /* -------------------------------------------------------------------------- */
    return (
        <div className="w-full rounded-xl- shadow-lg bg-white overflow-hidden">
            <div ref={refImg} className="overflow-hidden rounded-md- max-h-[336px]- w-full sm:w-[348px] sm:h-[348px] md:w-[240px] md:h-[240px] lg:w-[325px] lg:h-[325px] xl:w-[410px] xl:h-[410px] 2xl:w-[368px] 2xl:h-[368px]"
            >
                {product ? product.image_link
                    ? <LazyLoadImage
                        ref={refLazyImg}
                        alt={product.alt ? product.alt : product.name}
                        effect="blur"
                        width={`100%`}
                        delayTime={500}
                        afterLoad={() => {
                            handleViewItemDataLayer(product, currency)

                        }}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/general/logo-replace.png";
                        }}
                        src={product.image_link} // use normal <img> attributes as props
                    />
                    : <img className='w-full' src="/images/general/logo-replace.png" alt="" />
                    : <Skeleton height={344} />
                }
            </div>
            <div className="p-2 md:p-3 md:pb-5">
                <h3 className="text-[#252A34] text-sm sm:text-md md:text-md lg:text-lg xl:text-xl text-center font-medium h-[34px] sm:h-[32px] lg:h-[48px]  my-2 leading-4 md:leading-none">
                    {product ? product.name : <Skeleton height={48} />}
                </h3>
                <div className="relative">
                    {product ? <>
                        <div className={`text-center my-2 relative overflow-hidden ${showMore ? 'h-auto' : 'max-h-[60px] md:max-h-[80px]'} ${(product.short_description || product.description).split(' ').length > 28 ? 'mb-4' : 'mb-4'}`}>
                            <p ref={ref} className={`text-[#78716C] text-xs sm:text-sm overflow-hidden transition-all duration-200 min-h-[79px]`}>
                                {product.short_description || product.description}
                            </p>
                        </div>
                        {isOverflow && <span className='text-[#00a3da] block w-full absolute cursor-pointer -bottom-5 text-center' onClick={() => handleHeight()}>{showMore ? 'Show less' : 'Show more'}</span>}
                    </> : <Skeleton count={4} />}
                </div>
                {!hideStatus &&
                    <div className="basis-full flex-col md:basis-1/2 flex justify-center pt-2 md:pt-4">
                        <div className="flex items-center justify-between mb-2">
                            <p className='text-sm text-[#78716C]'>Donation</p>
                            <p className='text-sm text-[#78716C]'>{handlePercentage(ConvertAUD(currency, product.raised, false), ConvertAUD(currency, goal, false))}</p>
                        </div>
                        <div className="flex w-full h-2 bg-[#F60362] bg-opacity-10 mb-2">
                            <div
                                className={`bg-[#F60362]`}
                                style={{ width: `${handlePercentage(ConvertAUD(currency, product.raised, false), ConvertAUD(currency, goal, false))}` }}></div>
                        </div>
                        <div className="flex flex-wrap w-full">
                            <p className='text-sm text-[#78716C] basis-full lg:basis-1/2'><span className='w-[46px] inline-block lg:w-auto'>Raised:</span> {ShowCurrencies(currency, ConvertAUD(currency, product.raised, false))}</p>
                            <p className='text-sm text-[#78716C] basis-full lg:basis-1/2 text-left lg:text-right'><span className='w-[46px] inline-block lg:w-auto'>Goal:</span> {ShowCurrencies(currency, ConvertAUD(currency, goal, false))}</p>
                        </div>
                    </div>
                }
                <div className="basis-full flex justify-center items-center mt-8">
                    {product
                        ? <CustomButton
                            onClick={() => { disableOnClick ? onClick() : addProduct({product: product, currency: currency}) }}
                            title={'Donate Now'}
                            className={``} />
                        : <Skeleton height={43} />
                    }
                </div>
            </div>
        </div>
    );
}

export default FundraiserProductBox;
