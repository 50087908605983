import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import useOutsideClick from '../../hooks/ui/useOutsideClick';
import { closeSheet, selectSheetVisibility } from '../../store/sheetToggler';

export const Sheet = ({ children, refTo }) => {
  const ref = useRef(null);
  const isVisible = useSelector(selectSheetVisibility);
  const dispatch = useDispatch();

  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showSheet, setShowSheet] = useState(false);

  const handleClick = () => {
    dispatch(closeSheet());
  };
  useOutsideClick(ref, handleClick);

  useEffect(() => {
    if (isVisible) {
      setShowBackdrop(true);
      setShowSheet(true);
    } else {
      setTimeout(() => setShowSheet(false), 250);
      setTimeout(() => setShowBackdrop(false), 500);
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      setTimeout(() => {
        document.body.style.overflow = 'auto';
      }, 500);
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  return (
    <div
      className={`fixed top-0 z-[200] min-h-[100svh] backdrop-blur-lg h-full backdrop-brightness-50 overflow-y-auto overflow-x-hidden w-full flex justify-end ${
        showBackdrop ? 'right-0' : 'right-[110%]'
      }`}
      style={{
        WebkitOverflowScrolling: 'touch',
        willChange: 'transform',
      }}
    >
      <div
        ref={ref}
        className={`w-full md:max-w-[500px] h-full min-h-max transition-all duration-300 ease-in-out bg-[#0286c3] sheet-bg-image ${
          showSheet ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div ref={refTo} className="h-full">
          {children}
        </div>
      </div>
    </div>
  );
};
