import React from 'react';

const PrayerTimesPageContent = (props) => {

    const {city, namazOrSalah} = props

    return (
        <div className="mb-4 p-3 md:p-4 bg-blue-50 rounded-lg mx-2 md:mx-auto max-w-[800px] m-2">
            <div className="text-left">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                    {namazOrSalah} Time in {
                    city?.replace(/-/g, " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase())
                } | Daily {namazOrSalah} Time
                </h2>
                <p className="text-gray-600 text-base md:text-lg leading-relaxed">
                    Are you looking for accurate <span
                    className="font-bold">{namazOrSalah} time in {city}?</span> Observing{" "}{namazOrSalah} at the correct time is an
                    essential part of daily life for Muslims. Our comprehensive {namazOrSalah} timetable helps you stay
                    updated on <span className="font-bold">Fajr, Zuhr, Asr, Maghrib, and Isha</span> times in {city} so
                    you can perform your religious
                    obligations without any hassle.
                </p>
                <br/>
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                    Why Check {namazOrSalah} Times in {city}?
                </h2>

                <ol className="space-y-6 list-decimal pl-5">
                    <li className="text-gray-800">
                        <span className="font-bold">Accurate & Reliable:</span> We use verified sources to provide the
                        most accurate {namazOrSalah} time for {city}, ensuring you never miss a prayer. You can also
                        select the
                        different calculation methods to match you {namazOrSalah} time in {city}
                    </li>
                    <li className="text-gray-800">
                        <span className="font-bold">Convenient & Accessible:</span> Whether you're a resident or
                        visiting {city}, our easy-to-read schedule helps you plan your day around prayer times.
                    </li>
                    <li className="text-gray-800">
                        <span className="font-bold">Local Time Adjustments:</span> Our listings take into account <span
                        className="font-semibold">time zone</span> and <span className="font-semibold">daylight variations</span>,
                        so you get the correct {namazOrSalah} time every day.
                    </li>
                </ol>

                <h3 className="text-xl font-bold text-gray-800 mt-8 mb-6">
                    How to Use Our {namazOrSalah} Schedule
                </h3>

                <ol className="space-y-6 list-decimal pl-5">
                    <li className="text-gray-800">
                        <span className="font-bold">Review the Five Daily {namazOrSalah}:</span> Find the updated
                        timings for
                        Fajr, Dhuhr, Asr, Maghrib, and Isha.
                    </li>
                    <li className="text-gray-800">
                        <span className="font-bold">Stay Informed:</span> Bookmark this page to quickly check current
                        prayer times whenever you need them in {city}.
                    </li>
                    <li className="text-gray-800">
                        <span className="font-bold">Plan Ahead:</span> Use our timetable to plan your daily routine,
                        whether you're at home, work, or traveling around {city}.
                    </li>
                </ol>
                <br/>
                <p className="text-gray-800 mb-6 leading-relaxed">
                    By relying on our up-to-date <span
                    className="font-bold">{namazOrSalah} time in {city}</span> resource,
                    you can focus on what matters most—your spiritual growth and connection. Remember to share
                    this page with others in your community so everyone can benefit from accurate prayer schedules.
                </p>

                <p className="text-gray-800 text-lg">
                    Stay on top of your daily prayers with our easy-to-access <span className="font-bold">
                    {namazOrSalah} schedule for {city}</span>—and never miss {namazOrSalah} again!
                </p>
            </div>
        </div>
    );
};

export default PrayerTimesPageContent;