import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-new';
import { useSelector } from 'react-redux';
import SeoData from '../../../utils/seo.json';
import { selectProducts } from '../../../store/products';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../../store/user';
import WidgetHome from '../../../components/widgets/widgetHome';
import Top10ProductBox from './top10ProductBox';
import services from '../../../services';
import moment from 'moment';
import NewHelmetLayout from '../../../components/general/newHelmetLayout';
import MaxWidthContainer from '../../../components/general/maxWidthContainer';
import ProductsTabVertical from '../../../components/general/productsTabVertical';


const Top10Products = ({page = 'general'}) => {
  // const [products, setProducts] = useState();
  const products = useSelector(state => selectProducts(state));
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [topProducts, setTopProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [view, setView] = useState('grid');
  const palestineProductID = [74,136,250,251,252,253,254,255,335,336,337,338,339,352,353,354,355,356,357,358,371,372,373,374,375,376,399,400,401,402,403,404,405,406,409,480,481,482,546,547,548,549,550,551,552,611,624,592,593,594,595,596,597,598,599,600,601,602,604,605,606,607,623,642,643,644,646,647,648,649];
  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    getTop10Products()
  };
  const getTop10Products = async () => {
    // setIsLoading(true);
    let tempTopProducts = [];
    let tempGeneralSearch = {
      from: new Date().toISOString().split('T')[0],
      to: new Date().toISOString().split('T')[0],
      host: window.location.hostname,
      limit: 11
    };
    let tempPalestineSearch = {
      from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      limit: 11,
      products: palestineProductID
    };
    let tempSearch = page === 'general' ? tempGeneralSearch : tempPalestineSearch
    try {
      const response = await services.getTop20Products(tempSearch);
      // console.log("ProductsTransactionTotalReport", response);
      let temp = [];
      response.data.map(item => {
        item.product_name !== 'Admin Contribution' && temp.push(item)
        return item;
      })
      let compare = (a, b) => {
        if (a.total_sales < b.total_sales) {
          return -1;
        }
        if (a.total_sales > b.total_sales) {
          return 1;
        }
        return 0;
      }
      let sortable = temp.sort(compare).reverse();
      // let sortable = topProducts.sort(compare).reverse();
      let _top10 = sortable.slice(0, 11);
      // console.log("sortable", sortable);
      // console.log("sortable", _top10);
      products.map(item => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            _top10.map((_item,index) => {
              if (inner_item_product.name === _item.product_name) {
                tempTopProducts[index] = inner_item_product
              }
              return null;
            })
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
      // setProductsCount(tempProductsCount);
      // setProductsName(tempProductsName);
      // setTopProducts(_top10)
      function uniqBy(a) {
        var seen = {};
        return a.filter(function (item) {
          var k = item.creator;
          return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        })
      }
      let tempNewProducts = uniqBy(tempTopProducts)
      tempNewProducts = tempNewProducts.slice(0, 10);
      tempNewProducts = tempNewProducts.filter(n => n)
      // console.log("tempNewProducts=", tempNewProducts)
      setTopProducts(tempNewProducts)
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.error('error========', error);
    }
  }
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
  }, [selectedCurrencyStore])
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout showRegularImg={false} hideDonateNowButton={true}>
      {seo &&
        <Helmet>
          <title>{`${seo['appeals']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['appeals']['description']}`} />
        </Helmet>
      }
      <WidgetHome selectedCurrency={(e) => setSelectedCurrency(e.value)} />
      <div className="pt-5 bg-[#eaf7ff]">
        <div className="w-full px-4 md:px-2 lg:px-0">
          <h1 className="w-full text-center text-4xl md:text-5xl lg:text-7xl text-[#253b7e] py-2 font-['Gotcha'] mt-6">
            {page === 'general'
            ? 'Top 10 Most Popular'
            : 'Most Popular Palestine Appeals'
            }
          </h1>
        </div>
      </div>
      <ProductsTabVertical
        sections={[
          {
            products: topProducts.filter(prod => prod.name !== 'Pakistan Solar Powered Well'),
          },
        ]}
      />
      <section className='py-8 md:py-12 lg:py-16 bg-white'>
        <MaxWidthContainer>
            <div className="flex justify-center">
              <h1 className="md:text-center font-montserratBold text-[#093686] leading-[30px] sm:leading-[60px] text-2xl md:text-[48px] w-full sm:w-10/12 mb-8 md:mb-12 lg:mb-16">
                Save Lives Now –
                <span className="text-[#F60362]"> Your Chance to Make a Difference</span>
              </h1>
            </div>
            <div className="space-y-7 md:space-y-10">
              <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                Every day, countless lives hang in the balance. Children go to sleep hungry, families struggle to survive, and communities devastated by war and natural
                disasters cry out for help. But you can be their hope.
              </p>
              <p className="text-base md:text-xl font-montserratMedium text-[#78716C]">
                When you support the Top 10 Appeals at MATW Project, you’re not just donating – you’re transforming lives. From providing life-saving food for orphans to
                restoring dignity for families in war zones, your generosity reaches where it’s needed most
              </p>
              <div className='flex flex-col gap-2'>
                <span className="text-xl font-montserratBold text-[#253B7E]">
                 1. Save Lives in Gaza
                </span>
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C] mt-2">
                  In the face of unimaginable hardship, families in Gaza fight to survive each day. Your donation provides critical food, water, and medical
                  supplies to those living through unspeakable challenges. Be the lifeline they so desperately need.
                </p>
              </div>
              <div className='flex flex-col gap-2'>
                <span className="text-xl font-montserratBold text-[#253B7E]">
                 2. Lebanon Emergency Appeal
                </span>
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C] mt-2">
                  Lebanon’s economic collapse has left families without hope. Every dollar you give delivers essential aid – a warm meal, clean water,
                  or vital healthcare – to people who have lost everything.
                </p>
              </div>
              <div className='flex flex-col gap-2'>
                <span className="text-xl font-montserratBold text-[#253B7E]">
                 3. Where Most Needed
                </span>
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C] mt-2">
                  When disaster strikes, every second counts. Your donation here gives MATW Project the flexibility to respond instantly, wherever
                  the need is greatest. You’re empowering us to save lives, right when it matters most.
                </p>
              </div>
              <p className="text-base md:text-xl font-montserratMedium text-[#78716C] mt-2">
                Every dollar or pound you give is a lifeline. Every donation is hope.
              </p>
              <p className="text-base md:text-xl font-montserratMedium text-[#78716C] mt-2">
                At MATW Project, we ensure 100% of your contributions go directly to those in need. But the clock is ticking for so many vulnerable souls.
              </p>
              <div className='flex flex-col gap-2'>
                <span className="text-xl font-montserratBold text-[#253B7E]">
                  Act now. Your generosity today can create brighter tomorrows.
                </span>
                <p className="text-base md:text-xl font-montserratMedium text-[#78716C] mt-2">
                  Click on any of the appeals above to begin your journey of compassion. Together, we can build a world of love, hope, and dignity for all.
                </p>
              </div>
            </div>
        </MaxWidthContainer>
      </section>
    </NewHelmetLayout>
  );
};

export default Top10Products;
