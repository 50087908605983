import { Builder } from '@builder.io/react';
import React from 'react';
const PageBanner = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    desktopBanner,
    mobileBanner,
    alt = ''
  } = props;
  /* -------------------------------------------------------------------------- */

  return <section className="flex justify-center">
    <img src={desktopBanner} alt={alt} className='max-[500px]:hidden w-full' />
    <img src={mobileBanner || desktopBanner} alt={alt} className='min-[501px]:hidden w-full' />
  </section>;
};
Builder.registerComponent(PageBanner, {
  name: 'PageBanner',
  inputs: [
    {
      name: 'mobileBanner',
      type: 'file',
      required: true,
      allowedFileTypes: ['png', 'jpg', 'jpeg'],
      description: 'Banner for the Mobile size'
    },
    {
      name: 'desktopBanner',
      type: 'file',
      required: true,
      allowedFileTypes: ['png', 'jpg', 'jpeg'],
      description: 'Banner for the Desktop size'
    },
    {
      name: 'alt',
      type: 'string',
      required: true,
      defaultValue: '',
      description: 'Alt text for the banner image'
    },
  ]
});
export default PageBanner;