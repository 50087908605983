import { Builder } from '@builder.io/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import RamadanTabContent from '../../../../../general/ramadanTabs2/tab-content2';
import services from '../../../../../../services';
import { selectProducts } from '../../../../../../store/products';
import { useSelector } from 'react-redux';
import ZakatCalculatorWidgetV2 from '../zakatCalculatorWidgetV2';
const RamadanTabsWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { tabs, showZakatCalculator } = props;
  const contentRef = useRef(null);
  // const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  /* -------------------------------------------------------------------------- */
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const [tabTopProducts, setTabTopProducts] = useState([]);
  const [tabBottomeProducts, setTabBottomeProducts] = useState([]);
  const [topCardProduct, setTopCardProduct] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const _allProducts = useSelector(state => selectProducts(state));
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  const getAllProducts = _allProducts => {
    setIsLoaded(true);
    let tempSelectedTopProducts = [];
    let tempSelectedBottomProducts = [];
    let tempSelectedTopCardProduct = [];
    // Initialize tempSelectedProducts with an empty products array for each tab
    tabs.forEach(() => {
      tempSelectedTopCardProduct.push({
        products: [],
      });
      tempSelectedTopProducts.push({
        products: [],
      });
      tempSelectedBottomProducts.push({
        products: [],
      });
    });
    _allProducts.forEach(item => {
      item.sub_categories.forEach(innerItem => {
        innerItem.products.forEach(innerProduct => {
          tabs.map((tabItem, tabIndex) => {
            if (tabItem.topProducts && tabItem.topProducts.includes(innerProduct.creator)) {
              tempSelectedTopProducts[tabIndex].products.push(innerProduct);
            }
            if (tabItem.bottomProducts && tabItem.bottomProducts.includes(innerProduct.creator)) {
              tempSelectedBottomProducts[tabIndex].products.push(innerProduct);
            }
            if (tabItem.topCardProduct && tabItem.topCardProduct === innerProduct.creator) {
              tempSelectedTopCardProduct[tabIndex].products.push(innerProduct);
            }
          });
        });
      });
    });
    setTabTopProducts(tempSelectedTopProducts);
    setTabBottomeProducts(tempSelectedBottomProducts);
    setTopCardProduct(tempSelectedTopCardProduct);
  };
  useEffect(() => {
    if (tabs.length > 0) {
      _allProducts && Object.keys(_allProducts).length && getAllProducts(_allProducts);
    }
  }, [_allProducts, tabs]);
  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]">
      <div className="md:container w-full mx-auto px-0 py-4 md:py-12">
        <div className="flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
          {tabs.map(({ title }, index) => (
            <button
              key={`btn_${index}`}
              onClick={() => setActiveTabProducts(index)}
              className={`${
                index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'
              } text-[14px] md:text-base rounded-full max-[500px]:col-span-2 p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase font-['Montserrat'] font-bold`}
            >
              {title}
            </button>
          ))}

          {/* Add the Zakat tab dynamically if needed */}
          {showZakatCalculator && (
            <button
              onClick={() => setActiveTabProducts(tabs.length)}
              className={`${
                activeTabProducts === tabs.length ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'
              } text-[14px] md:text-base rounded-full max-[500px]:col-span-2 p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase font-['Montserrat'] font-bold`}
            >
              ZAKAT
            </button>
          )}
        </div>

        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
          <div className="flex flex-col w-full justify-center">
            {tabs.map(
              (item, index) =>
                index === activeTabProducts && (
                  <React.Fragment key={`description_${index}`}>
                    <div className="flex w-full justify-center mb-6">
                      <p className="text-center text-white text-[18px] md:text-3xl md:w-2/3">{item.description}</p>
                    </div>
                    <div ref={contentRef}>
                      <RamadanTabContent
                        topProducts={tabTopProducts[index] ? tabTopProducts[index].products : []}
                        bottomProducts={tabBottomeProducts[index] ? tabBottomeProducts[index].products : []}
                        cardTitle={tabs[index] ? tabs[index].cardTitle : ''}
                        topCardTitle={tabs[index] ? tabs[index].topCardTitle : ''}
                        topCardProduct={topCardProduct[index] ? topCardProduct[index].products : []}
                        topCardImageBlue={tabs[index] ? tabs[index].topCardImageBlue : ''}
                        isLoaded={isLoaded}
                      />
                    </div>
                  </React.Fragment>
                ),
            )}

            {/* Render Zakat Calculator tab */}
            {showZakatCalculator && activeTabProducts === tabs.length && (
              <React.Fragment>
                <div className="flex w-full justify-center mb-6">
                  <p className="text-center text-white text-[18px] md:text-3xl md:w-2/3 leading-relaxed">
                    Transform entire communities, lifting people out of poverty. 
                  </p>
                </div>

                <div ref={contentRef} className="w-full  justify-center items-center md:container md:mx-auto bg-white rounded-md p-2 md:p-4">
                  <div className="flex w-full justify-center px-6 text-center">
                    <h2 className="text-3xl font-bold text-primaryDark mb-4">
                      The Zakat Calculator can help you find the amount <br />
                      so that it does not become more or less than nisaab.
                    </h2>
                  </div>

                  <ZakatCalculatorWidgetV2 />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
Builder.registerComponent(RamadanTabsWidget, {
  name: 'RamadanTabsWidget2',
  inputs: [
    {
      name: 'tabs',
      type: 'list', // Defines the input as a list (array)
      defaultValue: [], // Initializes with an empty array
      minRows: 0, // Optional: Minimum number of items
      maxRows: 6, // Optional: Maximum number of items
      defaultItem: { title: '', description: '', cardTitle: '', topCardTitle: '', topCardProduct: '', topProducts: [], bottomProducts: [] }, // Defines the structure of each item
      subFields: [
        {
          name: 'title',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Tab Title.', // Provides guidance to users
        },
        {
          name: 'description',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Description.', // Provides guidance to users
        },
        {
          name: 'cardTitle',
          type: 'string', // Each item is a string
          required: false, // Makes this field mandatory
          helpText: 'Enter the Card Title.', // Provides guidance to users
        },
        {
          name: 'topCardTitle',
          type: 'string', // Each item is a string
          required: false, // Makes this field mandatory
          helpText: 'Enter the top Card Title.', // Provides guidance to users
        },
        {
          name: 'topCardImageBlue',
          type: 'file',
          required: true,
          allowedFileTypes: ['png', 'jpg', 'jpeg'],
          description: 'Image for the Food for Orphan main',
        },
        {
          name: 'topCardProduct',
          type: 'string', // Each item is a string
          required: false, // Makes this field mandatory
          helpText: 'Enter the top Card Product.', // Provides guidance to users
        },
        {
          name: 'topProducts',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Top Products.', // Provides guidance to users
        },
        {
          name: 'bottomProducts',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Bottom Products.', // Provides guidance to users
        },
      ],
    },
    {
      name: 'showZakatCalculator',
      type: 'boolean',
      defaultValue: false,
      helpText: 'Toggle to display the Zakat Calculator.',
    },
  ],
});
export default RamadanTabsWidget;
