// utils/calculationUtils.js
import { DateTime } from 'luxon';

/**
 * Calculates the donation schedule based on the selected option and conditions.
 * @param {Object} params
 * @param {string} params.startDate - The adjusted start date of donation in ISO format.
 * @param {string} params.timeZone - The user's time zone.
 * @param {Array} params.selectedProducts - Array of selected products with quantities.
 * @param {string} params.donationOption - 'equal' or 'custom'.
 * @param {Array} params.selectedConditions - Array of selected special condition values.
 * @param {Object} params.multipliers - Multiplier values for each condition.
 * @param {string} params.currency - The selected currency.
 * @param {number} params.numberOfNights - The number of nights to donate.
 * @returns {Object} An object containing the donation schedule and any difference due to rounding.
 */
export const calculateDonations = ({
  startDate,
  timeZone,
  selectedProducts,
  donationOption,
  selectedConditions,
  multipliers,
  currency,
  numberOfNights,
}) => {
  // Convert startDate to Luxon DateTime in specified timezone
  const startDateLuxon = DateTime.fromISO(startDate, { zone: timeZone }).startOf('day');

  // Calculate exact totalAmount based on currency, price, and quantity
  const exactTotalAmount = selectedProducts.reduce((sum, p) => {
    const price = p[currency.toLowerCase()];
    const quantity = p.quantity || 0;
    if (typeof price === 'number') {
      return sum + price * p.quantity;
    }
    return sum;
  }, 0);

  // Round the totalAmount to the nearest integer
  const totalAmountRounded = Math.round(exactTotalAmount);

  // Generate donation dates based on the number of nights
  const donationDates = [];
  for (let i = 0; i < numberOfNights; i++) {
    donationDates.push(startDateLuxon.plus({ days: i }));
  }

  if (donationOption === 'equal') {
    // Equal Division Logic
    const perDayAmount = totalAmountRounded / numberOfNights;
    const donationSchedule = donationDates.map(date => ({
      date: date.toISODate(),
      amount: Math.round(perDayAmount),
      isMultiplierNight: false,
      products: [],
      hasProduct: false,
      remainedAmount: 0,
      shares: 1, // Base share
    }));

    // Adjust for rounding differences
    const sumRounded = donationSchedule.reduce((sum, day) => sum + day.amount, 0);
    let difference = totalAmountRounded - sumRounded;

    const adjustedDonationSchedule = donationSchedule.map((day, index) => {
      if (difference > 0 && index < difference) {
        return { ...day, amount: day.amount + 1 };
      } else if (difference < 0 && index < Math.abs(difference)) {
        return { ...day, amount: day.amount - 1 };
      }
      return day;
    });

    const handleAddPrd = () => {
      // let tempadjustedDonationSchedule = adjustedDonationSchedule2;
      // const tempadjustedDonationSchedule = structuredClone(adjustedDonationSchedule2);
      const tempadjustedDonationSchedule = JSON.parse(JSON.stringify(adjustedDonationSchedule));
      let _arr = [];
      let _totalPrds = 0;
      selectedProducts.map(prd => {
        let _total = prd.quantity * prd[currency.toLowerCase()];
        _totalPrds += _total;
      });
      selectedProducts.map(prd => {
        let _total = prd.quantity * prd[currency.toLowerCase()];
        _arr.push({ product: prd, percentage: (_total * 100) / _totalPrds });
      });
      console.log('_arr=', _arr);
      let tempArr = [];
      tempadjustedDonationSchedule.map((item, index) => {
        // let tempPrds = [];
        // _arr.map(arr => {
        //   // let tempprd = arr.product;
        //   const tempprd = JSON.parse(JSON.stringify(arr.product));
        //   tempprd.quantity = (item.amount * arr.percentage) / 100;
        //   // item.products.push(tempprd)
        //   tempPrds.push(tempprd)
        // })
        // tempArr[index] = {amount: item.amount, date: item.date, isMultiplierNight: item.isMultiplierNight, products: tempPrds}

        let tempPrds = [];

        // Step 1: Calculate initial quantities
        let totalQuantity = 0;
        _arr.forEach(arr => {
          const tempprd = JSON.parse(JSON.stringify(arr.product));
          tempprd.quantity = (item.amount * arr.percentage) / 100;
          totalQuantity += tempprd.quantity;
          tempPrds.push(tempprd);
        });

        // Step 2: Round quantities
        let roundedTotal = 0;
        tempPrds.forEach(prd => {
          prd.quantity = Math.round(prd.quantity);
          roundedTotal += prd.quantity;
        });

        // Step 3: Adjust quantities to match item.amount
        const difference = item.amount - roundedTotal;

        // Distribute the difference
        for (let i = 0; i < Math.abs(difference); i++) {
          const indexToAdjust = i % tempPrds.length; // Loop through products
          if (difference > 0) {
            tempPrds[indexToAdjust].quantity += 1; // Increase quantity
          } else {
            tempPrds[indexToAdjust].quantity -= 1; // Decrease quantity
          }
        }

        // Step 4: Store the adjusted object
        tempArr[index] = {
          amount: item.amount,
          date: item.date,
          isMultiplierNight: item.isMultiplierNight,
          products: tempPrds,
          currency: currency,
        };
      });
      localStorage.setItem('DAILY_DONATION', JSON.stringify(tempArr));
      // console.log('all prds=', tempadjustedDonationSchedule);
      console.log('all prds=', tempArr);
    };
    handleAddPrd();

    return {
      donationSchedule: adjustedDonationSchedule,
      difference: totalAmountRounded - adjustedDonationSchedule.reduce((sum, day) => sum + day.amount, 0),
    };
  } else if (donationOption === 'custom') {
    // Custom Conditions Logic

    if (selectedConditions.length === 0) {
      throw new Error('At least one special condition must be selected for custom donations.');
    }

    // Initialize shares for each day
    const dayShares = donationDates.map(date => ({
      date: date.toISODate(),
      shares: 1, // Base share
      isMultiplierNight: false,
    }));

    // Determine the last ten nights
    const lastTenNightsStartIndex = Math.max(0, numberOfNights - 10);
    const lastTenNightsIndices = [];
    for (let i = lastTenNightsStartIndex; i < numberOfNights; i++) {
      lastTenNightsIndices.push(i);
    }

    // Apply special condition shares based on selected multipliers
    selectedConditions.forEach(condition => {
      switch (condition) {
        case 'friday':
          dayShares.forEach((day, index) => {
            const dateObj = DateTime.fromISO(day.date, { zone: timeZone });
            if (dateObj.weekday === 5) {
              // Friday
              day.shares += multipliers.friday;
              day.isMultiplierNight = true;
            }
          });
          break;
        case 'lastTenNights':
          dayShares.forEach((day, index) => {
            if (lastTenNightsIndices.includes(index)) {
              day.shares += multipliers.lastTenNights;
              day.isMultiplierNight = true;
            }
          });
          break;
        case 'oddNights':
          dayShares.forEach((day, index) => {
            // Adjusted: Apply odd night multiplier only within the last ten nights
            if (lastTenNightsIndices.includes(index)) {
              const nightNumber = index + 1; // Start counting from 1
              if (nightNumber % 2 !== 0) {
                // Odd night
                day.shares += multipliers.oddNights;
                day.isMultiplierNight = true;
              }
            }
          });
          break;
        case 'laylatulQadr':
          if (numberOfNights >= 27) {
            dayShares.forEach((day, index) => {
              if (index === 26) {
                // 27th night (0-based index)
                day.shares += multipliers.laylatulQadr;
                day.isMultiplierNight = true;
              }
            });
          }
          break;
        default:
          break;
      }
    });

    // Calculate total shares
    const totalShares = dayShares.reduce((sum, day) => sum + day.shares, 0);

    // Calculate share value
    const shareValue = totalShares > 0 ? exactTotalAmount / totalShares : 0;

    // Assign amounts based on shares
    const donationSchedule = dayShares.map(day => ({
      date: day.date,
      amount: Math.round(shareValue * day.shares),
      isMultiplierNight: day.isMultiplierNight,
      products: [],
      hasProduct: false,
      remainedAmount: 0,
      shares: day.shares,
    }));

    // Calculate the sum of rounded amounts
    const sumRounded = donationSchedule.reduce((sum, day) => sum + day.amount, 0);

    // Calculate the difference to reach totalAmountRounded
    let difference = totalAmountRounded - sumRounded;

    // Distribute the difference by adding or subtracting 1 from the first 'difference' days
    const adjustedDonationSchedule = donationSchedule.map((day, index) => {
      if (difference > 0 && index < difference) {
        return { ...day, amount: day.amount + 1 };
      } else if (difference < 0 && index < Math.abs(difference)) {
        return { ...day, amount: day.amount - 1 };
      }
      return day;
    });

    // Final sum after adjustment
    const finalSum = adjustedDonationSchedule.reduce((sum, day) => sum + day.amount, 0);

    // Calculate final difference (should be zero)
    const finalDifference = finalSum - totalAmountRounded;
    console.log('adjustedDonationSchedule=', adjustedDonationSchedule);

    // Handle product distribution if needed (similar to existing logic)
    // ...

    const handleAddPrd = () => {
      // let tempadjustedDonationSchedule = adjustedDonationSchedule2;
      // const tempadjustedDonationSchedule = structuredClone(adjustedDonationSchedule2);
      const tempadjustedDonationSchedule = JSON.parse(JSON.stringify(adjustedDonationSchedule));
      let _arr = [];
      let _totalPrds = 0;
      selectedProducts.map(prd => {
        let _total = prd.quantity * prd[currency.toLowerCase()];
        _totalPrds += _total;
      });
      selectedProducts.map(prd => {
        let _total = prd.quantity * prd[currency.toLowerCase()];
        _arr.push({ product: prd, percentage: (_total * 100) / _totalPrds });
      });
      console.log('_arr=', _arr);
      let tempArr = [];
      tempadjustedDonationSchedule.map((item, index) => {
        // let tempPrds = [];
        // _arr.map(arr => {
        //   // let tempprd = arr.product;
        //   const tempprd = JSON.parse(JSON.stringify(arr.product));
        //   tempprd.quantity = (item.amount * arr.percentage) / 100;
        //   // item.products.push(tempprd)
        //   tempPrds.push(tempprd)
        // })
        // tempArr[index] = {amount: item.amount, date: item.date, isMultiplierNight: item.isMultiplierNight, products: tempPrds}

        let tempPrds = [];

        // Step 1: Calculate initial quantities
        let totalQuantity = 0;
        _arr.forEach(arr => {
          const tempprd = JSON.parse(JSON.stringify(arr.product));
          tempprd.quantity = (item.amount * arr.percentage) / 100;
          totalQuantity += tempprd.quantity;
          tempPrds.push(tempprd);
        });

        // Step 2: Round quantities
        let roundedTotal = 0;
        tempPrds.forEach(prd => {
          prd.quantity = Math.round(prd.quantity);
          roundedTotal += prd.quantity;
        });

        // Step 3: Adjust quantities to match item.amount
        const difference = item.amount - roundedTotal;

        // Distribute the difference
        for (let i = 0; i < Math.abs(difference); i++) {
          const indexToAdjust = i % tempPrds.length; // Loop through products
          if (difference > 0) {
            tempPrds[indexToAdjust].quantity += 1; // Increase quantity
          } else {
            tempPrds[indexToAdjust].quantity -= 1; // Decrease quantity
          }
        }

        // Step 4: Store the adjusted object
        tempArr[index] = {
          amount: item.amount,
          date: item.date,
          isMultiplierNight: item.isMultiplierNight,
          products: tempPrds,
          currency: currency,
        };
      });
      localStorage.setItem('DAILY_DONATION', JSON.stringify(tempArr));
      // console.log('all prds=', tempadjustedDonationSchedule);
      console.log('all prds=', tempArr);
    };
    handleAddPrd();
    // console.log('all prds=');

    return { donationSchedule: adjustedDonationSchedule, difference: finalDifference };
  } else {
    throw new Error('Invalid donation option selected.');
  }
};
