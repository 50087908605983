import {createSlice} from '@reduxjs/toolkit';

const prayerSlice = createSlice({
    name: 'prayer',
    initialState: {
        prayerName: 'All',
        cityName: 'NA',
        countryName: 'NA',
        setRoute: ''
    },
    reducers: {
        setPrayerName: (state, action) => {
            state.prayerName = action.payload;
        },
        setCountryName: (state, action) => {
            state.countryName = action.payload;
        },
        setCityName: (state, action) => {
            state.cityName = action.payload;
        }
    },
});

export const {setPrayerName, setCountryName, setCityName} = prayerSlice.actions;
export const selectPrayerName = (state) => state.prayer.prayerName;

export const selectCountryName = (state) => state.prayer.countryName;

export const selectCityName = (state) => state.prayer.cityName;
export default prayerSlice.reducer;