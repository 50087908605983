import React from 'react';
import ProductBox from '../../../components/general/productBox';
import MultiProductsBox from '../../../components/general/multiProductsBox';
import { useKeenSlider } from 'keen-slider/react';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import CustomButton from '../../../components/general/button';
import SingleProductBox from '../../../components/general/singleProductBox';
import { ProductBoxCart } from './productBoxCart';
import useCart from '../../../hooks/cart/useCart';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import MultiProductsBoxCart from '../../../components/general/multiProductsBoxCart';

const HomePageTabContent = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    products,
    currency,
    showMultiProductsOnRow = true,
    viewAppealsLink,
    isPalestineRefugee = false,
    arabic = false,
    lang = 'en',
  } = props;

  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const sortedtProducts = products.sort((a, b) => a[selectedCurrencyStore.toLowerCase()] - b[selectedCurrencyStore.toLowerCase()]);
  const productCount = sortedtProducts.length > 1 ? (sortedtProducts.length > 2 ? 2.1 : 2) : 1;
  const {
      handleAddToGiving,
      handleRemoveProduct,
      handleCheckProduct,
    } = useCart()
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className={`flex flex-wrap gap-3 md:gap-4 justify-center items-start w-full`}>
        {sortedtProducts &&
          sortedtProducts.map((item, index) => (
            <>
              <div key={`products__${index}`} className={`w-full sm:max-w-[300px] sm:basis-[calc(50%-8px)] hidden sm:block`}>
                {item.products ? (
                  <MultiProductsBox
                    multiProducts={item.products}
                    staticLink={'/'}
                    redirectUrl={item.redirectUrl}
                    title={item.title}
                    description={item.description}
                    image={item.img}
                    arabic={arabic}
                    _lang={lang}
                    alt={item?.alt}
                    currency={currency ? currency : 'AUD'}
                    showMultiProductsOnRow={showMultiProductsOnRow}
                    className="!rounded-none !p-0 !pb-3 md:!pb-5 md:!p-0"
                    imageContainerClass="!rounded-none"
                    imageClass="!rounded-none"
                    btnContainerClass="!rounded-none"
                    descriptionStyle="px-2 md:px-3"
                    skeletonStyle="px-2"
                    squareButton
                    addToCart={true}
                  />
                ) : (
                  <ProductBox
                    stateVariableObj={{ productName: item.name }}
                    product={item}
                    arabic={arabic}
                    _lang={lang}
                    linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                    isStaticProduct={item.isStaticProduct}
                    staticLink={item.staticLink}
                    staticLinkText={item.staticLinkText}
                    currency={currency ? currency : 'AUD'}
                    titleClasses={`uppercase px-1 !text-lg !h-auto !mt-3 min-h-[56px]`}
                    isPalestineRefugee={isPalestineRefugee}
                    childClasses="!rounded-none !p-0 !pb-3 md:!pb-5 md:!p-0"
                    imageContainerClass="!rounded-none"
                    imageClass="!rounded-none"
                    descriptionStyle="px-2 md:px-3"
                    skeletonStyle="px-2"
                    squareButton
                    addToCart={true}
                  />
                )}
              </div>
              <div className="w-full sm:hidden">

                {item.products ? (
                  <MultiProductsBoxCart
                    product={item}
                    multiProducts={item.products}
                    staticLink={'/'}
                    redirectUrl={item.redirectUrl}
                    title={item.title}
                    description={item.description}
                    image={item.img}
                    arabic={arabic}
                    _lang={lang}
                    alt={item?.alt}
                    currency={currency ? currency : 'AUD'}
                    showMultiProductsOnRow={showMultiProductsOnRow}
                    className="!rounded-none !p-0 !pb-3 md:!pb-5 md:!p-0"
                    imageContainerClass="!rounded-none"
                    imageClass="!rounded-none"
                    btnContainerClass="!rounded-none"
                    descriptionStyle="px-2 md:px-3"
                    skeletonStyle="px-2"
                    squareButton
                    addToCart={true}
                    onCheck={handleCheckProduct}
                    onAdd={handleAddToGiving}
                    onRemove={handleRemoveProduct}
                  />

                ) : (
                  <ProductBoxCart
                    key={index}
                    product={item}
                    onCheck={handleCheckProduct}
                    onAdd={handleAddToGiving}
                    onRemove={handleRemoveProduct}
                    stateVariableObj={{ productName: item.name }}
                    arabic={arabic}
                    _lang={lang}
                    linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                    isStaticProduct={item.isStaticProduct}
                    staticLink={item.staticLink}
                    staticLinkText={item.staticLinkText}
                    currency={currency ? currency : 'AUD'}
                />
                )}
              </div>
            </>
          ))}
        {viewAppealsLink && (
          <div className="flex w-full justify-center items-center py-2 md:py-4">
            <CustomButton
              link={viewAppealsLink}
              title={arabic || lang === 'ar' ? 'عرض كافة الاستئنافات' : lang === 'fr' ? `VOIR TOUS LES APPELS` : 'View all Appeals'}
              className={arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default HomePageTabContent;
