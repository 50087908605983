import React from 'react';
import IftarDonationWidget from './IftarDonationWidget2';

const RamadanTabContent = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    topProducts = [],
    bottomProducts = [],
    cardTitle = '',
    topCardProduct = '',
    topCardTitle = '',
    topCardImageBlue = '',
    
  } = props;
  /* -------------------------------------------------------------------------- */
  return (
    <div className="flex w-full flex-wrap justify-center items-start gap-2 md:gap-4 md:h-auto">
      <IftarDonationWidget
        topList={topProducts}
        bottomList={bottomProducts}
        cardTitle={cardTitle}
        topCardTitle={topCardTitle}
        topCardProduct={topCardProduct}
        topCardImageBlue={topCardImageBlue}
        
      />
    </div>
  );
};
export default RamadanTabContent;
