import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-new';
import {
  // selectSelectedCountryCode,
  selectSelectedCurrency,
} from '../../store/user';
import services from '../../services';
import ProductBox from '../../components/general/productBox';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';
// import ChangeDocumentTitle from '../utils/changeDocumentTitle';
import Skeleton from 'react-loading-skeleton';
// import SeoData from '../../utils/seo.json';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const Products = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const categoryID = params.get('id');
  const [products, setProducts] = useState({
    name: '',
    products: [0, 0, 0, 0, 0, 0, 0, 0],
  });
  const [isLoading, setIsLoading] = useState(true);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [subCategoryName, setSubCategoryName] = useState('');
  const [currentYoutube, setCurrentsetYoutube] = useState('');
  const youtubeVideos = [
    { title: 'Orphan Feast', videoLink: 'NrTJl17DI4c' },
    { title: 'Support An Orphan', videoLink: '0FO3OfWACKY' },
    { title: 'Education', videoLink: 'KeY21bfU1WM' },
    { title: 'Construction', videoLink: 'wfbs9zZD_d4' },
    { title: 'Sacrifice', videoLink: 'VzmIm-Hmzow' },
    { title: 'Project Build', videoLink: 'ALrla77TNdY' },
    { title: 'Sustainable', videoLink: 'jzAZ03Qgf7k' },
    { title: 'Ramadan Emergency', videoLink: 'mUvEP4zxyNc' },
    { title: 'Ramadan Food Aid', videoLink: 'TcaeEoqaenE' },
    { title: 'Iftar', videoLink: 'yjL51TZx3f4' },
    { title: 'Orphan Iftar', videoLink: '5su_UdFn-LA' },
    { title: 'Zakat', videoLink: 'Lrte3Wg9ZxY' },
    { title: 'Eid Gift', videoLink: 'kK-WcUzr_d8' },
    { title: 'Caravan', videoLink: 'ALrla77TNdY' },
    { title: 'Food Aid', videoLink: 'f4Ny1iv04tM' },
    { title: 'Water Aid', videoLink: 'sZPg8vYlxi8' },
    { title: 'Give Zakat', videoLink: 'Lrte3Wg9ZxY' },
    { title: 'Calculate my Zakat', videoLink: 'bcbef_IsUdg' },
    { title: 'Earthquake Appeal', videoLink: 'XRziciz2sZA' },
    { title: 'Palestine', videoLink: 'R6URgeOuMng' },
    { title: 'Health', videoLink: 'Z3DtUoz0scc' },
    { title: 'Build A Masjid', videoLink: '_-B8kF-2FoE' },
    { title: 'Indonesia Masjid', videoLink: '_-B8kF-2FoE' },
    { title: 'Yemen Masjid', videoLink: '_-B8kF-2FoE' },
    { title: 'Build A Water Well', videoLink: 'VBNEEsngrNg' },
    // { title: 'Make A Donation', videoLink: 'gsqwmvSSyos' },
    { title: 'Make A Donation', videoLink: 'tC_L9aTE8eI' },
    { title: 'Emergency Support', videoLink: 'XRziciz2sZA' },
  ];

  // const selectedcountryCode = useSelector(selectSelectedCountryCode);
  // const seo = SeoData[selectedcountryCode];
  const [meta, setMeta] = useState({
    parent: '',
    child: '',
  });
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getProducts = async () => {
    setIsLoading(true);
    try {
      const response = await services.categoryProducts(categoryID);
      // console.log('response get all Products', response.data[0]);

      if (response.data[0]) {
        setProducts(response.data[0]);
        // ChangeDocumentTitle(`${response.data[0].name} - ${response.data[0].category.name} - MATW Project Muslim Charity`);
        setIsLoading(false);
      } else {
        navigate(generateLinkWithLang('/', lang));
      }
    } catch (error) {
      setIsLoading(false);
      console.error('error========', error);
    }
  };
  const getProductsByName = async name => {
    setIsLoading(true);
    try {
      const response = await services.categoryProductsByName(name);
      if (response.data.name === 'Sacrifice') {
        response.data.tile_image = '/images/landings/sadaqah-jariyah/sadaqah-jariyah-sacrifice.webp';
      }
      if (response.data.name === 'Construction') {
        response.data.tile_image = '/images/landings/orphan/orphans-construction.webp';
        response.data.products.map(item => {
          if (item.name === 'Lebanon Orphan Centre') {
            // item.description = `Change lives in Lebanon, Tripoli: Build a loving home for 100+ orphans, and be their beacon of hope.`
          }
          if (item.name === 'Togo Orphan Centre') {
            // item.description = `Expand Togo Orphan Centre: Give orphans a safe haven and a brighter future.`
          }
          return item;
        });
      }
      if (response.data.name === 'Project Build') {
        response.data.tile_image = '/images/landings/current-projects/project-build.webp';

        response.data.products.map(item => {
          if (item.name === 'Secure Orphan Homes In Kpoga Togo') {
            // item.description = `Let's make a difference in the lives of these children. Support secure orphan homes in Kpoga Togo, providing shelter and hope for a better future. Your donation creates safe, loving homes where every child can thrive. Join us in making their dreams come true. Donate now.`
          }
          return item;
        });
      }
      if (response.data.name === 'Caravan') {
        response.data.tile_image = '/images/landings/crisis/caravan.webp';
      }
      if (Object.keys(response.data).length === 0) {
        navigate(generateLinkWithLang('/', lang));
      } else {
        if (response.data.name === 'Health') {
          response.data.tile_image = '/images/landings/sadaqah-jariyah/health-banner.webp';
          let temp = response.data;
          temp.products.map(item => {
            if (item.name === 'Treatment For CP Child') {
              // item.description = 'Donate to help provide prosthetics to disabled Bangladeshis.'
            }
            if (item.name === 'Disability Support') {
              // item.description = 'Empower lives: Donate for prosthetics and wheelchairs.'
            }
            if (item.name === 'Emergency Medical Aid') {
              // item.description = 'Save lives in emergencies: Your donation supports vital medical care.'
            }
            return item;
          });

          let tempProducts = temp.products.map(item => item).filter((item, index) => index > 2 && Number(item.status) === 1 && item);
          // console.log("temp", tempProducts)
          temp.products = tempProducts;
          setProducts(temp);
        } else if (response.data.name === 'Palestine') {
          let temp = response.data;
          let tempProducts = temp.products.map(item => item).filter(item => item.name === 'Palestine Appeal' && Number(item.status) === 1 && item);
          temp.products = tempProducts;
          setProducts(temp);
        } else {
          setProducts(response.data);
        }
        setIsLoading(false);
        youtubeVideos.map(video => video.title === response.data.name && setCurrentsetYoutube(video.videoLink));
        // ChangeDocumentTitle(`${response.data.name} - ${response.data.category ? response.data.category.name : ''} - MATW Project Muslim Charity`);
        // setting meta tags
      }
    } catch (error) {
      console.error('error========', error);
      setIsLoading(false);
    }
  };
  const handleParagraph = text => {
    let tempText = [];
    if (text) {
      tempText = text.split('.');
      return tempText.map((txt, index) => {
        let temp =
          txt === '' ? (
            ''
          ) : (
            <p key={`p${index}`}>
              {txt}.<br />
            </p>
          );
        return temp;
      });
    }
  };
  const camelCaseformat = val => {
    let array = val.split('');
    for (let index = 0; index < array.length; index++) {
      if (array[index] === '-') {
        array[index + 1] = array[index + 1] ? (array[index + 1] = array[index + 1].toUpperCase()) : navigate(generateLinkWithLang('/', lang));
      }
    }
    return array.join('').replaceAll('-', '');
  };
  const RawHTML = ({ children, className = '' }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '') }} />;
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // categoryID ? getProducts() : navigate("/");
    categoryID && getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryID]);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    let pathNames = window.location.pathname.split('/');

    if (pathNames.length > 2) {
      let temp = pathNames[2].split('-');
      temp = temp.join(' ');
      setSubCategoryName(temp);
      getProductsByName(temp);
      let p = camelCaseformat(pathNames[1]);
      let c = camelCaseformat(pathNames[2]);
      setMeta({
        ...meta,
        parent: p,
        child: c,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);
  /* -------------------------------------------------------------------------- */

  return (
    <NewHelmetLayout>
      {/* {seo && seo.hasOwnProperty(meta?.parent) && seo[meta?.parent].hasOwnProperty(meta?.child) &&
                <Helmet>
                    <title>{`${seo[meta.parent][meta.child]['title']}`}</title>
                    <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
                    <meta name="description" content={`${seo[meta.parent][meta.child]['description']}`} />
                </Helmet>
            } */}
      {products.products[0] && (
        <Helmet>
          <title>{`${products ? products.page_title : ''} | MATW Project`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={`${products ? products.page_subtitle : ''}`} />
        </Helmet>
      )}
      {/* <div className="flex md:w-full md:h-[120px] lg:w-full lg:h-[160px] xl:w-full xl:h-[200px] 2xl:w-full 2xl:h-[250px]"> */}
      {!isLoading && (
        <img
          src={products.tile_image}
          alt={products.name}
          className="w-full"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = '/images/general/logo-replace.png';
            currentTarget.className = 'max-h-[150px] mx-auto';
          }}
        />
      )}
      {/* </div> */}
      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />
      <div className="md:container md:mx-auto mt-5">
        <div className="grid grid-cols-12 px-3- md:px-4-">
          <div className="col-span-12 text-center text-6xl text-[#253b7e] flex justify-center items-center py-2- md:py-8- font-['Gotcha'] h-[125px]">
            {!isLoading ? products.name : <Skeleton height={80} />}
          </div>
          <div className="col-start-1 col-span-12 rounded-md">
            <div className="flex flex-row justify-center flex-wrap">
              {products && subCategoryName === 'give zakat' && (
                <div className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                  <ProductBox
                    product={{
                      category_id: 0,
                      id: 0,
                      image_link: '/images/zakat/zakat-give-zakat-zakat-calculator.jpg',
                      name: 'Zakat Calculator',
                      description: 'Use our calculator to find out what you need to give in zakat.',
                      creator: '',
                    }}
                    isStaticProduct={true}
                    currency={selectedCurrency}
                  />
                </div>
              )}
              {products.products.map((item, index) => {
                return (
                  <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                    <ProductBox product={item} currency={selectedCurrency} />
                  </div>
                );
              })}
            </div>
            <div
              className={`col-span-12 rounded-xl mt-6 lg:min-h-[500px]- bg-white p-6 lg:p-12 text-center shadow-lg ${
                products && (products.name === 'Food Packs') | (products.name === 'Cow Share') ? 'hidden' : ''
              }`}
            >
              <h1 className="text-[#00a3da] text-4xl mb-4">{products ? products.page_title : <Skeleton />}</h1>
              <p className="text-[#777] text-xl mb-4">{products ? products.page_subtitle : <Skeleton />}</p>
              <div className="text-[#999] text-md">
                {/* {products ? handleParagraph(products.page_description) : <Skeleton />} */}
                {products ? (
                  products.page_description && products.page_description.includes('<') ? (
                    <RawHTML>{products.page_description}</RawHTML>
                  ) : (
                    handleParagraph(products.page_description)
                  )
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            {currentYoutube && (
              <div className="col-span-12 rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
                <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                  <iframe
                    className="video"
                    width={'100%'}
                    height={'100%'}
                    title={'youtube'}
                    sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                    src={`https://youtube.com/embed/${currentYoutube}?autoplay=0`}
                  ></iframe>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </NewHelmetLayout>
  );
};

export default Products;