import { useLocation } from "react-router-dom";

const useCartBadge = () => {
    const location = useLocation();
    const excludedRoutes = ["/login", "/signup"]; // Routes to exclude

    return !excludedRoutes.includes(location.pathname);
};

export default useCartBadge;
