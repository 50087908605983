import { Builder } from '@builder.io/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import ProductCard from "../../../../general/productCard";
import IsGTMScriptLoaded from "../../../../../utils/isGTMScriptLoaded";
import getSymbolFromCurrency from 'currency-symbol-map';
import { selectSelectedCurrency } from "../../../../../store/user";
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import { useKeenSlider } from 'keen-slider/react';
import Arrow from "../../../../../pages/home/components/tab-arrow";
import { selectProducts } from '../../../../../store/products';
import { checkDomain } from '../../../../../utils/checkDomain';
const ProductBoxes = ({
  products = []
}) => {
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const _allProducts = useSelector(state => selectProducts(state));
  const MutationPlugin = slider => {
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        slider.update();
      });
    });
    const config = {
      childList: true
    };
    slider.on("created", () => {
      observer.observe(slider.container, config);
    });
    slider.on("destroyed", () => {
      observer.disconnect();
    });
  };
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: 'snap',
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: 'center',
      perView: 1,
      spacing: 8
    }
    // eslint-disable-next-line no-use-before-define
  }, [MutationPlugin]);
  const handleIsAnyAmount = product => {
    const hasQuantity = product.quantity ? product.quantity > 1 : false;
    return Number(product.aud) <= 2 && !hasQuantity;
  };
  const handleViewItemDataLayer = (tempProduct, currency) => {
    const dataLayerTemp = {
      event: 'view_item',
      ecommerce: {
        currency: currency.toUpperCase(),
        items: [{
          item_id: tempProduct.creator,
          item_name: tempProduct.name,
          item_brand: tempProduct.category || '',
          item_category: tempProduct.category || '',
          item_description: tempProduct.description || '',
          price: tempProduct[currency.toLowerCase()]
        }]
      }
    };
    window.dataLayer = window.dataLayer || [];
    if (IsGTMScriptLoaded()) {
      window.dataLayer.push(dataLayerTemp);
    }
    const obj = {
      content_type: 'product',
      content_id: tempProduct.creator,
      currency: currency,
      value: tempProduct[currency.toLowerCase()],
      price: tempProduct[currency.toLowerCase()],
      quantity: 1,
      content_category: tempProduct.category || 'donation',
      content_name: tempProduct.name,
      description: tempProduct.description
    };
    if (window.ttq) {
      if (!checkDomain('matwprojectme.org')) {
        window.ttq.instance(localStorage.getItem('TikTokID')).track('ViewContent', obj);
      }
    }
  };
  const ShowCurrencies = (currency, _price) => {
    const tempCurrency = currency?.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let final = '';
    price = price ? price.toLocaleString() : '0';
    switch (tempCurrency) {
      case 'GBP':
      case 'EUR':
        final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        final = `${price}`;
        break;
      case 'IDR':
        final = `Rp${price}`;
        break;
      case 'AED':
        final = `Dh${price}`;
        break;
      default:
        final = `$${price}`;
        break;
    }
    return final;
  };
  const getSelectedProducts = _allProducts => {
    setIsLoaded(true);
    const tempSelectedProducts = [];
    _allProducts.forEach(item => {
      item.sub_categories.forEach(innerItem => {
        innerItem.products.forEach(innerProduct => {
          if (products.includes(innerProduct.creator)) {
            tempSelectedProducts.push(innerProduct);
          }
        });
      });
    });
    setSelectedProducts(tempSelectedProducts);
  };
  useEffect(() => {
    if (products.length > 0) {
      _allProducts && Object.keys(_allProducts).length && getSelectedProducts(_allProducts);
    }
  }, [_allProducts, products]);
  return <>
    <div className="hidden sm:flex w-full px-2 md:px-0 md:container md:mx-auto">
      <div className="flex justify-center flex-wrap w-full">
        {isLoaded ? selectedProducts.length > 0 ? selectedProducts.map((product, index) => <div key={`product-${product.creator}-${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
          <ProductCard product={product} alt={''} _lang={'en'} removeDataLayer={false} isVisibleDescription={true} roundedButton={false} squareButton={false} selectedCurrencyStore={selectedCurrencyStore} handleViewItemDataLayer={handleViewItemDataLayer} handleIsAnyAmount={handleIsAnyAmount} ShowCurrencies={ShowCurrencies} />
        </div>) : <p className="text-center w-full">No products selected.</p> : <Skeleton count={4} />}
      </div>
    </div>
    {selectedProducts.length > 0 && <div className="w-full sm:hidden flex-wrap justify-center items-center relative">
      <div className="flex">
        {instanceRef.current && <div className={`absolute flex justify-center  items-center translate-y-1/2 w-8 h-8 top-[47%] -left-[0px] z-10 cursor-pointer text-white`} onClick={e => e.stopPropagation() || instanceRef.current?.prev()}>
          <LiaAngleLeftSolid size={25} />
        </div>}
        {instanceRef.current && <div className={`absolute flex justify-center items-center translate-y-1/2 w-8 h-8 top-[47%] -right-[0px] z-10 cursor-pointer text-white`} onClick={e => e.stopPropagation() || instanceRef.current?.next()}>
          <LiaAngleRightSolid size={25} />
        </div>}
      </div>
      <div ref={sliderRef} className="keen-slider !w-[85%] min-[1280px]:!w-[94%] mx-auto">
        {selectedProducts.length > 0 ? selectedProducts.map((product, index) => {
          return <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center py-4">
            <div className="w-full max-w-[300px]">
              <ProductCard product={product} alt={''} _lang={'en'} removeDataLayer={false} isVisibleDescription={true} roundedButton={false} squareButton={false} selectedCurrencyStore={selectedCurrencyStore} handleViewItemDataLayer={handleViewItemDataLayer} handleIsAnyAmount={handleIsAnyAmount} ShowCurrencies={ShowCurrencies} />
            </div>
          </div>;
        }) : <p className="text-center w-full">No products selected.</p>}
      </div>
      {selectedProducts.length > 1
        ? <div className="flex bg-white py-3 px-2 rounded-md justify-center items-center mx-auto mt-2 max-w-[300px]">
          {loaded && instanceRef.current && <div className="flex justify-center items-center relative w-full">
            <Arrow left onClick={e => e.stopPropagation() || instanceRef.current?.prev()} disabled={currentSlide === 0} />
            {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
              return <button key={`q${idx}`} onClick={() => {
                instanceRef.current?.moveToIdx(idx);
              }} className={'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' + (currentSlide === idx ? ' bg-[#F60362] hover:opacity-60' : 'bg-[#C3C3C3] hover:bg-[#F60362')}></button>;
            })}
            <Arrow onClick={e => e.stopPropagation() || instanceRef.current?.next()} disabled={currentSlide === instanceRef.current.track.details.slides.length - 1} />
          </div>}
        </div>
        : <></>
      }
    </div>}
  </>;
};
Builder.registerComponent(ProductBoxes, {
  name: 'ProductBoxes',
  inputs: [{
    "name": "products",
    "type": "string"
  }]
});
export default ProductBoxes;